import * as React from 'react';
import {useOutlet, Await} from 'react-router-dom';
import Alert from "@mui/material/Alert";
import LinearProgress from "@mui/material/LinearProgress";

import {AuthContextProvider} from '../hooks/useAuth';
import AuthProvider from './AuthProvider';



async function loadInitUser(){
   const resp = await AuthProvider.checkAuth()
         .catch(()=>{});

   if (resp){
      return resp;
   }

   return null;
}


export default function AuthLayout(params){
   const outlet = useOutlet();

   return (
      <React.Suspense fallback={<LinearProgress />}>
         <Await
           resolve={loadInitUser()}
           errorElement={<Alert severity="error">Something went wrong!</Alert>}
           children={(user) => (
               <AuthContextProvider userData={user}>
                  {outlet}
               </AuthContextProvider>
           )}
         />
       </React.Suspense>
   );
}
