import * as React from 'react';
import {
   Stack,
   Dialog,
   Button,
   TextField,
   DialogTitle,
   DialogContent,
   DialogActions
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';




/** Dialog to select a Date+Time range
 *
 * @param {object} props
 * @param {boolean} props.open determines if the dialog is open
 * @param {dateRangeSelectionAccepted} props.onAccept callback if
 *    dialog is accepted
 * @param {dateRangeSelectionRejected} props.onReject callback if
 *    dialog is rejected
 */
export default function DateTimeRangeSelectionDialog(props){
   const open = props.open;
   const _startDate = props.startDate;
   const _endDate = props.endDate;

   /** Callback called when DateTimeRangeSelectionDialog is accepted
    *
    * @function dateRangeSelectionAccepted
    *
    * @param {Date} startDate
    * @param {Date} endDate
    */
   const onAccept = props.onAccept;

   /** Callback called when DateTimeRangeSelectionDialog is rejected
    *
    * @function dateRangeSelectionRejected
    *
    */
   const onReject = props.onReject;

   const [startDate, setStartDate] = React.useState(_startDate);
   const [endDate, setEndDate] = React.useState(_endDate);

   function accepted(){
      onAccept(startDate, endDate);
   }

   function rejected(){
      onReject();
      // setIsOpen(false);
   }

   React.useEffect(()=>{
      setStartDate(_startDate);
      setEndDate(_endDate);
   }, [_startDate, _endDate]);

   return (
      <Dialog open={open} onClose={rejected}>
         <DialogTitle>Date Range</DialogTitle>

         <DialogContent>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
               <Stack spacing={2}>
                  <DateTimePicker
                     label="Start Time"
                     value={startDate}
                     onChange={(d)=>{
                        setStartDate(d.$d);
                     }}
                     disabled={false}
                     renderInput={(params) => {
                        params.variant="standard";
                        return (
                           <TextField
                              {...params}
                           />
                        );
                     }}
                  />
                  <DateTimePicker
                     label="End Time"
                     value={endDate}
                     onChange={(d)=>{
                        setEndDate(d.$d);
                     }}
                     disabled={false}
                     renderInput={(params) => {
                        params.variant="standard";
                        return (
                           <TextField
                              {...params}
                           />
                        );
                     }}
                  />
               </Stack>
            </LocalizationProvider>
         </DialogContent>

         <DialogActions>
            <Button onClick={rejected}>Cancel</Button>
            <Button onClick={accepted}>Ok</Button>
         </DialogActions>
      </Dialog>
   );
}
