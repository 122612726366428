import * as React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import DownloadIcon from '@mui/icons-material/Download';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Autocomplete from '@mui/material/Autocomplete';
import {styled} from '@mui/system';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';

import config from './config/config';
import * as Datastore from './Datastore';
import * as plotUtils from './plot/PlotUtils';
import * as utils from './utils';



function TsSensorAutoCompleteSelectionInput(props){
   const selection = props.selection;
   const setSelection = props.setSelection;
   const options = props.options;
   const label = props.label || '';
   const placeholder = props.placeholder || '';
   const disabled = props.disabled;

   const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
   const checkedIcon = <CheckBoxIcon fontSize="small" />;

   const GroupHeader = styled('div')(({ theme }) => ({
      position: 'sticky',
      top: '-8px',
      padding: '4px 10px',
      zIndex: 10,
      backgroundColor: '#ddd'
   }));

   return (
      <Autocomplete
         multiple
         disableCloseOnSelect
         disabled={disabled}
         size="small"
         onChange={(event, value) => {setSelection(value)} }
         id="sensor-selection-ac"
         value={selection}
         options={options}
         isOptionEqualToValue={(option, value)=> option.key === value.key}
         groupBy={(option)=> option.groupLabel}
         getOptionLabel={(option) => option.key}
         renderOption={(props, option, { selected }) => (
            <li {...props}>
               <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
               />
               {option.key}
            </li>
         )}
         style={{ width: 500 }}
         renderGroup={(params) => (
            <li key={params.key}>
               <GroupHeader>{params.group}</GroupHeader>
               <ul sx={{padding: 0}}>{params.children}</ul>
            </li>
         )}
         renderInput={(params) => (
            <TextField
               {...params}
               variant="standard"
               label={label}
               placeholder={placeholder} />
         )}
      />
   );
}


function __unitstr(unitStr, ptModeIndex){
   if (unitStr === 'pt'){
      return config.ptDisplayModes[ptModeIndex].key;
   }

   return unitStr;
}


export default function DataDownloadDialog(props){
   const SEVEN_DAYS_S = 7*24*3600;
   const open = props.open;
   const rejected = props.onClose;
   const accepted = props.onAccept;

   const [acOptions, setAcOptions] = React.useState([]);
   const [selection, setSelection] = React.useState([]);
   const [isDownloading, setIsDownloading] = React.useState(false);
   const [isLoading, setIsLoading] = React.useState(false);
   const [T1, setT1] = React.useState(new Date());
   const [T0, setT0] = React.useState(utils.dateAddSeconds(T1, -24*3600));

   // Specifies whether the pressure-transducer sensors show psi or kips
   const [displayModeIndex, setDisplayModeIndex] = React.useState(1);
   const [snapShotOnly, setSnapshotOnly] = React.useState(true);

   function _accepted(){
      accepted({
         selection: selection,
         displayModeIndex: displayModeIndex
      });
   }

   function snapshotChanged(event){
      const isChecked = event.target.checked;
      setSnapshotOnly(isChecked);
   }

   async function downloadData(){
      // {psi, kips}
      const modeKey = config.ptDisplayModes[displayModeIndex].key;

      function _updateObj(dict, key, newEntries){
         if (key in dict){
            dict[key] = {...dict[key], ...newEntries};
         }
         else{
            dict[key] = newEntries;
         }
      }

      // Individual sensor keys
      const requiredKeys = plotUtils.sensorKeysFromSelection(selection);

      let t0 = T0;
      let t1 = T1;
      if (snapShotOnly){
         t0 = t1;
      }

      setIsDownloading(true);
      // Get individual sensor data
      const sensorHistDict = await Datastore.timeHistory(
         requiredKeys, modeKey, t0, t1);

      // Combine individual sensor data to required data (e.g. sensor groups)
      const mergedHistDict = {}
      let headersUnit = [];
      for (let i=0; i<selection.length; ++i){
         const elem = selection[i];
         const key = elem.key;
         mergedHistDict[key] = plotUtils.toLineSeries(elem, sensorHistDict)
         headersUnit.push(__unitstr(selection[i].unit, displayModeIndex));
      }

      // Group items with the same time
      let timeDict = {};
      let headers = [];
      for (const [label, sensorData] of Object.entries(mergedHistDict)){
         headers.push(label);
         for (let i=0; i<sensorData.length; ++i){
            const t = sensorData[i][0].getTime();
            const elem = {t: t}
            elem[label] = sensorData[i][1];
            _updateObj(timeDict, t, elem);
         }
      }

      let flatten = [];
      for (const [t, data] of Object.entries(timeDict)){
         flatten.push(data);
      }
      flatten.sort((a, b)=>{ return a.t > b.t });

      // Create csv
      let str = ['t', ...headers].map(
         elem=>utils.enclose(elem, '"')).join(',') + '\n';
      str += ['', ...headersUnit].map(
         elem=>utils.enclose(elem, '"')).join(',') + '\n';
      for (let i=0; i<flatten.length; ++i){
         let elems = headers.map((h)=> flatten[i][h]);
         const tdate = dayjs(flatten[i].t).toDate();
         const tStr = dayjs.tz(tdate).format('YYYY-MM-DD HH:mm:ss');
         str += utils.enclose(tStr, '"') + ',' + elems.join(',') + '\n';
      }

      utils.downloadAsFile(str, 'data.csv', 'text;charset=utf-8;')
      setIsDownloading(false);
   }


   function canDownload(){
      return (!isDownloading) && (selection.length > 0);
   }


   React.useEffect(()=>{
      if (open){
         (async ()=>{
            // Fetch available plot options for AC
            setIsLoading(true);
            const userCfg = await Datastore.userConfig();
            setAcOptions(plotUtils.tsPlottableOptionList(userCfg.groups));
            setIsLoading(false);
         })();
      }
   }, [open]);


   function quickAddChipClicked(type){
      let groupItems = [];

      if (type === 'ext'){
         groupItems = plotUtils.extensometerPlotOptionGroups();
      }
      else if (type === 'tilt'){
         groupItems = plotUtils.tiltPlotOptionGroups();
      }

      let newItems = [];
      for (let i=0; i<groupItems.length; ++i){
         const key = groupItems[i].key;
         if (selection.find((elem)=> elem.key === key)){
            continue;
         }
         newItems.push(groupItems[i]);
      }
      setSelection(selection.concat(newItems));
   }

   return (
      <Dialog open={open} onClose={rejected}>
         <DialogTitle>Download Data</DialogTitle>
         <DialogContent>

            <Stack spacing={4}>
               <FormControl variant="standard" sx={{ m: 0, minWidth: 100 }}>
                  <Box>
                     <TsSensorAutoCompleteSelectionInput
                        options={acOptions}
                        disabled={isLoading}
                        selection={selection}
                        setSelection={setSelection}
                        label="Sensors and Groups"
                        placeholder="Sensor/Group"
                     />
                  </Box>
               </FormControl>

               <FormGroup>
                  <Typography gutterBottom variant="body2">Quick Add</Typography>
                  <FormControl variant="standard">
                     <Stack direction="row" spacing={1}>
                        <Chip
                           size="small"
                           clickable
                           variant="outlined"
                           label="Extensometers"
                           onClick={()=>quickAddChipClicked('ext')} />
                        <Chip
                           size="small"
                           clickable
                           label="Tilts"
                           variant="outlined"
                           onClick={()=>quickAddChipClicked('tilt')} />
                     </Stack>
                  </FormControl>
               </FormGroup>

               <FormControl variant="standard" sx={{ m: 0, minWidth: 100 }}>
                  <InputLabel id="disp-mode-select">Pressure Transducer Unit</InputLabel>
                  <Select labelId="disp-mode-select"
                          value={displayModeIndex}
                          label="Display"
                          onChange={(event)=>{
                             setDisplayModeIndex(event.target.value);
                          }}
                  >
                     {config.ptDisplayModes.map((elem, i)=>{
                        return (
                           <MenuItem key={i} value={i}>
                              {elem.label}
                           </MenuItem>
                        );
                     })}
                  </Select>
               </FormControl>

               <FormGroup>
                  <FormControl>
                     <FormControlLabel
                        control={
                           <Checkbox
                              checked={snapShotOnly}
                              onChange={snapshotChanged}
                           />
                        }
                        label="Point-in-time Snapshot" />
                  </FormControl>

                  <Stack direction="row" spacing={5}>

                     <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                           label="Start Time"
                           views={['year', 'month', 'day', 'hours', 'minutes']}
                           disabled={snapShotOnly}
                           value={T0}
                           maxDate={T1}
                           onChange={(d)=>{
                              const date = d.$d;
                              setT0(date);
                              const hi = utils.dateAddSeconds(date, SEVEN_DAYS_S);
                              if (T1 > hi){
                                 setT1(hi);
                              }
                           }}
                           renderInput={(params) => {
                              params.variant="standard";
                              return (
                                 <TextField
                                    {...params}
                                 />
                              );
                           }}
                        />
                     </LocalizationProvider>

                     <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                           label="End Time"
                           value={T1}
                           onChange={(d)=>{
                              const date = d.$d;
                              setT1(date);
                              const lo = utils.dateAddSeconds(date, -SEVEN_DAYS_S);
                              if (T0 < lo){
                                 setT0(lo);
                              }
                           }}
                           renderInput={(params) => {
                              params.variant="standard";
                              return (
                                 <TextField
                                    {...params}
                                 />
                              );
                           }}
                        />
                     </LocalizationProvider>
                  </Stack>
                  <FormHelperText>Limited to 7-day range per request</FormHelperText>
               </FormGroup>

               <FormControl sx={{pt: 2}}>
                  <Box sx={{position: 'relative'}}>
                     <Button sx={{width: '100%'}}
                             variant="contained"
                             disabled={! canDownload()}
                             startIcon={<DownloadIcon/>}
                             onClick={downloadData}>
                        Download
                     </Button>
                     {isDownloading && (
                        <CircularProgress
                           size={24}
                           sx={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              marginTop: '-12px',
                              marginLeft: '-12px',
                           }}
                        />
                     ) }
                  </Box>
               </FormControl>

            </Stack>
         </DialogContent>

         <DialogActions>
            <Button onClick={rejected}>Cancel</Button>
         </DialogActions>
      </Dialog>
   );
}
