import config from '../config/config';
import axios from 'axios';


const API = [config.apiEndpoint, 'auth'].join('/');



function setLocalStorage(key, value){
   try {
      window.localStorage.setItem(key, JSON.stringify(value));
   }
   catch (err){
      return false;
   }

   return true;
}


function getLocalStorage(key){
   const value = window.localStorage.getItem(key);

   if (value){
      return JSON.parse(value);
   }

   return undefined;
}


export function signup(email, firstname, lastname, password){
   return axios.post(API + '/signup', {
      email: email,
      firstname: firstname,
      lastname: lastname,
      password: password
   });
}


function tokenValue(token, key, defVal){
   try{
      const obj = JSON.parse(atob(token.split('.')[1]));
      return obj[key];
   }
   catch(err){
      return defVal;
   }
}


export default{

   login: async ({username, password}) => {

      const resp = await axios.post(API + '/signin', {
         email: username,
         password: password
      }).catch((err)=>{
         return Promise.reject(err.response.data.message || 'Something went wrong.');
      });

      const userData = resp.data;
      setLocalStorage('user', userData);
      return Promise.resolve(userData);
   },

   changePassword: async function(oldPassword, newPassword){
      try{
         const identity = await this.getIdentity();
         const resp = await axios.post(API + '/change-password', {
            oldPassword: oldPassword,
            newPassword: newPassword
         }, {
            headers: {
               'x-access-token': identity.token
            }
         });
         return Promise.resolve();
      }
      catch(err){
         return Promise.reject(err.response.data.message || 'Something went wrong.');
      };
   },

   checkError: (error) => {
      return Promise.resolve();
   },

   checkAuth: ()=>{
      const user = getLocalStorage('user');
      if (!user){
         return Promise.reject();
      }

      const expiration = tokenValue(user.token, 'exp');
      const expDate = new Date(expiration*1000);
      if (!expiration || (new Date() > expDate)){
         return Promise.reject();
      }

      if (user){
         return Promise.resolve(user);
      }
      return Promise.reject();
   },

   logout: () => {
      window.localStorage.removeItem('user');
      return Promise.resolve();
   },

   getIdentity: ()=>{
      const user = getLocalStorage('user');
      if (user){
         return Promise.resolve(user);
      }

      return Promise.reject({});
   },

   getPermissions: () => {
      const user = getLocalStorage('user');
      if (!user){
         return Promise.reject([]);
      }
      return user.admin ? Promise.resolve('admin') : Promise.resolve('user');
   }

};
