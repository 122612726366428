import {
   Create,
   SimpleForm,
   DateTimeInput,
   TextInput
} from 'react-admin';



export const NotesCreate = (props) => {
   return (
      <Create {...props} >
         <SimpleForm >
            <DateTimeInput source="date" defaultValue={new Date()} required/>
            <TextInput source="text" label="Note" fullWidth multiline/>
         </SimpleForm>
      </Create>
   )
};
