import * as React from 'react';
import { Create,
         SimpleForm,
         TextInput,
         required,
         PasswordInput,
         BooleanInput} from 'react-admin';


function validatePassword(values){
   const errors = {};
   if (values.passwordConfirm !== values.password){
      errors.passwordConfirm = 'Passwords are not the same';
   }
   return errors;
}


export const UserCreate = (props) => {

   return (
      <Create {...props} >
         <SimpleForm validate={validatePassword}>
            <TextInput source="email" type="email" validate={required()}/>
            <TextInput source="firstname" />
            <TextInput source="lastname" />
            <PasswordInput source="password" validate={required()} />
            <PasswordInput source="passwordConfirm" validate={required()} />
            <BooleanInput source="admin" />
            <BooleanInput source="enabled" />
         </SimpleForm>
      </Create>
   )
};
