const d3 = require('d3'),
      mouse = require('./Mouse.js');


/** Horizontal range selection component
 *
 * @events
 *    rangeSelected([t0, t1])
 *
 */
function Rubberband(plot){
    this._plot = plot;
    this.__events = [
        'rangeSelected'
    ];

    this._rb =
        plot
        .plot_group
        .append('rect')
        .attr('class', 'ep-rubberband');

    this._mouseIsDown = false;

    plot.svg.node().addEventListener('mousedown', function(event){
        if (! mouse.isLeftClick(event) || mouse.hasModifier(event)){
           return;
        }

        var m = d3.pointer(event);
        this._pstart = m[0];
        this._mouseIsDown = true;
    }.bind(this));

    plot.svg.node().addEventListener('mousemove', function(event){
        if (!this._mouseIsDown){
            return;
        }

        var m = d3.pointer(event);
        var right = m[0];

        var left = this._pstart;
        if (right < left){
            left = right;
            right = this._pstart;
        }

        var leftDomain = this._plot.leftAxis.range();
        right = this._plot.bottomAxis.dom_to_canvas(right);
        left = this._plot.bottomAxis.dom_to_canvas(left);

        var dw = this._plot.bottomAxis.map_to_canvas(right)
            - this._plot.bottomAxis.map_to_canvas(left);
        this._rb
            .attr('x', this._plot.bottomAxis.map_to_canvas(left))
            .attr('y', leftDomain[0])
            .attr('width', dw)
            .attr('height', Math.abs(leftDomain[0] - leftDomain[1]));
    }.bind(this));

    plot.svg.node().addEventListener('mouseup', function(event){
        if (! mouse.isLeftClick(event) || ! this._mouseIsDown){
            return;
        }

        var m = d3.pointer(event);
        this._mouseIsDown = false;
        this._rb.attr('width', 0);
        var right = m[0];
        if (Math.abs(right-this._pstart) < 10){
            return;
        }
        let range = [this._pstart, right];
        if (this._pstart > right){
            range = [right, this._pstart];
        }

        range = [
            this._plot.bottomAxis.dom_to_canvas(range[0]),
            this._plot.bottomAxis.dom_to_canvas(range[1])
        ];

        this.notify('rangeSelected', range);
    }.bind(this));

    this.observers = {};
}

Rubberband.prototype.unsubscribe = function(event, cb){
    if (!(event in this.observers)){
        this.observers[event] = [];
    }

    const idx = this.observers[event].indexOf(cb);
    if (!cb){
        this.observers[event] = [];
    }
    else if (idx > -1){
        this.observers[event].splice(idx, 1);
    }
};


Rubberband.prototype.subscribe = function(event, cb){
    if (!(event in this.observers)){
        this.observers[event] = [];
    }

    this.observers[event].push(cb);
};


Rubberband.prototype.notify = function(event, data){
    if (! (event in this.observers)){
        return;
    }

    let obs_list = this.observers[event];
    for (let i=0; i<obs_list.length; ++i){
        obs_list[i](data);
    }
};


module.exports = Rubberband;
