import * as React from 'react';
import {
   List,
   Datagrid,
   TextField,
   DateField
} from 'react-admin';




export const NotesList = () => (
    <List exporter={false}>
       <Datagrid rowClick="edit">
          <DateField showTime={true} source="date"/>
          <TextField source="text" label="Note"/>
          <TextField source="user.email"/>
          <DateField showTime={true} source="createdAt" label="Created"/>
          <DateField showTime={true} source="updatedAt" label="Modified"/>
       </Datagrid>
    </List>
);
