/**
 * @module utils
 */


import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);



/**
 * @param {Date} input date
 * @param {integer} offsetSecs number of seconds to add to input date
 * @return {Date} new date with offsetSecs added to it
 */
export function dateAddSeconds(date, offsetSecs){
   return new Date(
      (new Date()).setTime(date.getTime() + offsetSecs*1000)
   );
}


/** Find the index of the first element matching input time
 *
 * @param {Date} t query datetime
 * @param {Array<TXpoint>} tnumArray search array
 * @param {integer} startingIndex starting search index
 * @return {integer} index of the matching elemnt in search
 *    array, or -1 if not found
 */
function _findMatchingTimeValueIndex(t, tnumArray, startingIndex){
   if (startingIndex === undefined){
      startingIndex = 0;
   }

   if ((tnumArray.length < 1) || (startingIndex >= tnumArray.length)){
      return -1;
   }

   const tq = t.getTime();

   for (let i=startingIndex; i<tnumArray.length; ++i){
      if (tnumArray[i][0].getTime() === tq){
         return i;
      }
   }

   return -1;
}


/** Pair values from two time-series
 *
 * @param {Array<Date|number>} xSeries
 * @param {Array<Date|number>} ySeries
 * @return {Array<number|number>}
 */
export function tsToXySeries(xSeries, ySeries){
   let ret = [];
   if ((xSeries.length < 1) || (ySeries.length < 1)){
      return ret;
   }

   const N = Math.min(xSeries.length, ySeries.length);

   let qIndex = 0;
   for (let i=0; i<N; ++i){
      const idx = _findMatchingTimeValueIndex(xSeries[i][0], ySeries, qIndex);

      if (idx < 0){
         continue;
      }

      qIndex = idx;
      ret.push([xSeries[i][1], ySeries[idx][1], xSeries[i][0]]);
   }

   return ret;
}


export function enclose(str, char){
   return char + str + char;
};



/**
   example type: 'text/csv;charset=utf-8;'
 */
export function downloadAsFile(content, filename, type){
   if (type === undefined){
      type = 'text;charset=utf-8;';
   }

   let blob = new Blob([content], { type: type });
   if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
   } else {
      var link = document.createElement('a');

      // feature detection
      if (link.download !== undefined) {
         // Browsers that support HTML5 download attribute
         var url = URL.createObjectURL(blob);
         link.setAttribute('href', url);
         link.setAttribute('download', filename);
         link.style.visibility = 'hidden';
         document.body.appendChild(link);
         link.click();
         document.body.removeChild(link);
      }
   }
}


/** Current Date in US/Mountain time
 *
 * @return {Date} date object
 */
export function nowMountain(){
   const nowLocal = new Date();
   return dayjs(nowLocal).tz('US/Mountain').$d;
}


export function formatNumber(value, digits){
   if (isNaN(parseFloat(value))){
      return 'UNDEF';
   }

   if ((value === undefined) || (value === null)){
      return 'UNDEF';
   }

   if (digits === undefined){
      digits = 8;
   }

   return value.toFixed(digits).toLocaleString();
}
