import {
   Edit,
   SimpleForm,
   NumberInput,
   SelectInput,
   TextInput,
   required,
   BooleanInput} from 'react-admin';
import JsonInput from './JsonInput';



export const NotifierEdit = (props) => {
   return (
      <Edit {...props} >
         <SimpleForm >
            <TextInput source="name" />
            <TextInput source="fn" label="Function name" />
            <TextInput source="observers"
                       helperText="Comma-separated list of emails"
                       fullWidth/>
            <SelectInput source="trigger"
                         choices={[
                            { id: 'BOX', name: 'Small Boxes' },
                            { id: 'STATION', name: 'Stations' }
                         ]} />
            <JsonInput label="Context (JSON)"
                       source="context"
                       multiline
                       fullWidth/>
            <JsonInput label="State (JSON)"
                       source="state"
                       multiline
                       fullWidth/>
            <NumberInput source="interval" label="Interval (minutes)"/>
            <BooleanInput source="enabled" />
         </SimpleForm>
      </Edit>
   )
};
