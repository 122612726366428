const d3 = require('d3');


function Axis(opts){
    this.opts = {
        location: opts.location || 'left',
        margins: opts.margins || [0, 0],
        box: opts.box || [[0,0], [0,0]],
        scale: opts.scale || d3.scaleLinear(),
        label: opts.label || '',
        tickFormat: opts.tickFormat || undefined
    };

    const ac = {
        left: d3.axisLeft,
        right: d3.axisRight,
        bottom: d3.axisBottom,
        top: d3.axisTop
    };

    this._scale = this.opts.scale;
    this._axis = ac[this.opts.location](this._scale);
    this._gridaxis = this._regenGridAxis();
}


Axis.prototype._regenGridAxis = function(){
    const ac = {
        left: d3.axisLeft,
        right: d3.axisRight,
        bottom: d3.axisBottom,
        top: d3.axisTop
    };

    var width = this.opts.box[1][0] - this.opts.box[0][0];
    var height = this.opts.box[0][1] - this.opts.box[1][1];
    let ret = ac[this.opts.location](this._scale);

    if (this.opts.location === 'left'){
        ret.tickSize(-width)
            .tickFormat('');
    }
    else if (this.opts.location === 'bottom'){
        ret.tickSize(-height)
            .tickFormat('');
    }

    return ret;
};


Axis.prototype.attach = function(parent){
    this._container = parent.append('g')
        .attr(
            'transform',
            `translate(${this.opts.margins[0]}, ${this.opts.margins[1]})`);

    this._dom = this._container
        .append('g')
        .attr('class', 'ep-axis')
        .call(this._axis);

    this._griddom = this._container
        .append('g')
        .attr('class', 'ep-grid')
        .call(this._gridaxis);

    this._text = this._container
        .append('text')
        .attr('class', 'ep-axis-label')
        .text(this.opts.label);

    this.__repositionLabel();

    return this;
};


Axis.prototype.__repositionLabel = function(){
    var rec = this._dom.node().getBBox();
    let r = this.range();
    if (this.opts.location == 'bottom'){
        let r = this.range();
        let xoffset = (r[1]-r[0])/2;
        let yoffset = this.opts.margins[1] + rec.height*2;

        this._text.attr(
            'transform',
            `translate(${xoffset}, ${yoffset} )`
        );
    }
    if (this.opts.location == 'left'){
        let r2 = this._text.node().getBBox();
        let xoffset = -rec.width - 10;
        let yoffset = (r[1]+r[0])/2 + r2.width/2;
        this._text.attr(
            'transform',
            `translate(${xoffset}, ${yoffset} ) rotate(-90)`
        );
    }
    if (this.opts.location === 'right'){
        let r = this.range();
        let r2 = this._text.node().getBBox();
        let xoffset = r2.height*2 + 10;
        let yoffset = (r[1]+r[0])/2 + r2.width/2;
        this._text.attr(
            'transform',
            `translate(${xoffset}, ${yoffset} ) rotate(-90)`
        );
    }
};


Axis.prototype.range = function(arg){
    if (arg === undefined){
        return this._scale.range();
    }

    this._scale.range(arg);
    return this;
};


Axis.prototype.label = function(str){
    if (!this._text){
        this.opts.label = str;
        return;
    }
    this._text.text(str);
    this.__repositionLabel();
};


Axis.prototype.domain = function(arg){
    if (arg === undefined){
        return this._scale.domain();
    }

    this._scale.domain(arg);
    if (this._dom !== undefined){
        this.draw();
    }

    return this;
};


Axis.prototype.draw = function(){
    if (this.opts.tickFormat !== undefined){
        this._axis
            .tickFormat(
                this.opts.tickFormat(
                    this._scale.domain()
                ));
    }

    // Draw axis itself
    this._dom
        .transition()
        .duration(200)
        .call(this._axis)
        .on('end', ()=>{
            this.__repositionLabel();
        });

    // draw grid lines
    this._griddom
        .transition()
        .duration(200)
        .call(this._gridaxis);

    return this;
};


/** Update axis offsets based on parent margins
 *
 * @param {list} margins [start, end] of the margins
 */
Axis.prototype.setMargins = function(margins){
    this.opts.margins = margins;
    this._container.attr(
        'transform',
        `translate(${this.opts.margins[0]}, ${this.opts.margins[1]})`);
};

function marginsFromBBox(bbox, location){
    if (location === 'right'){
        return bbox[1];
    }
    return bbox[0];
}

/** Resize and redraw the axes
 *
 * @param {Array<list>} bbox [[bottomLeft], [topRight]] bounding box defining the
 *      plottable region relative to parent
 * @param {Array} margins
 */
Axis.prototype.resize = function(bbox){
    if (this.opts.location != 'left'){
        this.setMargins(marginsFromBBox(bbox, this.opts.location));
    }

    this.opts.box = bbox;
    var width = this.opts.box[1][0] - this.opts.box[0][0];
    var height = this.opts.box[0][1] - this.opts.box[1][1];
    this.height = height;
    this.width = width;

    if (this.opts.location === 'left'){
        this._gridaxis
            .tickSize(-width)
            .tickFormat('');
        this.range([0, height]);
    }
    else if (this.opts.location === 'right'){
        this._gridaxis
            .tickSize(-width)
            .tickFormat('');
        this.range([0, height]);
    }
    else if (this.opts.location === 'bottom'){
        this._gridaxis
            .tickSize(-height)
            .tickFormat('');

        this.range([0, width]);
    }

    this._dom.transition().duration(250).call(this._axis);
    this._griddom.transition(250).call(this._gridaxis);
    this.__repositionLabel();
};


Axis.prototype.map_to_canvas = function(p){
    return this._scale(p);
};


Axis.prototype.dom_to_canvas = function(p){
    if (this.opts.location === 'bottom'){
        return this._scale.invert(p - this.opts.margins[0]);
    }

    return this._scale.invert(p - this.opts.margins[1]);
};


Axis.prototype.scale = function(scale){
    if (scale === undefined){
        return this._scale;
    }
    this._scale = scale;
    this._axis.scale(scale);
    // this.draw();

    const ac = {
        left: d3.axisLeft,
        right: d3.axisRight,
        bottom: d3.axisBottom,
        top: d3.axisTop
    };
    this._gridaxis = ac[this.opts.location](this._scale);
    var height = this.opts.box[0][1] - this.opts.box[1][1];
    if (this.opts.location === 'bottom'){
        this._gridaxis
            .tickSize(-height)
            .tickFormat('');
    }


    return this;
};


module.exports = Axis;
