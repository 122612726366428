/** Series legend widget
 *
 *
 *  +---------svg.g---------+
 *  | -- label1   --label2  |
 *  |                       |
 *  | -- label2   --label3  |
 *  +-----------------------+
 *
 *
 * @param {svg.g} group svg group element containing the legend
 * @param {Model.plots.series} data model plot data
 * @param {Object} ops key-value pair of options, unused
 *
 * events:
 *   - legendhover(key) emitted when pointer hovers over legend
 *     text
 *   - legendunhover(key) emitted when pointer leaves hover text
 *
 */
function Legend(group, data, opts){
    this.__events = [
        'legendhover',          // key
        'legendunhover'         // key
    ];

    this.opts = opts || {};
    this.data = data;
    this.group = group;
    this.observers = {};
    // Maximum number of columns before wrapping
    this.maxColumns = this.opts.maxColumns || 3;

    this.draw();
}


Legend.prototype.draw = function(){
    // horizontal spacing between legend elems
    var dx = 10;
    // vertical spacing between legend elems
    var dy = 20;
    // hacky horizontal padding between elems
    var pad = 40;
    // horizontal size of legend line
    var lw = 10;

    const max_cols = this.maxColumns;
    var legends = this.group.selectAll('.ep-legend');
    var legends_text = this.group.selectAll('.ep-legend-text');

    let max_length = 0;
    for (var i=0; i<this.data.length; ++i){
       max_length = Math.max(this.data[i].label.length, max_length);
    }
    dx = Math.max(dx, max_length*9);

    legends
        .data(this.data)
        .exit()
        .remove();

    // Draw lines indicating the series linetype/color
    legends
        .data(this.data)
        .enter()
        .append('line')
        .merge(legends)
        .attr('x1', function(d, i){
            let col = i % max_cols;
            return 0 + col*(dx + pad);
        })
        .attr('y1', function(d, i){
            let row_id = Math.floor(i/max_cols);
            return row_id*dy;
        })
        .attr('x2', function(d, i){
            let col = i % max_cols;
            return lw + col*(dx + pad);
        })
        .attr('y2', function(d, i){
            let row_id = Math.floor(i/max_cols);
            return row_id*dy;
        })
        .attr('stroke', function(d){
            return d.color;
        })
        .attr('class', 'ep-legend')
        .classed('ep-dashed-line', function(d){
            return d.linestyle === '--';
        })
        .attr('stroke-width', 2);

    legends_text
      .data(this.data)
      .exit()
      .remove();

    // Draw legend texts next to legend-lines lines
    legends_text
        .data(this.data)
        .enter()
        .append('text')
        .merge(legends_text)
        .attr('dy', function(d, i){
            let row_id = Math.floor(i/max_cols);
            return 5 * (row_id + 1) + row_id*(dy-5);
        })
        .attr('dx', function(d, i){
            let col = i % max_cols;
            return col*(pad+dx) + lw + 5;
        })
        .text(function(d){
            return d.label;
        })
        .attr('class', 'ep-legend-text')
        .attr('fill', function(d){
            if (d.enabled || d.enabled !== false){
                return '#000000';
            }
            return '#33333333';
        })
        .on('mouseover', function(event, d){
            this.emit('legendhover', d.key);
        }.bind(this))
        .on('mouseleave', function(event, d){
            this.emit('legendunhover', d.key);
        }.bind(this))
        .on('click', function(event, d){
            this.emit('click', d.key, event);
        }.bind(this));
};


Legend.prototype.setMaxColumns = function(maxCols){
    this.maxColumns = maxCols;
    this.draw();
};


Legend.prototype.emit = function(event, ...data){
    if (! (event in this.observers)){
        return;
    }

    this.observers[event](...data);
};


Legend.prototype.on = function(event, cb){
    this.observers[event] = cb;
};



module.exports = Legend;
