import * as React from 'react';
import TextField from '@mui/material/TextField';
import {Edit,
        SimpleForm,
        TextInput,
        BooleanInput,
        PasswordInput,
        required} from 'react-admin';



function validatePassword(values){
   const errors = {};
   if (values.passwordConfirm !== values.password){
      errors.passwordConfirm = 'Passwords are not the same';
   }

   try {
      const userData = values.userconfig.data
      if (userData.constructor !== Object){
         const d = JSON.parse(values.userconfig.data);
      }
   }
   catch (err){
      errors['userconfig.data'] = 'Invalid JSON';
   }

   return errors;
}


export const UserEdit = () => {
   return (
      <Edit>
         <SimpleForm validate={validatePassword}>
            <TextInput source="firstname"/>
            <TextInput source="lastname"/>
            <TextInput source="email" type="email"/>
            <BooleanInput label="Admin" source="admin" />
            <BooleanInput label="Enabled" source="enabled" />
            <PasswordInput source="password" validate={required()} />
            <PasswordInput source="passwordConfirm" validate={required()} />
            <TextInput
               format={ val => {
                  if (typeof(val) === 'object'){
                     return JSON.stringify(val);
                  }
                  return '' + val;
               }}
               label="Config Data (JSON)"
               source="userconfig.data"
               multiline
               fullWidth
            />
         </SimpleForm>
      </Edit>
   )
};
