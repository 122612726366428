import * as React from 'react';
import * as Datastore from './Datastore';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {
   DataGrid,
   GridToolbarContainer,
   GridToolbarColumnsButton
} from '@mui/x-data-grid';

import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { styled, lighten, darken } from '@mui/system';
import config from './config/config';
import DeleteConfirmDialog from './DeleteConfirmDialog';



function AutoCompleteSelectionInput(props){
   const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
   const checkedIcon = <CheckBoxIcon fontSize="small" />;
   const selection = props.selection;
   const setSelection = props.setSelection;
   const options = props.options;

   const GroupHeader = styled('div')(({ theme }) => ({
      position: 'sticky',
      top: '-8px',
      padding: '4px 10px',
      zIndex: 10,
      backgroundColor: '#ddd'
   }));

   return (
      <Autocomplete
         multiple
         disableCloseOnSelect
         size="small"
         onChange={(event, value) => setSelection(value) }
         id="sensor-selection-ac"
         value={selection}
         options={options}
         isOptionEqualToValue={(option, value)=> option.key === value.key}
         groupBy={(option)=> option.groupLabel}
         getOptionLabel={(option) => option.key}
         renderOption={(props, option, { selected }) => (
            <li {...props}>
               <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
               />
               {option.key}
            </li>
         )}
         style={{ width: 500 }}
         renderGroup={(params) => (
            <li key={params.key}>
               <GroupHeader>{params.group}</GroupHeader>
               <ul sx={{padding: 0}}>{params.children}</ul>
            </li>
         )}
         renderInput={(params) => (
            <TextField
               {...params}
               variant="standard"
               label="Sensors"
               placeholder="Sensors" />
         )}
      />
   );
}


function GroupEditDialog(props){
   const onAccept = props.onAccept;
   const onReject = props.onReject;
   const show = props.show;
   const _data = props.data;

   const [selection, setSelection] = React.useState([]);
   const [label, setLabel] = React.useState(props.label);
   const options = generateOptions();

   // Create grouped selection of {rod, jack} sensors
   function _updateLocalSelection(data){
      const keyedList = _generateKeyedOptions();
      let ret = [];

      setLabel(data.label);

      let sel = data.keys;
      for (let i=0; i<sel.length; ++i){
         const k = sel[i];
         ret.push({
            key: k,
            type: keyedList[k]
         });
      }
      setSelection(ret);
   }

   function generateOptions(){
      let ret = config.sensorGroups['PLATE_JACK'].map((elem)=>{
         return {
            groupLabel: 'Plate Jacks',
            type: 'PLATE_JACK',
            key: elem
         }
      });

      ret = ret.concat(config.sensorGroups['COMP_ROD'].map((elem)=>{
         return {
            groupLabel: 'Compression Rods',
            type: 'COMP_ROD',
            key: elem
         }
      }));
      return ret;
   }

   function _generateKeyedOptions(){
      let ret = {};
      for (let key in config.sensorGroups){
         const list = config.sensorGroups[key];
         for (let i=0; i< list.length; ++i){
            ret[list[i]] = key;
         }
      }
      return ret;
   }

   function accepted(){
      // Return only a list of strings
      let keys = [];
      for (let i=0; i<selection.length; ++i){
         keys.push(selection[i].key);
      }

      onAccept({
         label: label,
         keys: keys
      });
   }

   React.useEffect(()=>{
      _updateLocalSelection(_data);
   }, [_data]);

   return (
      <Dialog open={show} onClose={onReject}>
         <DialogTitle>Group</DialogTitle>

         <DialogContent sx={{m: 1}}>
            <Stack direction="column" spacing={1}>
               <TextField
                  label="Label"
                  variant="standard"
                  placeholder="Group Label"
                  onChange={(e)=>{ setLabel(e.target.value); }}
                  value={label} />
               <AutoCompleteSelectionInput
                  options={options}
                  selection={selection}
                  setSelection={setSelection}
               />
            </Stack>
         </DialogContent>

         <DialogActions>
            <Button onClick={onReject}>Cancel</Button>
            <Button onClick={accepted}>Ok</Button>
         </DialogActions>
      </Dialog>
   );
}


function GroupTableViewToolbar(props){
   const deleteClicked = props.deleteClicked;
   const addClicked = props.addClicked;
   const editClicked = props.editClicked;
   const selectedRows = props.selectedRows;

   return (
      <GridToolbarContainer>
         <Stack
            direction="row"
            spacing={1}
            divider={<Divider orientation="vertical" flexItem />}
         >
            <GridToolbarColumnsButton />

            <Tooltip title="Add a new group">
               <IconButton
                  onClick={addClicked}
                  color="primary"
                  size="small"
               >
                  <AddIcon/>
               </IconButton>
            </Tooltip>

            <Tooltip title="Edit selected group">
               <span>
                  <IconButton
                     onClick={editClicked}
                     color="primary"
                     size="small"
                     disabled={selectedRows.length !== 1}>
                     <EditIcon/>
                  </IconButton>
               </span>
            </Tooltip>
         </Stack>
         <Box sx={{ flexGrow: 1 }} />
         <Tooltip title="Delete selected group">
            <span>
               <IconButton
                  onClick={deleteClicked}
                  color="primary"
                  size="small"
                  disabled={selectedRows.length < 1}>
                  <DeleteIcon/>
               </IconButton>
            </span>
         </Tooltip>
      </GridToolbarContainer>
   );
}


function sumKeys(dataDict, keys, qKey){
   let sum = 0;

   for (let i=0; i<keys.length; ++i){
      const obj = dataDict[keys[i]];
      if (obj === undefined){
         return NaN;
      }
      sum += obj[qKey];
   }

   return sum;
}


function doTimesMatch(sensorDataCache, keys){
   if (keys.length < 1){
      return false;
   }

   if (!(keys[0] in sensorDataCache)){
      return false;
   }

   const t0 = sensorDataCache[keys[0]].t.getTime();
   return keys.every((key)=>{
      if (!(key in sensorDataCache)){
         return false;
      }

      return sensorDataCache[key].t.getTime() === t0
   });
}


function formatRoundNumber(val){
   if (val === undefined){
      return 'N/A';
   }

   return Math.round(val);
}


function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = React.memo(function GridCellExpand(props){
   const { width, value } = props;
   const wrapper = React.useRef(null);
   const cellDiv = React.useRef(null);
   const cellValue = React.useRef(null);
   const [anchorEl, setAnchorEl] = React.useState(null);
   const [showFullCell, setShowFullCell] = React.useState(false);
   const [showPopper, setShowPopper] = React.useState(false);

   const handleMouseEnter = () => {
      const isCurrentlyOverflown = isOverflown(cellValue.current);
      setShowPopper(isCurrentlyOverflown);
      setAnchorEl(cellDiv.current);
      setShowFullCell(true);
   };

   const handleMouseLeave = () => {
      setShowFullCell(false);
   };

   React.useEffect(() => {
      if (!showFullCell) {
         return undefined;
      }

      function handleKeyDown(nativeEvent) {
         // IE11, Edge (prior to using Bink?) use 'Esc'
         if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
            setShowFullCell(false);
         }
      }

      document.addEventListener('keydown', handleKeyDown);

      return () => {
         document.removeEventListener('keydown', handleKeyDown);
      };
   }, [setShowFullCell, showFullCell]);


   function WarningIcons(props){
      return (
         <span>
            {! props.row.groupTimesMatch &&
             <Tooltip title="Value may not be at the same time as other groups">
                <PriorityHighRoundedIcon
                   sx={{fontSize: 'medium', color: '#deab16'}}></PriorityHighRoundedIcon>
             </Tooltip>
            }
            {! props.row.timesMatch &&
             <Tooltip title="Timestamps on the sensors do not match">
                <ReportProblemIcon sx={{fontSize: 'small', color: '#c90000'}}></ReportProblemIcon>
             </Tooltip>
            }
         </span>
      );
   }

   return (
      <Box
         ref={wrapper}
         onMouseEnter={handleMouseEnter}
         onMouseLeave={handleMouseLeave}
         sx={{
            alignItems: 'center',
            lineHeight: '24px',
            width: 1,
            height: 1,
            position: 'relative',
            display: 'flex',
         }}
      >
         <Box
            ref={cellDiv}
            sx={{
               height: 1,
               width,
               display: 'block',
               position: 'absolute',
               top: 0,
            }}
         />
         <Box
            ref={cellValue}
            sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
         >
            <WarningIcons row={props.row}/>
            {value}
         </Box>
         {showPopper && (
            <Popper
               open={showFullCell && anchorEl !== null}
               anchorEl={anchorEl}
               style={{ width, marginLeft: -17 }}
            >
               <Paper
                  elevation={1}
                  style={{ minHeight: wrapper.current.offsetHeight - 3 }}
               >
                  <Typography variant="body2" style={{ padding: 8 }}>
                     {value}
                  </Typography>
               </Paper>
            </Popper>
         )}
      </Box>
   );
});


function renderGroupLabel(props){
   return (
      <GridCellExpand {...props}>
       </GridCellExpand>
   );
};


const ProgressBar = React.memo(function ProgressBar(props){
  const { percent } = props;
  const valueInPercent = Math.max(0, percent);

  return (
     <Box sx={{
             border: '1px solid #ddd',
             position: 'relative',
             overflow: 'hidden',
             width: '100%',
             height: 26,
             borderRadius: 0}}>
        <Box sx={{
                position: 'absolute',
                lineHeight: '24px',
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
             }}>
           {`${percent.toFixed(0).toLocaleString()}%`}
      </Box>
      <Box
         sx={{
            height: '100%',
            backgroundColor: props.color,
            maxWidth: valueInPercent + '%'
         }}
      />
    </Box>
  );
});


function filledReadingCell(params){
   return <ProgressBar percent={Number(params.value)} color="#ddd"/>;
}


function GroupTableView(props){
   const sensorDataCache = props.sensorDataCache;
   const groups = props.groups;
   const setGroups = props.setGroups;
   const selectedGroupsChanged = props.onSelectedGroupsChanged;

   const [rows, setRows] = React.useState([]);
   const [selectionList, setSelectionList] = React.useState([]);
   const [showEditDialog, setShowEditDialog] = React.useState(false);
   const [editDialogData, setEditDialogData] = React.useState({label: '', keys: []})
   const [isEditMode, setIsEditMode] = React.useState(false);
   const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] =
         React.useState(false);

   const columns = [
      {
         field: 'label',
         renderCell: renderGroupLabel,
         headerName: 'Name',
         type: 'string',
         flex: 1
      },
      {
         field: 'pressure',
         headerName: 'Pressure (psi)',
         type: 'number',
         flex: 0.75
      },
      {
         field: 'force',
         headerName: 'Force (kips)',
         type: 'number',
         flex: 0.75
      },
      {
         field: 'forcePercent',
         headerName: 'Force/Target (%)',
         renderCell: filledReadingCell,
         type: 'number',
         flex: 0.75
      },
      {
         field: 'pressurePercent',
         headerName: 'Pressure/Target (%)',
         renderCell: filledReadingCell,
         type: 'number',
         flex: 0.75
      },
      {
         field: 'targetKips',
         headerName: 'Target (kips)',
         type: 'number',
         flex: 0.75
      },
      {
         field: 'targetPsi',
         headerName: 'Target (psi)',
         type: 'number',
         flex: 0.75
      }
   ];

   async function _updateRows(sensorDataCache, groups){
      let _rows = [];
      const allKeys = Array.prototype.concat.apply([], groups.map((elem)=>elem.keys));
      const groupTimesMatch = doTimesMatch(sensorDataCache, allKeys);

      for (let i=0; i<groups.length; ++i){
         const keys = groups[i].keys;
         const targetKips = sumKeys(sensorDataCache, keys, 'targetKips');
         const force = sumKeys(sensorDataCache, keys, 'kips');
         const pressure = sumKeys(sensorDataCache, keys, 'psi');
         const targetPsi = sumKeys(sensorDataCache, keys, 'targetPsi');
         _rows.push({
            id: i,
            label: groups[i].label,
            pressure: formatRoundNumber(pressure),
            targetKips: formatRoundNumber(targetKips),
            forcePercent: formatRoundNumber(force/targetKips * 100),
            pressurePercent: formatRoundNumber(pressure/targetPsi * 100),
            targetPsi: formatRoundNumber(targetPsi),
            force: formatRoundNumber(force),
            timesMatch: doTimesMatch(sensorDataCache, keys),
            groupTimesMatch: groupTimesMatch
         });
      }
      setRows(_rows);
   }

   React.useEffect(()=>{
      _updateRows(sensorDataCache, groups);
   }, [sensorDataCache, groups]);


   function selectionModelChanged(model){
      setSelectionList(model);

      if (selectedGroupsChanged === undefined){
         return;
      }

      const ids = model;
      let groupList = [];
      for (let i=0; i< model.length; ++i){
         groupList.push(groups[ids[i]])
      }
      selectedGroupsChanged(groupList);
   }

   function newGroupInserted(data){
      const newGroups = [...groups, data];
      Datastore.setGroups(newGroups);
      setGroups(newGroups);
   }

   function deleteRowClicked(){
      setOpenDeleteConfirmDialog(true);
   }

   function deleteRowConfirmed(){
      const newGroups = groups.filter((v,idx)=>{
         return selectionList.indexOf(idx) === -1;
      });
      setGroups(newGroups);
      _updateRows(sensorDataCache, newGroups);
      setOpenDeleteConfirmDialog(false);
      Datastore.setGroups(newGroups);
   }

   function editRowClicked(){
      if (selectionList.length < 1){
         return;
      }

      const idx = selectionList[0];
      setEditDialogData(groups[idx]);
      setIsEditMode(true);
      setShowEditDialog(true);
   }

   function addRowClicked(){
      setIsEditMode(false);
      setEditDialogData({
         label: '',
         keys: [],
      });
      setShowEditDialog(true);
   }

   function acceptGroupEditDialog(data){
      if (isEditMode){
         const idx = selectionList[0];
         const newGroups = [...groups];
         newGroups[idx] = data;
         setGroups(newGroups);
         Datastore.setGroups(newGroups);
      }
      else{
         newGroupInserted(data);
      }
      setShowEditDialog(false);
   }

   return (
      <>
         <Box sx={{ flexGrow: 1 }} flex={1}>
            <DataGrid
               initialState={{
                  columns: {
                     columnVisibilityModel: {
                        pressure: false,
                        targetPsi: false,
                        pressurePercent: false,
                        forcePercent: true,
                     }
                  }
               }}
               density='compact'
               columns={columns}
               rows={rows}
               hideFooter={true}
               components={{
                  Toolbar: GroupTableViewToolbar,
               }}
               componentsProps={{
                  toolbar: {
                     deleteClicked: deleteRowClicked,
                     addClicked: addRowClicked,
                     editClicked: editRowClicked,
                     selectedRows: selectionList,
                  }
               }}
               onSelectionModelChange={(model)=>{
                  selectionModelChanged(model);
               }}
            />
         </Box>
         <GroupEditDialog
            onAccept={acceptGroupEditDialog}
            onReject={()=>{setShowEditDialog(false);}}
            show={showEditDialog}
            data={editDialogData}
         />
         <DeleteConfirmDialog
            title="Delete Group?"
            text="Do you wish to delete the selected group?"
            open={openDeleteConfirmDialog}
            onAccept={deleteRowConfirmed}
            onReject={()=>{setOpenDeleteConfirmDialog(false)}}
         />
      </>
   );
}


export function SensorGroupView(props){
   const [init, setInit] = React.useState(false);
   const [groups, setGroups] = React.useState([]);
   const sensorDataCache = props.sensorDataCache;
   const onSelectedGroupsChanged = props.onSelectedGroupsChanged;

   React.useEffect(()=>{
      async function loadConfig(){
         const cfg = await Datastore.userConfig();
         const groupsCfg = cfg.groups;
         setGroups(groupsCfg);
         setInit(true);
      }

      if (!init){
         loadConfig();
      }

   }, []);

   return (
      <Box
         sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
            backgroundColor: '#fff',
           }}>
         <GroupTableView
            onSelectedGroupsChanged={onSelectedGroupsChanged}
            sensorDataCache={sensorDataCache}
            groups={groups}
            setGroups={setGroups}
         />
      </Box>
   );
}


export default SensorGroupView;
