'use strict';


/** Container to center d3 elements horizontally
 *
 * @param {Object} parent d3 parent element
 * @param {int} width container width used to center elements
 * @param {int} y vertical positioning of the container
 */
function HCenterContainer(parent, width, y){
    (()=>{
        this._container = parent
            .append('g')
            .attr('width', width)
            .attr(
                'transform',
                `translate(0, ${y})`
            );

        this.width = width;
        this.parent = parent;
        this.__children = [];
        this._spacing = 10;
    })();
}


/** Set spacing between children
 *
 * @param {int} space horizontal spacing between elements
 */
HCenterContainer.prototype.spacing = function(space){
    this._spacing = space;
    this.center();
};


/** Append a new element to container
 *
 * @param {Object} elem d3 object
 */
HCenterContainer.prototype.append = function(elem){
    let ret = this._container.append(elem);
    this.__children.push(ret);
    this.center();
    return ret;
};


/** Reposition elements
 */
HCenterContainer.prototype.center = function(){
    let width = this.width; //this._container.node().getBBox().width;

    let totalWidth = 0;
    for (let i=0; i<this.__children.length; ++i){
        let child = this.__children[i];
        let bbox = child.node().getBBox();
        totalWidth += bbox.width + this._spacing;
    }

    let w = 0;
    for (let i=0; i<this.__children.length; ++i){
        let child = this.__children[i];
        let bbox = child.node().getBBox();

        let px = width/2 - totalWidth/2 + w;
        this.__children[i].attr(
            'transform',
            `translate(${px}, 0)`
        );
        w += this._spacing + bbox.width;
    }
};


HCenterContainer.prototype.resize = function(width){
    this.width = width;
    this.center();
};


module.exports = HCenterContainer;
