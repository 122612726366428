import {
   List,
   Datagrid,
   TextField,
   NumberField,
   DateField,
   BooleanField
} from 'react-admin';



export const AlertList = () => (
    <List exporter={false}>
       <Datagrid rowClick="edit">
            <DateField showTime={true} source="createdAt" label="Created"/>
            <DateField showTime={true} source="updatedAt" label="Modified"/>
            <TextField source="text"/>
        </Datagrid>
    </List>
);
