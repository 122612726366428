import * as React from 'react';
import {Admin, Resource}  from 'react-admin';
import DataProvider from './DataProvider';
import AuthProvider from '../auth/AuthProvider';
import LoginPage from '../LoginPage';
import users from './users';
import alerts from './alerts';
import notes from './notes';
import notifiers from './notifiers';
import {AdminLayout} from './AdminLayout';



function AdminDashboard(){
   return (
      <Admin
         basename="/admin"
         layout={AdminLayout}
         loginPage={LoginPage}
         dataProvider={DataProvider}
         authProvider={AuthProvider}
         disableTelemetry>

         <Resource name="users" {...users} />
         <Resource name="notifiers" {...notifiers} />
         <Resource name="alerts" {...alerts} />
         <Resource name="notes" {...notes} />
      </Admin>
   );
}


export default AdminDashboard;
