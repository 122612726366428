import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import config from '../config/config';

const apiUrl = config.apiEndpoint;



function getLocalStorage(key){
   const value = window.localStorage.getItem(key);

   if (value){
      return JSON.parse(value);
   }

   return undefined;
}


// Set auth token in all requests
export const httpClient = async (url, options = {}) => {
   if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
   }
   const token = getLocalStorage('user') || '';
   options.headers.set('x-access-token', token.token);
   return await fetchUtils.fetchJson(url, options);
};


export default {
   getList: (resource, params) => {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
         sort: JSON.stringify([field, order]),
         range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
         filter: JSON.stringify(params.filter),
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;

      return httpClient(url).then(({ headers, json }) => ({
         data: json,
         total: parseInt(headers.get('Content-Range').split('/').pop(), 10),
      }));
   },

   getOne: (resource, params) =>
   httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
   })),

   getMany: (resource, params) => {
      const query = {
         filter: JSON.stringify({ id: params.ids }),
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;
      return httpClient(url).then(({ json }) => ({ data: json }));
   },

   getManyReference: (resource, params) => {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
         sort: JSON.stringify([field, order]),
         range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
         filter: JSON.stringify({
            ...params.filter,
            [params.target]: params.id,
         }),
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;

      return httpClient(url).then(({ headers, json }) => ({
         data: json,
         total: parseInt(headers.get('content-range').split('/').pop(), 10),
      }));
   },

   create: (resource, params) =>
   httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
   }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
   })),

   update: (resource, params) =>
   httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
   }).then(({ json }) => ({ data: json })),

   updateMany: (resource, params) => {
      const query = {
         filter: JSON.stringify({ id: params.ids}),
      };
      return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
         method: 'PUT',
         body: JSON.stringify(params.data),
      }).then(({ json }) => ({ data: json }));
   },

   delete: (resource, params) =>
   httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
   }).then(({ json }) => ({ data: json })),

   deleteMany: (resource, params) => {
      const query = {
         filter: JSON.stringify({ id: params.ids}),
      };
      return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
         method: 'DELETE',
         body: JSON.stringify(params.data),
      }).then(({ json }) => ({ data: json }));
   },
};
