import * as React from 'react';
import {
   Dialog,
   Button,
   DialogTitle,
   DialogContent,
   DialogActions,
   DialogContentText,
} from '@mui/material';



export default function DeleteConfirmDialog(props){
   const open = props.open;
   const onAccept = props.onAccept;
   const onReject = props.onReject;
   const title = props.title;
   const text = props.text;

   return (
      <React.Fragment>
         <Dialog
            open={open}
            onClose={onReject}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
         >
            <DialogTitle id="alert-dialog-title">
               {title}
            </DialogTitle>
            <DialogContent>
               <DialogContentText id="alert-dialog-description">
                  {text}
               </DialogContentText>
            </DialogContent>
            <DialogActions>
               <Button onClick={onReject}>Cancel</Button>
               <Button onClick={onAccept} autoFocus color='error'> Delete </Button>
            </DialogActions>
         </Dialog>
      </React.Fragment>
   );
}
