import * as d3 from 'd3';
import * as React from 'react';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import * as utils from './utils';
import SensorPopover from './SensorPopover';


dayjs.extend(utc);
dayjs.extend(customParseFormat);


function SensorPopoverContentTilt(props){
   const data = props.data || {};
   const label = data.label || '-';
   const value = data.value || '-';
   const t = dayjs.tz(data.t).format('l LT');

   const THead = (props)=>{
      return <TableCell
                align="right"
                variant="head"
                component="th"
                scope="row">{props.label}</TableCell>
   }

   const dcopy = JSON.parse(JSON.stringify(data));
   delete dcopy['label'];
   delete dcopy['t'];
   delete dcopy['value'];

   return (
      <>
         <center><Typography sx={{ p: 1 }}><strong>{label}</strong></Typography></center>
         <Divider></Divider>
         <TableContainer>
            <Table sx={{ minWidth: 100 }} size="small" aria-label="sensor data">
               <TableBody>
                  <TableRow
                     sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                     <THead label="Time"></THead>
                     <TableCell align="left">{t}</TableCell>
                  </TableRow>
                  <TableRow
                     sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                     <THead label="Value"></THead>
                     <TableCell align="left">{utils.formatNumber(value)}</TableCell>
                  </TableRow>
                  {
                     Object.keys(dcopy).map((key, i)=>{
                        return (
                           <TableRow
                              key={key}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                           >
                              <THead label={key}></THead>
                              <TableCell align="left">{utils.formatNumber(dcopy[key])}</TableCell>
                           </TableRow>
                        );
                     })
                  }
               </TableBody>
            </Table>
         </TableContainer>
      </>
   );
}



function _tarrayMax(array, timeField){
   const N = array.length;

   let tmax = 0;
   for (let i=0; i<N; ++i){
      const t = array[i][timeField];
      tmax = Math.max(t, tmax);
   }
   return new Date(tmax);
}


function PlanViewSvgTilt(props){
   const data = props.data;
   const viewName = props.view;
   const opts = props.opts;

   const svg = React.useRef(undefined);
   const svgGroup = React.useRef(undefined);
   const [anchorEl, setAnchorEl] = React.useState(null);
   const [popoverData, setPopoverData] = React.useState({});
   const [popOverOpen, setPopOverOpen] = React.useState(false);
   const [backgroundPath, setBackgroundPath] = React.useState(undefined);
   const transform = React.useRef(d3.zoomIdentity);


   function _setElemOpacity(id, value){
      const pElem = d3.select(id);
      if (!pElem || pElem.empty()){
         return;
      }
      pElem.style('opacity', value);
   }


   function updateSensorValues(data){
      const dispModeKey = 'value';
      const tmax = _tarrayMax(data, 't');

      // Get the elements that should be updated on current view
      const validElems = sensorKeysFromElems(svgGroup.current);
      const updateElemData = data.filter(elem=> validElems.indexOf(elem.label) > -1);

      // Update displayed values/style
      updateElemData.forEach(sensor=>{
         const selector = `#S${sensor.label}-text`;
         const elem = d3.select(selector);
         if (!elem || elem.empty()){
            return;
         }
         const isLatest = sensor.t >= tmax;
         const alpha = isLatest ? 1.0 : 0.30;
         const val = sensor[dispModeKey];

         elem.data(sensor).enter();
         elem.style('opacity', alpha);
         elem.text(utils.formatNumber(val, 3));

         _setElemOpacity(selector.replace('-text', '-arrowpos'), val > 0 ? 1 : 0.1);
         _setElemOpacity(selector.replace('-text', '-arrowneg'), val < 0 ? 1 : 0.1);
         hcenterTextElement(elem);
      });
   }

   /* Return the sensor keys from the svg overlay
    *
    * Sensor keys are assumed to be prefixed with 'S'
    *
    * @param {Dom} group svg group element containing the SVG overlay
    * @return {array[string]} array of keys
    */
   function sensorKeysFromElems(group){
      const rects = group.selectAll("text[id^='S'");
      const ret = [];
      rects.each(function(d){
         const elem = d3.select(this);
         const label = elem.attr('id').replace('S', '');
         if (label.endsWith('-text')){
            ret.push(label.replace('-text', ''));
         }
      });
      return ret;
   }

   function fitToView(svg, g){
      const box = g.node().getBBox();
      const p = g.node().parentNode.parentNode;
      const w = p.offsetWidth;
      const h = p.offsetHeight;
      const scale = Math.min(w/box.width, h/box.height);
      transform.current =
         d3.zoomIdentity
         .translate(w / 2, h / 2)
         .scale(scale)
         .translate(-box.x - box.width / 2, -box.y - box.height / 2);
      d3.zoom().transform(svg, transform.current);
      g.attr('transform', transform.current);
   }

   function itemMouseLeave(){
      setPopOverOpen(false);
   }

   function itemMouseEnter(elem, label){
      setAnchorEl(elem);
      let pdata = data.find(item => {
         return item.label === label;
      });

      if (pdata === undefined){
         return;
      }

      setPopoverData(pdata);
      setPopOverOpen(true);
   }


   /** Horizontally center element relative to its parent
    *
    */
   function hcenterTextElement(elem){
      const bbox = elem.node().parentNode.getBBox();
      const center = bbox.x + bbox.width/2 - elem.node().getBBox().width/2;
      elem.attr('x', center);
   }


   /** Replace the displayed background with specified svg file
    *
    * @param {string} filepath path to the remote SVG file to display as overlay
    * @param {Dom} svg root svg dom element
    * @param {g} g top-most group element within 'svg' element
    */
   async function replaceBackground(filepath, svg, g){
      const elem = await d3.xml(filepath);

      const planNode = d3.select(elem.documentElement).remove().node();
      svg.attr('width', planNode.getAttribute('width'))
         .attr('height', planNode.getAttribute('height'))
         .style('width', '100%')
         .style('height', '100%');

      // Remove existing children
      const children = g.node().children;
      if (children){
         d3.selectAll(children).remove();
      }

      // Add new layout
      g.node().appendChild(planNode);

      // Add labels on top of the sensor locations
      const elemKeys = sensorKeysFromElems(g);
      elemKeys.forEach((key)=>{
         const selector = `#S${key}-text`;
         const elem = g.select(selector);
         elem.text('-');
         hcenterTextElement(elem);

         elem.on('mouseenter', function(d){
            const label = d3.select(this)
                  .attr('id')
                  .replace('-text', '')
                  .substr(1);
            itemMouseEnter(d3.select(this).node(), label);
         });
         elem.on('mouseout', function(d){
            itemMouseLeave(d3.select(this).node());
         });
      });

      fitToView(svg, g);

      return planNode;
   }

   async function _reload(data, viewName, svg){
      let g = undefined;
      if (d3.select('#planview-container').select('svg').empty()){
         const svgElem =
               d3.select('#planview-container')
               .append('svg');
         g = svgElem.append('g');

         const zoom = d3.zoom().on('zoom', e => {
            g.attr('transform', (transform.current = e.transform));
         });
         svgElem.call(zoom)
            .call(zoom.transform, transform.current)
            .node();
         svg.current = svgElem;
         svgGroup.current = g;
      }
      else{
         if (svg.current === undefined){
            const svgElem = d3.select('#planview-container').select('svg');
            const gElem = d3.select(svgElem.select('g').nodes()[0]);
            svg.current = svgElem;
            svgGroup.current = gElem;
         }
      }

      if (viewName && (viewName.background !== backgroundPath)){
         await replaceBackground(viewName.background, svg.current, svgGroup.current);
         setBackgroundPath(viewName.background);
      }
      updateSensorValues(data);

      // Reset mouseenter key cb otherwise values won't be updated on hover
      const elemKeys = sensorKeysFromElems(svgGroup.current);
      elemKeys.forEach((key)=>{
         const selector = `#S${key}-text`;
         const elem = svgGroup.current.select(selector);
         elem.on('mouseenter', function(d){
            const label = d3.select(this)
                  .attr('id')
                  .replace('-text', '')
                  .substr(1);
            itemMouseEnter(d3.select(this).node(), label);
         });
      });

   }

   React.useEffect(()=>{
      _reload(data, viewName, svg);
   }, [data, opts, viewName]);

   return (
      <>
         <Box id="planview-container" sx={{backgroundColor: '#fff', height: '100%'}}>
         </Box>
         <SensorPopover
            anchor={anchorEl} show={popOverOpen}>
            <SensorPopoverContentTilt data={popoverData}/>
         </SensorPopover>
      </>
   );
}


export default PlanViewSvgTilt;
