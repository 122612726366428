'use strict';


/** Return true if the event was triggered by middle mouse button
 * @param {Event} event triggering event
 * @return {Bool} true if evenet was triggered by middle button, false
 *    otherwise
 */
module.exports.isMiddleButton = function(event){
   return event && (event.which == 2 || event.buttons == 4 );
};


/** Return true if the event was triggered by left mouse button
 * @param {Event} event triggering event
 * @return {Bool} true if evenet was triggered by left button, false
 *    otherwise
 */
module.exports.isLeftClick = function(event){
   return event && (event.which == 1 || event.buttons == 1 );
};


/** Return true if Alt, Ctrl, or Shift keys have been pressed
 * @param {Event} event triggering event
 * @return {Bool} true if {Alt, Ctrl, or Shift} were pressed,
 *    false otherwise
 */
module.exports.hasModifier = function(event){
   return event.ctrlKey || event.shiftKey || event.altKey;
};
