import * as React from 'react';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {useNavigate} from 'react-router-dom';
import {useAuth} from './hooks/useAuth';
import SettingsMenuItem from './SettingsMenuItem';




function DashboardToolbar(){
   const [anchorElUser, setAnchorElUser] = React.useState(null);
   const auth = useAuth();
   const navigate = useNavigate();

   function openUserMenu(event){
      setAnchorElUser(event.currentTarget);
   }

   function closeUserMenu(){
      setAnchorElUser(null);
   }

   function openNavMenu(events){
   }

   function logoutUser(){
      auth.logout();
      closeUserMenu();
      navigate('/');
   }

   function userInitials(){
      if (!(auth && auth.user)){
         return 'U';
      }
      if (auth.user){
         const name = auth.user.name.split(' ');
         const first = name[0] || '';
         const last = name[1] || '';
         return [first[0] || '', last[0] || ''].join('').toUpperCase() || 'U';
      }

      return 'U';
   }


   return (
      <AppBar style={{background: '#272727'}}>

      <Container maxWidth={false}>
        <Toolbar variant="dense" disableGutters>

          <div className="nav-logo">
             <a href="/"
                title="Ensoft">
                <img src="https://ensoftinc.com/img/logo.png"
                     width="33px"
                     alt="Ensoft Logo"/></a>
          </div>

          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              ml: 2,
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            LCRA
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="User Options"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={openNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>

          </Box>

          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            LCRA
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={openUserMenu} sx={{ p: 0 }}>
                 <Avatar sx={{bgcolor: '#f4511e'}} balt="hf">{userInitials()}</Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={closeUserMenu}
            >
               { (auth.user && auth.user.admin) &&
                 <MenuItem onClick={()=>{navigate('/admin')}}>
                    <Typography textAlign="center">Admin</Typography>
                 </MenuItem>
               }
               <SettingsMenuItem closeMenu={closeUserMenu}/>
               <MenuItem onClick={logoutUser}>
                  <Typography textAlign="center">Logout</Typography>
               </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
   );
}


export default DashboardToolbar;
