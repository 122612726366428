import {
   Edit,
   SimpleForm,
   DateTimeInput,
   TextInput
} from 'react-admin';



export const NotesEdit = (props) => {
   return (
      <Edit {...props} >
         <SimpleForm >
            <DateTimeInput source="date" defaultValue={new Date()} required/>
            <TextInput source="text" label="Note" fullWidth multiline/>
         </SimpleForm>
      </Edit>
   );
};
