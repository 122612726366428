/**
 * @module SensorGroupPlanWidget
 */

import * as React from 'react';
import * as Datastore from './Datastore';
import {Allotment} from 'allotment';
import SensorGroupView from './SensorGroupView';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Typography from '@mui/material/Typography';
import PlanView from './PlanView';
import TimeWidget from './TimeWidget';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Stack from '@mui/material/Stack';
import config from './config/config';
import DashboardMenuButton from './DashboardMenuButton';


dayjs.extend(utc);
dayjs.extend(timezone);


/** Find the latest date and whether data is in sync
 *
 */
function _dateArrayParse(array, timeField, keyField, validKeys){
   const N = array.length;

   let isInSync = true;
   let latest = undefined;

   for (let i=0; i<N; ++i){
      const t = array[i][timeField];
      const key = array[i][keyField];
      if (validKeys.indexOf(key) < 0){
         continue;
      }

      if (latest === undefined){
         latest = t;
         continue;
      }
      latest = latest > t ? latest : t;
      isInSync = (isInSync && (latest.getTime() === t.getTime()));
   }

   return {
      latest: latest,
      isInSync: isInSync
   };
}


function InlineBox(props){
   return <Box sx={{display: 'inline'}}>{props.children}</Box>;
}



/** Widget showing both sensor groups and plan view
 *
 */
function SensorGroupPlanWidget(){
   const [sensorDataCache, setSensorDataCache] = React.useState({});
   const [sensorData, setSensorData] = React.useState([]);
   const fetchInterval = React.useRef(undefined);
   const [timeWidgetCfg, setTimeWidgetCfg] = React.useState({
      showLatest: true,
      date: dayjs(new Date()).tz('utc')
   });
   const [highlightedKeys, setHighlightedKeys] = React.useState([]);
   const REFRESH_INTERVAL = 5000;
   const paneRef = React.useRef(null);

   function _updateDataCache(newList, newTime, dataCache){
      let ret = {...dataCache};
      for (let i=0; i<newList.length; ++i){
         const key = newList[i].label;
         ret[key] = {
            ...newList[i],
            datetime: newTime,
         };
      }
      return ret;
   }

   /* Request sensor data from endpoint api
    *
    * @param {Date} [date] optional date to request data for
    */
   async function fetchDataRequested(data){
      const qdate = data.showLatest ? undefined : dayjs(data.date).toDate();
      const readings = await Datastore.readings(undefined, qdate);
      // TODO: handle error

      const ptSensors =
         config.sensorGroups.PLATE_JACK.concat(config.sensorGroups.COMP_ROD);
      const dateInfo = _dateArrayParse(readings, 't', 'label', ptSensors);

      setSensorDataCache(_updateDataCache(
         readings, dateInfo.latest, sensorDataCache));

      setSensorData(readings);

      const dispDate = dateInfo.latest || dayjs(data.date).toDate();

      setTimeWidgetCfg({
         ...data,
         date: dispDate
      });
   }


   /* Begin querying API for new data at a fixed interval
    *
    * @param {Date} [date] optional date+time to request data
    */
   function _resetInterval(data){
      if (fetchInterval.current !== undefined){
         clearInterval(fetchInterval.current);
      }

      fetchInterval.current = setInterval(function(){
         fetchDataRequested(data);
      }, REFRESH_INTERVAL);
      fetchDataRequested(data);
   }


   /* Callback for handling TimeWidget state change
    *
    * @param {TimeWidgetState} data new time widget state
    * @return undefined
    */
   function timeWidgetSelectionChanged(data){
      if (data.showLatest){
         _resetInterval(data);
      }
      else{
         clearInterval(fetchInterval.current);
         fetchDataRequested(data);
      }
      setTimeWidgetCfg(data);
   }

   React.useEffect(()=>{
      timeWidgetSelectionChanged(timeWidgetCfg);
   }, []);


   function selectedGroupsChanged(groups){
      let keys = [];

      for (let i=0; i<groups.length; ++i){
         keys = keys.concat(groups[i].keys);
      }

      setHighlightedKeys(keys);
   }


   function minimizeGroupsClicked(){
      paneRef.current.resize([5, 10000]);
   }

   function undoMinimizeGroupsClicked(){
      paneRef.current.resize([200, 600]);
   }

   return (
      <Box sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
         <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <InlineBox/>
            <TimeWidget
               state={timeWidgetCfg}
               onChange={timeWidgetSelectionChanged}/>
            <Stack
               direction="row"
               divider={<Divider orientation="vertical" flexItem />}
               spacing={1} >
               <DashboardMenuButton/>
            </Stack>
         </Box>

         <Allotment defaultSizes={[200, 600]} minSize={20} ref={paneRef}>
            <Box sx={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'space-between'}}>
               <Box sx={{
                       minWidth: 0,
                       width: '100%'
                    }}>
                  <SensorGroupView
                     sensorDataCache={sensorDataCache}
                     onSelectedGroupsChanged={selectedGroupsChanged}
                  />
               </Box>
               <Box sx={{
                       zIndex: 1000,
                       minWidth: 20,
                       backgroundColor: '#eee',
                    }}>
                  <Box sx={{
                          display: 'flex',
                          height: '100%',
                          justifyContent: 'space-between',
                          flexDirection: 'column',
                       }}>
                     <Stack>
                           <IconButton size="small" sx={{p: 0}} onClick={minimizeGroupsClicked}>
                              <ChevronLeftIcon fontSize="inherit"/>
                           </IconButton>
                           <IconButton size="small" sx={{p: 0}} onClick={undoMinimizeGroupsClicked}>
                              <ChevronRightIcon fontSize="inherit"/>
                           </IconButton>
                     </Stack>
                     <Box sx={{
                             width: 20,
                             display: 'inline-block',
                             transform: 'rotate(-90deg)',
                             p: 0, m: 0}}>
                        <Typography variant="caption" component={Box} >
                           Groups
                        </Typography>
                     </Box>
                     <Box></Box>
                  </Box>
               </Box>
            </Box>
            <PlanView sensorData={sensorData} highlightedKeys={highlightedKeys} />
         </Allotment>
      </Box>
   );
}


export default SensorGroupPlanWidget;
