import * as React from 'react';
import Box from '@mui/material/Box';
import {Card, CardActions, CardContent} from '@mui/material';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {Snackbar, Alert} from '@mui/material';
import {FormControlLabel, Checkbox} from '@mui/material';
import CircularProgress from "@mui/material/CircularProgress";
import {useAuth} from './hooks/useAuth';



function TabPanel(props){
   const { children, value, index, ...other } = props;

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`vertical-tabpanel-${index}`}
         aria-labelledby={`vertical-tab-${index}`}
         {...other}
      >
         {value === index && (
            <Box sx={{ p: 3 }}>
               {children}
            </Box>
         )}
      </div>
   );
}


function a11yProps(index){
   return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
   };
}


function ChangePasswordContent(){
   const [loading, setLoading] = React.useState(false);
   const [fieldMeta, setFieldMeta] = React.useState({
      oldPassword: {
         error: false,
         helper: '',
      },
      password1: {
         error: false,
         helper: '',
      },
      password2: {
         error: false,
         helper: '',
      },
   })
   const [splash, setSplash] = React.useState({text: '', type: 'success'});
   const auth = useAuth();

   function clearErrors(){
      for (const [key, data] of Object.entries(fieldMeta)){
         data.error = false;
         data.helper = '';
      }
   }

   function setError(field, text){
      const fieldData = fieldMeta[field];
      if (!fieldData){
         return false;
      }

      fieldData.helper = text;
      fieldData.error = true;

      setFieldMeta({
         ...fieldMeta,
         fieldData
      })
   }

   async function submitForm(event){
      event.preventDefault();

      clearErrors();
      const formData = new FormData(event.currentTarget);
      const oldPassword = formData.get('oldPassword');
      const password1 = formData.get('password1');
      const password2 = formData.get('password2');

      if (password1 !== password2){
         setError('password1', 'Passwords do not match');
         setError('password2', 'Passwords do not match');
         return;
      }

      try{
         setLoading(true);
         const success = await auth.changePassword(oldPassword, password1);
         setSplash({
            text: 'Password changed.',
            type: 'success'
         });
      }
      catch (err){
         setSplash({
            text: err,
            type: 'error'
         });
      }
      setLoading(false);
   }


   return (
      <Box component="form" onSubmit={submitForm} noValidate>
         <TextField
            margin="normal"
            required
            fullWidth
            name="oldPassword"
            label="Existing Password"
            type="password"
            id="oldPassword"
         />
         <TextField
            margin="normal"
            required
            fullWidth
            label="New Password"
            name="password1"
            id="password1"
            type="password"
            helperText={fieldMeta['password1'].helper}
            error={fieldMeta['password1'].error}
         />
         <TextField
            margin="normal"
            required
            fullWidth
            label="Confirm New Password"
            name="password2"
            id="password2"
            type="password"
            helperText={fieldMeta['password2'].helper}
            error={fieldMeta['password2'].error}
         />

         <Box sx={{m: 1, position: 'relative'}}>
            <Button
               type="submit"
               fullWidth
               variant="contained"
               disabled={loading}
               sx={{mt: 3, mb: 2}}
            >
               Save
            </Button>
            {loading && (
               <CircularProgress
                  size={24}
                  sx={{
                     position: 'absolute',
                     top: '50%',
                     left: '50%',
                     marginTop: '-12px',
                     marginLeft: '-12px',
                  }}
               />
            ) }
         </Box>
         <Snackbar
            open={splash.text !== ''}
            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            onClose={()=> setSplash({...splash, text: ''})}
            autoHideDuration={2500}
         >
            <Alert severity={splash.type} sx={{ width: '100%' }}>
               {splash.text}
            </Alert>
         </Snackbar>
      </Box>
   );
}


export function SettingsDialog(props){
   const open = props.open;
   const rejected = props.onClose;
   const [value, setValue] = React.useState(0);

   const handleChange = (event, newValue) => {
      setValue(newValue);
   };

   return (
      <Dialog
         open={open}
         onClose={rejected}
         fullWidth={true}
         maxWidth="sm"
         onKeyDown={(event)=>{event.stopPropagation() }}>

         <DialogTitle>Settings</DialogTitle>
         <DialogContent>
            <Box
               sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
            >
               <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{ borderRight: 1, borderColor: 'divider' }}
               >
                  <Tab label="Change Password" {...a11yProps(0)}></Tab>
               </Tabs>
               <TabPanel value={value} index={0}>
                  <ChangePasswordContent/>
               </TabPanel>
            </Box>

         </DialogContent>
      </Dialog>
   );
}


export default function SettingsMenuItem(props){
   const closeMenu = props.closeMenu;
   const [open, setOpen] = React.useState(false);

   function close(){
      setOpen(false);
   }

   function menuClicked(){
      closeMenu();
      setOpen(true);
   }

   return (
      <>
         <MenuItem onClick={menuClicked}>
            Settings
         </MenuItem>
         <SettingsDialog open={open} onClose={close}/>
      </>
   );
}
