import * as React from 'react';
import {
   Fab,
   Box,
   Menu,
   Paper,
   Stack,
   Slide,
   AppBar,
   Dialog,
   Divider,
   Tooltip,
   Toolbar,
   Collapse,
   MenuItem,
   Typography,
   IconButton,
   ListItemIcon,
   CircularProgress,
} from '@mui/material';
import { TransitionGroup } from 'react-transition-group';

import AddIcon from '@mui/icons-material/Add';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import CloseIcon from '@mui/icons-material/Close';

import {EPlot} from './eplot/EPlot.js';
import './eplot/scss/ep-styles.css';
import * as utils from '../utils';
import * as plotUtils from './PlotUtils';
import * as Datastore from '../Datastore';
import config from '../config/config';
import PlotSelectionDialog from './PlotSelectionDialog';
import DateRangeSelectionWidget from './DateRangeSelectionWidget';
import dayjs from 'dayjs';



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


function throttle(fn, delay) {
    let timer = 0;
    return function(...args) {
        clearTimeout(timer);
        timer = setTimeout(() => fn.apply(this, args), delay);
    }
}


function axisLabel(unitStr, ptModeIndex){
   if (unitStr === 'pt'){
      return config.ptDisplayModes[ptModeIndex].label;
   }

   return unitStr
}



function SensorPlotDialogOptionsButton(props){
   const {onDelete} = props;
   const [anchorEl, setAnchorEl] = React.useState(null);
   const open = Boolean(anchorEl);

   function menuClicked(event){
      setAnchorEl(event.currentTarget);
   }

   function closeMenu(){
      setAnchorEl(null);
   }

   function _callback(fn){
      closeMenu();
      if (!fn){
         return;
      }
      fn();
   }

   function deleteClicked(){
      _callback(onDelete);
   }

   return (
      <>
         <IconButton
            onClick={menuClicked}
            aria-label="Settings"
            aria-controls={open ? 'settings-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            color="primary">
            <MoreVertIcon/>
         </IconButton>
         <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={closeMenu}
         >
            <MenuItem onClick={deleteClicked}>
               <ListItemIcon>
                  <DeleteIcon/>
               </ListItemIcon>
               Delete
            </MenuItem>
         </Menu>
      </>
   );
}


function SensorPlot(props){
   const {
      dateRange,
      id,
      pdata,
      loading,
      onDelete,
      onZoomIn,
      onMouseMove,
      onMouseLeave,
      trackerPosition,
      onChangeSelection,
   } = props;
   const pcache = React.useRef([]);
   const plt = React.useRef(undefined);
   const [progVisibility, setProgVisibility] = React.useState('hidden');

   function mousemove(data){
      let p = data[0];

      // Hack: tracker for xy plots lags behind time-series by the
      // margin
      if (!plt.current._isTimeSeries){
         p += plt.current.bottomAxis.opts.margins[0];
      }

      const x = plt.current.bottomAxis.dom_to_canvas(p);
      onMouseMove(x);
   }

   function mouseleave(){
      onMouseLeave();
   }

   function fitContent(){
      if (!plt.current){
         return;
      }
      const tpoint = plt.current.bottomAxis.dom_to_canvas(0);
      const [t0, t1] = dateRange;
      if (tpoint instanceof Date){
         plt.current.setAxesRange([t0, t1], 'auto', 'auto');
      }
      else{
         plt.current.setAxesRange('auto', 'auto', 'auto');
      }
   }

   React.useEffect(()=>{
      if (!plt.current){
         const plot = new EPlot(`#${id}`, {width: 1000, height: 400});
         plt.current = plot;
         const observer = new ResizeObserver(throttle((entries) => {
            entries.forEach(entry => {
               const w = Math.max(100, entry.contentRect.width-20);
               const h = Math.max(300, entry.contentRect.height-50);
               plt.current.resize(w, h);
               fitContent();
            });
         }, 100));
         observer.observe(document.getElementById(id));
      }

      // Must resubscribe for the state to be updated
      plt.current.rubberband.unsubscribe('rangeSelected', onZoomIn);
      plt.current.rubberband.subscribe('rangeSelected', onZoomIn);
      plt.current.unsubscribe('mousemove', mousemove);
      plt.current.subscribe('mousemove', mousemove);
      plt.current.unsubscribe('mouseleave', mouseleave);
      plt.current.subscribe('mouseleave', mouseleave);

    }, [plt]);

   React.useEffect(()=>{
      if (!plt || !pdata){
         return;
      }
      pcache.current = pdata.data;

      // 'Disable' existing plots that may have been hidden
      const existingData = plt.current.pdata || [];
      for (let i=0; i<existingData.length; ++i){
         const k0 = existingData[i].label;
         for (let j=0; j<pdata.length; ++j){
            if (k0 === pdata[j].label){
               pdata[j].enabled = existingData[i].enabled;
            }
         }
      }
      plt.current.plot(pdata.data);
      fitContent();
      plt.current.leftAxis.label(pdata.leftAxisLabel || '');
      plt.current.rightAxis.label(pdata.rightAxisLabel || '');
      plt.current.tooltip.formatDelegate = pdata.tooltipFormatFn;

      const rightCount = pdata.data.filter(
         (item)=> item.axis === 'right').length;
      const leftCount = pdata.data.length;
      plt.current.legendRight.setMaxColumns(
         plotLegendColumnCount(rightCount));
      plt.current.legendLeft.setMaxColumns(
         plotLegendColumnCount(leftCount));
   }, [pdata]);

   React.useEffect(()=>{
      if (!plt){
         return;
      }

      if (loading){
         setProgVisibility('visible');
      }
      else{
         setProgVisibility('hidden');
      }
   }, [loading]);

   React.useEffect(()=>{
      if (trackerPosition !== undefined){
         plt.current.tracker.hide(false);
         plt.current.tracker.show(trackerPosition);
      }
      else{
         plt.current.tracker.hide(true);
      }
   }, [trackerPosition]);

   function plotLegendColumnCount(N){
      return Math.min(
         Math.max(3, Math.ceil(N/3)), 9);
   }

   function disabledProps(){
      if (progVisibility === 'hidden'){
         return {};
      }
      return {
         opacity:0.5,
         pointerEvents: 'none'
      }
   }

   return (
      <Box sx={{display: 'flex',
                minHeight: '350px',
                maxHeight: '500px',
                p: 2}}>
         <Paper elevation={0}
                sx={{
                   width: '100%', p:1, border: '1px solid #7b7b7b'}}>
            <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'}}>

               <Box sx={{display: 'flex'}}>
                  <Tooltip title="Plot selection">
                     <IconButton
                        onClick={onChangeSelection}
                        aria-label="Settings"
                        aria-haspopup="true"
                        color="primary">
                        <AutoGraphIcon/>
                     </IconButton>
                  </Tooltip>
               </Box>
               <Box sx={{display: 'flex'}}>
                  <SensorPlotDialogOptionsButton
                     onDelete={()=>{
                        onDelete();
                     }}
                  />
               </Box>
            </Box>

            <Box sx={{position: 'relative'}}>
               <Box id={id} sx={{
                       backgroundColor: '#fff',
                       display: 'block',
                       position: 'relative',
                       ...disabledProps()
                    }}/>
                <CircularProgress
                   sx={{
                      position: 'absolute', left: '50%', top: '30%',
                      visibility: progVisibility
                   }}/>
            </Box>
         </Paper>
      </Box>
   );
}


async function fetchUsersPlotsState(){
   const userCfg = await Datastore.userConfig();
   const plotDashCfg = userCfg.plotDashboard;

   if (!plotDashCfg){
      return {
         dateRange: [
            utils.dateAddSeconds(utils.nowMountain(), -3600),
            utils.nowMountain()
         ],
         plots: []
      }
   }

   const dRange = plotDashCfg.dateRange || [];
   if ('dateRange' in plotDashCfg){
      const t0 = new Date(dRange[0]);
      const t1 = new Date(dRange[1]);
      plotDashCfg.dateRange = [t0, t1];
   }
   else{
      plotDashCfg.dateRange = [
         utils.dateAddSeconds(utils.nowMountain(), -3600),
         utils.nowMountain()
      ];
   }

   return plotDashCfg;
}


async function storePlotStates(plots, dateRange){
   return Datastore.saveUserData('plotDashboard', {
      dateRange: dateRange,
      plots: plots
   });
}


async function scrollElemIntoView(id, delays){
   const maxTries = 10;
   const sleep = (ms)=>{
      return new Promise(resolve => setTimeout(resolve, ms));
   }

   for (let i=0; i<maxTries; ++i){
      const elem = document.getElementById(id);
      if (elem){
         elem.scrollIntoView({behavior: 'smooth'});
         return;
      }
      await sleep(delays || 100);
   }
}


async function fetchSensorHistory(selection, dateRange){
   const dRange = dateRange;
   const {
      tsSelectionLeft,
      tsSelectionRight,
      isTimeSeries,
      xySelection,
      displayModeIndex} = selection;
   const [t0, t1] = dateRange;

   let leftAxisLabel = '';
   let rightAxisLabel = '';

   let pdata = [];
   let tooltipFormat = plotUtils.tsFormat;
   if (isTimeSeries){
      const elem0 = tsSelectionLeft[0] || {};
      leftAxisLabel = axisLabel(elem0.unit, displayModeIndex);

      pdata = await plotUtils.fetchTsPlotData(
         tsSelectionLeft,
         displayModeIndex,
         dRange[0], dRange[1],
         'left');

      const rightData = await plotUtils.fetchTsPlotData(
         tsSelectionRight,
         displayModeIndex,
         dRange[0], dRange[1],
         'right'
      );
      const elem0Right = tsSelectionRight[0] || {};
      rightAxisLabel = axisLabel(elem0Right.unit, displayModeIndex);

      // plt.current.legendRight.setMaxColumns(
      //    plotLegendColumnCount(tsSelectionRight.length));
      // plt.current.legendLeft.setMaxColumns(
      //    plotLegendColumnCount(tsSelectionLeft.length));
      pdata = pdata.concat(rightData);
   }
   else{
      const elem0 = tsSelectionLeft[0] || {};
      leftAxisLabel = axisLabel(elem0.unit, displayModeIndex);
      pdata = await plotUtils.fetchXyPlotData(xySelection, displayModeIndex, t0, t1);
      tooltipFormat = plotUtils.xyFormat;
   }

   return {
      data: pdata,
      leftAxisLabel: leftAxisLabel,
      rightAxisLabel: rightAxisLabel,
      tooltipFormatFn: tooltipFormat
   }
}


const UserPlotsDialog = React.forwardRef(function(props, ref){
   const {open, setOpen} = props;
   // Load/sync from/to server
   const state = React.useRef({
      plots: [],
      dateRange: [
         utils.dateAddSeconds(utils.nowMountain(), -3600),
         utils.nowMountain()
      ]
   });
   const [plotData, setPlotData_] = React.useState([]);
   // Copy of plotData that's in sync and can be accessed in fns
   const plotDataS = React.useRef([]);

   const plotStates = React.useRef([]);
   const [loadingStates, setLoadingStates] = React.useState([]);
   const [rangeText, setRangeText] = React.useState('');
   const [selectionDialogOpen, setSelectionDialogOpen] = React.useState(false);
   const activePlotIndex = React.useRef(-1);
   const [trackerPos, setTrackerPos] = React.useState(undefined);
   const [activePlotSelection, setActivePlotSelection] = React.useState({
      displayModeIndex: 0,
      isTimeSeries: true,
      tsSelectionLeft: [
      ],
      tsSelectionRight: [
      ]
   });

   const beenOpened = React.useRef(false);
   const _init = React.useRef(false);

   React.useEffect(()=>{
      if (open && !beenOpened.current){
         beenOpened.current = true;
         init();
      }
   }, [open]);

   async function init(){
      if (_init.current){
         return;
      }
      _init.current = true;
      const cfg = await fetchUsersPlotsState();
      plotStates.current = cfg.plots;
      onRangeChange(cfg.dateRange[0], cfg.dateRange[1], cfg.plots);
   }

   function setPlotData(data){
      setPlotData_(data);
      plotDataS.current = data;
   }

   async function onRangeChange(start, end, plotStates){
      const t0_str = dayjs(start).format('l LT');
      let t1_str = '';
      if (end === undefined){
         end = utils.nowMountain();
         t1_str = 'now';
      }
      else{
         t1_str = dayjs(end).format('l LT');
      }

      const newState = {
         ...state.current,
         dateRange: [start, end]
      };
      state.current = newState;
      setRangeText(`${t0_str}|${t1_str}`);

      await replot(newState.dateRange, plotStates);
   }


   function zoomInCb(range, index){
      const pstate = plotStates.current[index];
      if (pstate.isTimeSeries){
         return onRangeChange(range[0], range[1]);
      }
      else{
         // Requesting data based on xy data range doesn't make
         // sense. Instead, truncate the data such that the plot data
         // falls in the selected range and the selected plot zooms in
         // to selection
         let pdata = plotDataS.current[index];
         const seriesList = pdata.data;

         for (let i=0; i<seriesList.length; ++i){
            const series = seriesList[i];
            const truncated = [];
            for (let j=0; j<series.data.length; ++j){
               const p = series.data[j];
               if ((p[0] >= range[0]) && (p[0] <= range[1])){
                  truncated.push(p);
               }
            }
            series.data = truncated;
         }
         pdata = {...pdata, data:pdata.data}
         plotDataS.current[index] = pdata;
         setPlotData([...plotDataS.current]);
      }
   }

   function zoomOutCb(){
      const dRange = state.current.dateRange;
      const t0 = dRange[0];
      const t1 = dRange[1] || utils.nowMountain();

      const dt_s = (t1.getTime() - t0.getTime())/1000;
      const offsetS = 0.5 * dt_s;

      const t0new = utils.dateAddSeconds(t0, -offsetS);
      const t1new = utils.dateAddSeconds(t1, offsetS);
      onRangeChange(t0new, t1new);
   }

   function refreshCb(){
      replot(state.current.dateRange);
   }


   function handleClose(){
      return setOpen(false);
   }


   function addPlotClicked(){
      activePlotIndex.current = -1;
      setSelectionDialogOpen(true);
      const newItem = {
         displayModeIndex: 0,
         isTimeSeries: true,
         tsSelectionLeft: [
         ],
         tsSelectionRight: [
         ]
      }
      setActivePlotSelection(newItem);
      setSelectionDialogOpen(true);
   }

   function showTrackers(x){
      setTrackerPos(x);
   }

   function setLoading(tf, index){
      let cpy = [...loadingStates];
      for (let i=loadingStates.length; i<plotStates.current.length; ++i){
         cpy.push(tf);
      }
      if (!index){
         for (let i=0; i<cpy.length; ++i){
            cpy[i] = tf;
         }
         setLoadingStates(cpy);
      }

      cpy[index] = tf;
      setLoadingStates(cpy);
   }

   async function replot(dateRange, states){
      states = states ? states : plotStates.current;
      if (!states){
         return;
      }
      storePlotStates(states, state.current.dateRange);

      setLoading(true);
      const allData = await Promise.all(states.map((state)=>{
         if (!state){
            return {};
         }
         return fetchSensorHistory(state, dateRange);
      }));
      setPlotData(allData);
      setLoading(false);
   }

   async function replotGraph(index, dateRange, state){
      state = state ? state : plotStates.current[index];
      if (!state){
         return;
      }
      setLoading(true, index);
      const x = await fetchSensorHistory(state, dateRange);
      setLoading(false, index);
      const tmp = [...plotData];
      tmp[index] = x;
      setPlotData(tmp);
   }

   function plotSelectionDialogAccepted(newState){
      if (activePlotIndex.current < 0){
         activePlotIndex.current = plotStates.current.length;
         plotStates.current.push(newState);
         const elemId = `_sp-${activePlotIndex.current}`;
         scrollElemIntoView(elemId, 200);
      }
      else{
         plotStates.current[activePlotIndex.current] = newState;
      }

      replotGraph(activePlotIndex.current, state.current.dateRange, newState);
      setSelectionDialogOpen(false);
      storePlotStates(plotStates.current, state.current.dateRange);
   }

   function plotSelectionDialogRejected(){
      setSelectionDialogOpen(false);
   }

   return (
      <React.Fragment>
         <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            keepMounted={true}
            TransitionComponent={Transition}
         >
            <AppBar position="sticky">
               <Toolbar variant="dense">
                  <IconButton
                     edge="start"
                     color="inherit"
                     onClick={handleClose}
                     aria-label="close"
                  >
                     <CloseIcon />
                  </IconButton>
                  <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                     Plot Dashboard
                  </Typography>

                  <Stack
                     direction="row"
                     color="inherit"
                     divider={<Divider orientation="vertical" flexItem />}
                     spacing={1} >
                     <IconButton
                        aria-label="Refresh out"
                        color="inherit"
                        onClick={()=>{refreshCb()}}>
                        <Tooltip title="Refresh graphs">
                           <RefreshIcon/>
                        </Tooltip>
                     </IconButton>

                     <DateRangeSelectionWidget
                        onRangeChange={onRangeChange}
                        rangeText={rangeText}
                        startDate={state.current.dateRange[0]}
                        endDate={state.current.dateRange[1]}
                        setRangeText={setRangeText}
                        color="inherit"
                     />

                     <IconButton
                        aria-label="Zoom out"
                        color="inherit"
                        onClick={()=>{zoomOutCb()}}>
                        <Tooltip title="Zoom out">
                           <ZoomOutIcon/>
                        </Tooltip>
                     </IconButton>
                  </Stack>

               </Toolbar>
            </AppBar>

            <Stack spacing={2}>
               <TransitionGroup>
                  {plotStates.current.map((elem, idx, arr)=>{
                     return (
                        <Collapse key={idx}>
                           <SensorPlot
                              key={idx}
                              id={`_sp-${idx}`}
                              loading={loadingStates[idx]}
                              pdata={plotData[idx]}
                              dateRange={state.current.dateRange}
                              onDelete={()=>{
                                 plotStates.current.splice(idx, 1);

                                 const newData = [...plotData];
                                 newData.splice(idx, 1);

                                 setPlotData(newData);
                                 storePlotStates(plotStates.current, state.current.dateRange);
                              }}
                              onChangeSelection={()=>{
                                 activePlotIndex.current = idx;
                                 setActivePlotSelection(plotStates.current[idx]);
                                 setSelectionDialogOpen(true);
                              }}
                              onZoomIn={(range)=>{zoomInCb(range, idx)}}
                              onMouseMove={(d)=>{
                                 showTrackers(d);
                              }}
                              onMouseLeave={()=>{
                                 showTrackers(undefined);
                              }}
                              trackerPosition={trackerPos}
                           />
                        </Collapse>
                     );
                  })}
               </TransitionGroup>
            </Stack>

            <Box sx={{
                    margin: 0,
                    top: 'auto',
                    right: 20,
                    bottom: 20,
                    left: 'auto',
                    position: 'fixed',
                 }}>
               <Tooltip title="Add new plot">
                  <Fab color="primary" aria-label="add" onClick={addPlotClicked}>
                     <AddIcon />
                  </Fab>
               </Tooltip>
            </Box>

            <PlotSelectionDialog
               onAccept={plotSelectionDialogAccepted}
               onReject={plotSelectionDialogRejected}
               selection={activePlotSelection}
               open={selectionDialogOpen}/>
         </Dialog>
      </React.Fragment>
   );
});

export default UserPlotsDialog;
