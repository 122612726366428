import * as d3 from 'd3';
import * as React from 'react';
import Typography from '@mui/material/Typography';
import SensorPopover from './SensorPopover';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Divider from '@mui/material/Divider';
import * as utils from './utils';
import config from './config/config';


dayjs.extend(utc);
dayjs.extend(customParseFormat);



function _tarrayMax(array, timeField){
   const N = array.length;

   let tmax = 0;
   for (let i=0; i<N; ++i){
      const t = array[i][timeField];
      tmax = Math.max(t, tmax);
   }
   return new Date(tmax);
}


function PropView(props){
   const val = props.value === undefined ? '-' : props.value;
   return (
      <Typography sx={{p: 1}}> {props.label}: {val} </Typography>
   );
}


function SensorPopoverContentPT(props){
   const data = props.data || {};
   const label = data.label || '-';
   const t = dayjs.tz(data.t).format('l LT');

   const rows = [
      {
         label: 'Time',
         value: t,
      },
      {
         label: 'Force (kips)',
         value: data.kips,
      },
      {
         label: 'Pressure (psi)',
         value: data.psi,
      },
      {
         label: 'Target Force (kips)',
         value: data.targetKips,
      },
      {
         label: 'Target Pressure (psi)',
         value: data.targetPsi,
      },
      {
         label: 'Pressure/Target (%)',
         value: utils.formatNumber(data.psi/data.targetPsi * 100, 1)
      },
      {
         label: 'Force/Target (%)',
         value: utils.formatNumber(data.kips/data.targetKips * 100, 1)
      }
   ];

   return (
      <>
         <center><Typography sx={{ p: 1 }}><strong>{label}</strong></Typography></center>
         <Divider></Divider>
         <TableContainer>
            <Table sx={{ minWidth: 100 }} size="small" aria-label="sensor data">
               <TableBody>
                  {rows.map((row,i)=>{
                     return <TableRow
                               sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                               key={i}
                            >
                               <TableCell
                                  align="right"
                                  variant="head"
                                  component="th"
                                  scope="row">{row.label}</TableCell>
                               <TableCell align="left">{row.value}</TableCell>
                            </TableRow>
                  })}
               </TableBody>
            </Table>
         </TableContainer>
      </>
   );
}


function PlanViewSvgPT(props){
   const data = props.data;
   const viewName = props.view;
   const opts = props.opts;

   const [svg, setSvg] = React.useState(undefined);
   const [svgGroup, setSvgGroup] = React.useState(undefined);
   const [anchorEl, setAnchorEl] = React.useState(null);
   const [popoverData, setPopoverData] = React.useState({});
   const [popOverOpen, setPopOverOpen] = React.useState(false);
   const [backgroundPath, setBackgroundPath] = React.useState(undefined);
   const highlightedKeys = props.highlightedKeys || [];
   const transform = React.useRef(d3.zoomIdentity);

   function updateSensorValues(data){
      const valueGetter = config.ptDisplayModes[opts.displayModeIndex].value;

      const tmax = _tarrayMax(data, 't');

      const ids = data.map((elem) => (`#S${elem.label}-text`));
      for (let i=0; i<ids.length; ++i){
         const id = ids[i];
         const elem = d3.select(id);
         if (!elem || elem.empty()){
            continue;
         }
         const isLatest = data[i].t >= tmax;
         const alpha = isLatest ? 1.0 : 0.30;
         const val = valueGetter(data[i]);

         elem.data(data[i]).enter();
         elem.style('opacity', alpha);
         elem.style('fill', data[i].color);
         elem.select('tspan')
            .text(val.toFixed(0).toLocaleString());
      }
   }


   function dehighlightSensors(parent){
      parent = parent || d3;
      parent.selectAll('._sensor_rect')
         .attr('stroke-width', 0.5);
   }

   function highlightSensor(label, parent){
      parent = parent || d3;
      parent
         .select('#S' + label)
         .attr('stroke-width', 3);
   }

   function highlightSensors(labels, parent){
      parent = parent || d3;
      dehighlightSensors(parent);
      for (let i=0; i<labels.length; ++i){
         highlightSensor(labels[i], parent);
      }
   }

   function itemMouseEnter(elem, label){
      setAnchorEl(elem);
      let pdata = undefined;
      for (let i=0; i<data.length; ++i){
         if (data[i].label === label){
            pdata = data[i];
            break;
         }
      }

      if (pdata === undefined){
         return;
      }

      setPopoverData(pdata);
      setPopOverOpen(true);
   }

   function itemMouseLeave(){
      setPopOverOpen(false);
   }

   /* Return the sensor keys from the svg overlay
    *
    * Sensor keys are assumed to be prefixed with 'S'
    *
    * @param {Dom} group svg group element containing the SVG overlay
    * @return {array[string]} array of keys
    */
   function sensorKeysFromElems(group){
      const ret = [];
      for (const shape of ['rect', 'circle']){
         const rects = group.selectAll(shape + "[id^='S'");
         rects.each(function(d){
            const elem = d3.select(this);
            const label = elem.attr('id').replace('S', '');
            if (label.indexOf('-label') < 0){
               ret.push(label);
            }
         });
      }
      return ret;
   }

   function fitToView(svg, g){
      const box = g.node().getBBox();
      const p = g.node().parentNode.parentNode;
      const w = p.offsetWidth;
      const h = p.offsetHeight;
      const scale = Math.min(w/box.width, h/box.height);
      transform.current =
         d3.zoomIdentity
         .translate(w / 2, h / 2)
         .scale(scale)
         .translate(-box.x - box.width / 2, -box.y - box.height / 2);
      d3.zoom().transform(svg, transform.current);
      g.attr('transform', transform.current);
   }

   function replacePlaceholderElement(key, g){
      const selector = `#S${key}`;
      const elem = g.select(selector);

      let posElem = elem;
      const labelElem = d3.select(`#S${key}-label`);
      if (!labelElem.empty()){
         // Hide the placeholder
         labelElem.attr('display', 'none');
         posElem = labelElem;
      }

      const parent = d3.select(posElem.node().parentNode);

      let xkey = 'x';
      let ykey = 'y';
      let wkey = 'width';
      let hkey = 'height';
      if (posElem.node().nodeName === 'circle'){
         xkey = 'cx';
         ykey = 'cy';
         wkey = 'r';
         hkey = 'r';
      }

      const w = parseFloat(posElem.attr(wkey));
      const h = parseFloat(posElem.attr(hkey));
      const x = parseFloat(posElem.attr(xkey));
      const y = parseFloat(posElem.attr(ykey));

      let xtrans = x;
      let ytrans = y;
      let text_yp = h/2;
      if (posElem.node().nodeName === 'circle'){
         xtrans = x - w/2;
         ytrans = y - w/2;
      }

      let trans = '';
      if (posElem.attr('transform')){
         trans += posElem.attr('transform');
      }
      trans += ` translate(${xtrans}, ${ytrans}) `;

      const group = parent
            .append('g')
            .attr('transform', trans)
            .attr('id', `_G${key}`);

      const text = group
            .append('text')
            .attr('font-size', '13px')
            .attr('fill', '#000')
            .attr('user-select', 'none')
            .attr('cursor', 'context-menu')
            .text(key);
      const bbox = text.node().getBBox();

      text.attr('x', w/2 - bbox.width/2)
         .attr('y', text_yp + bbox.height/2-2);

      // Sensor
      const rect = parent
            .append(posElem.node().nodeName)
            .attr('class', '_sensor_rect')
            .attr(xkey, elem.attr(xkey))
            .attr(ykey, elem.attr(ykey))
            .attr(wkey, elem.attr(wkey))
            .attr(hkey, elem.attr(hkey))
            .attr('fill', 'none')
            .attr('id', 'S' + key)
            .attr('stroke', '#6c6c6c')
            .attr('stroke-width', 0.5);

      posElem.remove();
      elem.remove();
   }

   /** Replace the displayed background with specified svg file
    *
    * @param {string} filepath path to the remote SVG file to display as overlay
    * @param {Dom} svg root svg dom element
    * @param {g} g top-most group element within 'svg' element
    */
   async function replaceBackground(filepath, svg, g){
      const elem = await d3.xml(filepath);

      const planNode = d3.select(elem.documentElement).remove().node();
      svg.attr('width', planNode.getAttribute('width'))
         .attr('height', planNode.getAttribute('height'))
         .style('width', '100%')
         .style('height', '100%');

      // Remove existing children
      const children = g.node().children;
      if (children){
         d3.selectAll(children).remove();
      }

      // Add new layout
      g.node().appendChild(planNode);

      /**
       *
       * 'SW' -- displays sensor label and the
       * (selectable/highlightable) sensor indicator. Its center also
       * specifies sensor label location if 'SW-label' does not exist.
       *
       * 'SW-label' -- optional. if available, its center specifies sensor
       *    label location. Otherwise it's placed under 'SW*'
       *
       * 'SW-text' -- sensor reading value
       */

      // Add labels on top of the sensor locations
      const elemKeys = sensorKeysFromElems(g);
      elemKeys.forEach((key)=>{
         replacePlaceholderElement(key, g);
      });

      const sensorGroups = g.selectAll("g[id^='_G'");
      sensorGroups.on('click', function(d){
         // const label = d3.select(this).attr('id').replace('_G', '');
         // const x = d3.select(this).attr('x')
         // const y = d3.select(this).attr('y')
         // highlightSensor(label);
      });

      sensorGroups.on('mouseenter', function(d){
         const label = d3.select(this).attr('id').replace('_G', '');
         itemMouseEnter(d3.select(this).node(), label);
      });

      sensorGroups.on('mouseout', function(d){
         itemMouseLeave(d3.select(this).node());
      });

      fitToView(svg, g);

      return planNode;
   }

   async function _reload(data, viewName, svg, highlightedKeys){
      let g = undefined;
      if (!svg){
         const svgElem =
               d3.select('#planview-container')
               .append('svg');
         g = svgElem.append('g');

         const zoom = d3.zoom().on('zoom', e => {
            g.attr('transform', (transform.current = e.transform));
         });
         svgElem.call(zoom)
            .call(zoom.transform, transform.current)
            .node();
         setSvg(svgElem);
         setSvgGroup(g);
      }

      if (viewName && (viewName.background !== backgroundPath)){
         await replaceBackground(viewName.background, svg, svgGroup);
         setBackgroundPath(viewName.background);
      }
      updateSensorValues(data);

      const sensorGroups = (g || svgGroup).selectAll("g[id^='_G'");
      sensorGroups.on('mouseenter', function(d){
         const label = d3.select(this).attr('id').replace('_G', '');
         itemMouseEnter(d3.select(this).node(), label);
      });

      highlightSensors(highlightedKeys);
   }

   React.useEffect(()=>{
      _reload(data, viewName, svg, highlightedKeys);
   }, [data, opts, viewName, svg, highlightedKeys]);

   return (
      <>
         <Box id="planview-container" sx={{backgroundColor: '#fff', height: '100%'}}>
         </Box>
         <SensorPopover
            anchor={anchorEl} show={popOverOpen}>
            <SensorPopoverContentPT data={popoverData}/>
         </SensorPopover>
      </>
   );
}


export default PlanViewSvgPT;
