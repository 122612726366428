import * as React from 'react';
import Box from '@mui/material/Box';
import {Allotment} from 'allotment';
import SensorGroupPlanWidget from './SensorGroupPlanWidget';
import DashboardToolbar from './DashboardToolbar';
import TSPlot from './plot/TSPlot';
import 'allotment/dist/style.css';

import IconButton from '@mui/material/IconButton';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';



function Dashboard(){

   const paneRef = React.useRef(null);
   const paneDims = React.useRef([100, 50]);

   function minimizeLowerPane(){
      const dims = paneDims.current;
      if ( dims[1]/dims[0] > 1){
         paneRef.current.reset();
         return;
      }

      paneRef.current.resize([1000, 5]);
   }

   function expandLowerPane(){
      const dims = paneDims.current;
      if ( dims[0]/dims[1] > 1.5){
         paneRef.current.reset();
         return;
      }
      paneRef.current.resize([20, 1000]);
   }

   return (
      <>
         <Box sx={{height: '100vh', width: '100%', display: 'flex', flexDirection: 'column'}} id="asdasd">
            <DashboardToolbar/>
            <Box sx={{display: 'flex', flexDirection: 'column', height: '100vh', mt: '48px'}}>
               <Allotment vertical={true}
                          defaultSizes={[100, 50]}
                          onChange={(dims)=>{ paneDims.current = dims; }}
                          minSize={20} ref={paneRef}>
                  <SensorGroupPlanWidget/>

                  <Box sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          backgroundColor: '#eee',
                          height: '100%'
                       }}>
                     <Box sx={{
                             display: 'flex',
                             justifyContent: 'space-between',
                             flexDirection: 'row',
                          }}>
                        <Box/>
                        <Box sx={{
                                display: 'inline-block',
                                p: 0, m: 0}}>
                           <Typography variant="caption" component={Box} >
                              Plots
                           </Typography>
                        </Box>
                        <Box sx={{display: 'inline-block', pr: 1}}>
                           <Stack direction="row">
                              <IconButton size="small" sx={{p: 0}} onClick={minimizeLowerPane}>
                                 <ExpandMore fontSize="inherit"/>
                              </IconButton>
                              <IconButton size="small" sx={{p: 0}} onClick={expandLowerPane}>
                                 <ExpandLess fontSize="inherit"/>
                              </IconButton>
                           </Stack>
                        </Box>
                     </Box>
                     <TSPlot/>
                  </Box>

               </Allotment>
            </Box>
         </Box>
      </>
   );
}


export default Dashboard;
