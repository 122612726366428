import Dashboard from './Dashboard';
import LoginPage from './LoginPage';
import RequireLogin from './RequireLogin';
import AdminDashboard from './admin/AdminDashboard';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import AuthLayout from './auth/AuthLayout';
import AlertsView from './AlertsView';


function App(){

   return (
      <BrowserRouter>
         <Routes>
            <Route element={<AuthLayout/>}>

               <Route exact
                      path="/"
                      element={<RequireLogin target="/dashboard" login="/login"/>} />

               <Route exact
                      path="/dashboard"
                      element={
                         <RequireLogin login="/login">
                            <Dashboard/>
                         </RequireLogin>
                      }
               />

               <Route exact
                      path="/admin/*"
                      element={
                         <RequireLogin login="/login">
                            <AdminDashboard/>
                         </RequireLogin>
                      }
               />

               <Route exact path="/login" element={<LoginPage/>} />
               <Route exact path="/alerts" element={<AlertsView/>} />
            </Route>
         </Routes>
      </BrowserRouter>
   );
}


export default App;
