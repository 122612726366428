import {NotesList} from './NotesList';
import {NotesCreate} from './NotesCreate';
import {NotesEdit} from './NotesEdit';



export default {
   list: NotesList,
   edit: NotesEdit,
   create: NotesCreate
};
