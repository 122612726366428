import * as React from 'react';
import {
   Box,
   Menu,
   Button,
   Tooltip,
   MenuItem,
   Typography,
} from '@mui/material';
import DateTimeRangeSelectionDialog from './DateTimeRangeSelectionDialog';
import * as utils from '../utils';



export default function DateRangeSelectionWidget(props){
   /** Callback when selected date range is changed
    *
    * @function dateRangeSelectionRejected
    * @param {Date} start Date
    * @param {Date} end Date
    */
   const onRangeChange = props.onRangeChange;
   const rangeText = props.rangeText;
   const setRangeText = props.setRangeText;
   const startDate = props.startDate;
   const endDate = props.endDate;
   const color = props.color || "primary";

   const [anchorEl, setAnchorEl] = React.useState(null);
   const [selectionDialogOpen, setSelectionDialogOpen] = React.useState(false);

   const ONEHR_S = 3600;
   const options = [
      {
         label: '15 min',
         value: ONEHR_S*0.25
      },
      {
         label: '1 hour',
         value: ONEHR_S
      },
      {
         label: '2 hours',
         value: ONEHR_S*2
      },
      {
         label: '1 day',
         value: ONEHR_S*24
      },
      {
         label: '3 days',
         value: ONEHR_S*24*3
      },
      {
         label: 'Custom',
         value: undefined
      }
   ];

   function closeMenu(){
      setAnchorEl(null);
   }

   function openMenu(event){
      setAnchorEl(event.currentTarget);
   }

   function menuItemClicked(index){
      closeMenu();

      const opt = options[index];
      const backOffsetSecs = opt.value;
      if (backOffsetSecs === undefined){
         setSelectionDialogOpen(true);
         return;
      }

      setRangeText(opt.label);
      onRangeChange(
         utils.dateAddSeconds(utils.nowMountain(), -backOffsetSecs), undefined);

      closeMenu();
   }

   function closeRangeSelectionDialog(){
      setSelectionDialogOpen(false);
   }

   function rangeSelectionDialogRejected(){
      closeRangeSelectionDialog();
   }

   function rangeSelectionDialogAccepted(startDate, endDate){
      closeRangeSelectionDialog();
      onRangeChange(startDate, endDate);
   }

   return (
      <>
         <DateTimeRangeSelectionDialog
            onAccept={rangeSelectionDialogAccepted}
            onReject={rangeSelectionDialogRejected}
            startDate={startDate}
            endDate={endDate}
            open={selectionDialogOpen} />

         <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Change plot range">
               <Button onClick={openMenu}
                       sx={{display: 'block'}}
                       color={color}>
                  { rangeText.split('|').map((elem, i)=>{
                     return (
                        <Typography key={i} sx={{display: 'block'}} variant="caption">
                           {elem}
                        </Typography>
                     );
                  })}
               </Button>
            </Tooltip>

            <Menu
               sx={{ mt: '45px' }}
               id="menu-appbar"
               anchorEl={anchorEl}
               anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
               }}
               keepMounted
               transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
               }}
               open={Boolean(anchorEl)}
               onClose={closeMenu}
            >
               {options.map((opt, i) => (
                  <MenuItem key={i} onClick={()=>{ menuItemClicked(i); }}>
                     <Typography textAlign="center">{opt.label}</Typography>
                  </MenuItem>
               ))}
            </Menu>
         </Box>
      </>
   );
}
