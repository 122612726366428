import * as React from 'react';
import * as Datastore from './Datastore';
import dataProvider from './admin/DataProvider';
import auth from './auth/AuthProvider.js';
import {
   Box,
   Paper,
   Stack,
   Popper,
   Dialog,
   Button,
   Tooltip,
   Divider,
   TextField,
   Typography,
   IconButton,
   DialogTitle,
   DialogContent,
   DialogActions,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
   DataGrid,
   GridToolbarContainer
} from '@mui/x-data-grid';
import { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import DeleteConfirmDialog from './DeleteConfirmDialog';
import * as utils from './utils';



function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}


const GridCellExpand = React.memo(function GridCellExpand(props){
   const { width, value } = props;
   const wrapper = React.useRef(null);
   const cellDiv = React.useRef(null);
   const cellValue = React.useRef(null);
   const [anchorEl, setAnchorEl] = React.useState(null);
   const [showFullCell, setShowFullCell] = React.useState(false);
   const [showPopper, setShowPopper] = React.useState(false);

   const handleMouseEnter = () => {
      const isCurrentlyOverflown = isOverflown(cellValue.current);
      setShowPopper(isCurrentlyOverflown || value.includes('\n'));
      setAnchorEl(cellDiv.current);
      setShowFullCell(true);
   };

   const handleMouseLeave = () => {
      setShowFullCell(false);
   };

   React.useEffect(() => {
      if (!showFullCell) {
         return undefined;
      }

      function handleKeyDown(nativeEvent) {
         // IE11, Edge (prior to using Bink?) use 'Esc'
         if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
            setShowFullCell(false);
         }
      }

      document.addEventListener('keydown', handleKeyDown);

      return () => {
         document.removeEventListener('keydown', handleKeyDown);
      };
   }, [setShowFullCell, showFullCell]);


   return (
      <Box
         ref={wrapper}
         onMouseEnter={handleMouseEnter}
         onMouseLeave={handleMouseLeave}
         sx={{
            alignItems: 'center',
            lineHeight: '24px',
            width: 1,
            height: 1,
            position: 'relative',
            display: 'flex',
         }}
      >
         <Box
            ref={cellDiv}
            sx={{
               height: 1,
               width,
               display: 'block',
               position: 'absolute',
               top: 0,
            }}
         />
         <Box
            ref={cellValue}
            sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
         >
            {value}
         </Box>
         {showPopper && (
            <Popper
               open={showFullCell && anchorEl !== null}
               anchorEl={anchorEl}
               style={{ width, marginLeft: -17, zIndex:9999, maxWidth: '400px' }}
            >
               <Paper
                  elevation={1}
                  style={{ minHeight: wrapper.current.offsetHeight - 3 }}
               >
                  <Typography variant="body2" style={{ padding: 8, whiteSpace: 'pre-line' }}>
                     {value}
                  </Typography>
               </Paper>
            </Popper>
         )}
      </Box>
   );
});


function renderLongText(props){
   return (
      <GridCellExpand {...props}>
      </GridCellExpand>
   );
}


function NotesTableToolbar(props){
   const selectedRows = props.selectedRows;
   const deleteClicked = props.deleteClicked;
   const addClicked = props.addClicked;
   const editClicked = props.editClicked;
   const refreshClicked = props.refreshClicked;
   const rows = props.rows || [];
   const [openConfirm, setOpenConfirm] = React.useState(false);
   const [userId, _suid] = React.useState(undefined);

   React.useEffect(()=>{
      (async function(){
         _suid((await auth.getIdentity()).id);
      })();
   });

   function isWriteable(){
      if (selectedRows.length !== 1){
         return false;
      }
      const id = selectedRows[0];
      const row = rows.find((elem) => elem.id === id);
      if (row === undefined){
         return false;
      }
      return row.user.id === userId;
   }

   function _delete(){
      setOpenConfirm(true);
   }

   function deleteConfirmed(){
      deleteClicked();
      setOpenConfirm(false);
   }

   return (
      <GridToolbarContainer sx={{display: 'flex'}}>
         <Stack
            direction="row"
            spacing={1}
            divider={<Divider orientation="vertical" flexItem />}
         >
            <Tooltip title="Add new note">
               <IconButton
                  onClick={addClicked}
                  color="primary"
                  size="small"
               >
                  <AddIcon/>
               </IconButton>
            </Tooltip>

            <Tooltip title="Edit selected note">
               <span>
                  <IconButton
                     onClick={editClicked}
                     color="primary"
                     size="small"
                     disabled={! isWriteable()}
                  >
                     <EditIcon/>
                  </IconButton>
               </span>
            </Tooltip>

            <Tooltip title="Refresh Notes">
               <IconButton
                  onClick={refreshClicked}
                  color="primary"
                  size="small"
               >
                  <RefreshIcon/>
               </IconButton>
            </Tooltip>
         </Stack>

         <Box sx={{ flexGrow: 1 }} />

         <Tooltip title="Delete selected note">
            <span>
               <IconButton
                  onClick={_delete}
                  color="primary"
                  size="small"
                  disabled={! isWriteable()}
               >
                  <DeleteIcon />
               </IconButton>
            </span>
         </Tooltip>

         <DeleteConfirmDialog
            title="Delete Note?"
            text="Do you wish to delete the selected note?"
            open={openConfirm}
            onAccept={deleteConfirmed}
            onReject={()=>{setOpenConfirm(false)}}
         />
      </GridToolbarContainer>
   );
}


function NoteEditDialog(props){
   const onAccept = props.onAccept;
   const onReject = props.onReject;
   const show = props.show;
   const _data = props.data;
   const [text, setText] = React.useState(_data.text || '');
   const [dateTime, setDateTime] = React.useState(_data.date || utils.nowMountain());

   function accepted(){
      onAccept({
         date: dateTime,
         text: text
      });
   }

   React.useEffect(()=>{
      // datePicker uses the timezone in value or defaultValue prop
      setDateTime(_data.date || utils.nowMountain());
      setText(_data.text || '');
   }, [_data]);

   return (
      <Dialog open={show} onClose={onReject} fullWidth maxWidth="sm">
         <DialogTitle>Note</DialogTitle>

         <DialogContent sx={{m: 1}}>
            <Stack direction="column" spacing={1}>
               <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                     label="Time (MDT Time)"
                     defaultValue={utils.nowMountain()}
                     value={dateTime}
                     onChange={(d)=>{
                        setDateTime(d);
                     }}
                     disabled={false}
                     renderInput={(params) => {
                        params.variant="standard";
                        return (
                           <TextField
                              {...params}
                           />
                        );
                     }}
                  />
               </LocalizationProvider>

               <TextField
                  label="Note"
                  variant="standard"
                  placeholder="Notes"
                  onChange={(e)=>{ setText(e.target.value); }}
                  value={text}
                  multiline />
            </Stack>
         </DialogContent>

         <DialogActions>
            <Button onClick={onReject}>Cancel</Button>
            <Button onClick={accepted}>Ok</Button>
         </DialogActions>
      </Dialog>
   );
}


export default function NotesTableView(props){
   const [rows, setRows] = React.useState([]);
   const [selectionList, setSelectionList] = React.useState([]);
   const [showEditDialog, setShowEditDialog] = React.useState(false);
   const [editDialogData, setEditDialogData] = React.useState({});
   const [isEditMode, setIsEditMode] = React.useState(false);
   const ref = props.api;

   const api = {
      reload: reloadData
   }

   const columns = [
      {
         field: 'date',
         headerName: 'Date (MDT Time)',
         renderCell: function(params){
            const d = new Date(params.row.date);

            // Locale here will actually be in MDT
            const date = d.toLocaleDateString();
            const time = d.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
            return `${date} ${time}`;
         },
         flex: 0.5
      },
      {
         field: 'text',
         renderCell: renderLongText,
         headerName: 'Note',
         type: 'text',
         sortable: false,
         flex: 1
      },
      {
         field: 'email',
         headerName: 'Creator',
         renderCell: renderLongText,
         valueGetter: function(params){
            return params.row.user.email;
         },
         type: 'text',
         flex: 0.5
      }
   ];

   async function reloadData(){
      const notes = await dataProvider.getList('notes', {
         pagination: {
            perPage: 500,
            page: 1
         },
         sort: {
            field: 'date',
            order: 'desc'
         }
      });

      setRows(notes.data);
   }

   React.useEffect(()=>{
      reloadData();
      if (ref){
         ref.current = api;
      }
   }, [ref]);

   async function addNewNote(data){
      await Datastore.create('notes', {
         data: data
      });
      reloadData();
   }

   async function updateNote(id, data){
      let clone = [...rows];
      const idx = rows.findIndex((elem) => elem.id === id);
      if (idx < 0){
         return;
      }
      clone[idx] = {...clone[idx], ...data};
      setRows(clone);

      // TODO update backend
      await Datastore.update('notes', {
         id: id,
         data: data
      });
      reloadData();

      // Datastore.setGroups(newGroups);
      // setGroups(newGroups);
   }

   function deleteRowClicked(){
      if (selectionList.length < 1){
         return;
      }
      const id = selectionList[0];
      Datastore.deleteOne('notes', {
         id: id
      });

      const newRows = rows.filter((v,idx)=>{
         const id = rows[idx].id;
         return selectionList.indexOf(id) === -1;
      });
      setRows(newRows);
   }

   function editRowClicked(){
      if (selectionList.length < 1){
         return;
      }

      const id = selectionList[0];
      const row = rows.find((elem) => elem.id === id);
      setEditDialogData(row);
      setIsEditMode(true);
      setShowEditDialog(true);
   }

   function addRowClicked(){
      setIsEditMode(false);

      // TODO
      // setEditDialogData({
      // });
      setShowEditDialog(true);
   }

   function acceptEditDialog(data){
      if (isEditMode){
         const id = selectionList[0];
         updateNote(id, data);
      }
      else{
         addNewNote(data);
      }

      setShowEditDialog(false);
   }

   return (
      <>
         <Box sx={{ flexGrow: 1 }} flex={1}>
            <DataGrid
               initialState={{
                  columns: {
                     columnVisibilityModel: {
                     }
                  }
               }}
               density='compact'
               columns={columns}
               rows={rows}
               hideFooter={true}
               components={{
                  Toolbar: NotesTableToolbar,
               }}
               componentsProps={{
                  toolbar: {
                     deleteClicked: deleteRowClicked,
                     addClicked: addRowClicked,
                     editClicked: editRowClicked,
                     refreshClicked: reloadData,
                     selectedRows: selectionList,
                     rows: rows
                  }
               }}
               onSelectionModelChange={(model)=>{
                  setSelectionList(model);
               }}
            />
         </Box>
         <NoteEditDialog
            onAccept={acceptEditDialog}
            onReject={()=>{setShowEditDialog(false);}}
            show={showEditDialog}
            data={editDialogData}
         />
      </>
   );
}
