import * as React from 'react';
import {
   Box,
   Chip,
   Menu,
   Stack,
   Button,
   Dialog,
   Select,
   MenuItem,
   InputLabel,
   FormControl,
   DialogTitle,
   DialogContent,
   DialogActions,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { styled, lighten, darken } from '@mui/system';

import SensorAutoCompleteSelectionInput from '../SensorAutoCompleteSelectionInput';
import TsSensorAutoCompleteSelectionInput from '../TsSensorAutoCompleteSelectionInput';
import * as Datastore from '../Datastore';
import * as plotUtils from './PlotUtils';
import config from '../config/config';





/** Dialog to change plotted sensors and toggle pressure/force
 *
 * @param {module:TSPlot.TSPlotViewState} props.selection active selection
 *
 */
export default function PlotSelectionDialog(props){
   const open = props.open;
   const rejected = props.onReject;
   const accepted = props.onAccept;
   const _extSelection = props.selection;

   const [xyAcOptions, setXyAcOptions] = React.useState([]);
   const [acOptions, setAcOptions] = React.useState([]);
   const [tsSelectionLeft, setTsSelectionLeft] = React.useState([]);
   const [tsSelectionRight, setTsSelectionRight] = React.useState([]);
   const [isLoading, setIsLoading] = React.useState(false);

   // Specifies whether the pressure-transducer sensors show psi or kips
   const [displayModeIndex, setDisplayModeIndex] = React.useState(0);

   // 0: time-series, 1: xy plot
   const [plotModeIndex, setPlotModeIndex] = React.useState(0);

   const [xInputSelection, setxInputSelection] = React.useState('');
   const [yInputSelection, setyInputSelection] = React.useState('');
   const [xySelection, setXySelection] = React.useState([]);

   function _accepted(){
      accepted({
         tsSelectionLeft: tsSelectionLeft,
         tsSelectionRight: tsSelectionRight,
         xySelection: xySelection,
         isTimeSeries: (plotModeIndex === 0),
         displayModeIndex: displayModeIndex
      });
   }

   function _updateLocalSelection(data){
      setPlotModeIndex((data.isTimeSeries === true) ? 0 : 1);
      setDisplayModeIndex(data.displayModeIndex);
      setTsSelectionLeft(data.tsSelectionLeft);
      setTsSelectionRight(data.tsSelectionRight);
   }

   function addXyItemClicked(event){
      if ((xInputSelection === '') || (yInputSelection === '')){
         return;
      }

      const newElem = {
         x: JSON.parse(JSON.stringify(xInputSelection)),
         y: JSON.parse(JSON.stringify(yInputSelection))
      };
      setXySelection([...xySelection,  newElem]);
      setxInputSelection('');
      setyInputSelection('');
   }

   React.useEffect(()=>{
      if (open){
         (async ()=>{
            // Update the displayed selection
            _updateLocalSelection(_extSelection);

            // Fetch available plot options for AC
            setIsLoading(true);
            const userCfg = await Datastore.userConfig();
            setAcOptions(plotUtils.tsPlottableOptionList(userCfg.groups));
            setXyAcOptions(plotUtils.xPlottableOptionList(userCfg.groups));

            setIsLoading(false);
         })();
      }

   }, [_extSelection, open]);


   function configNameChanged(data){
      if (data.data){
         // If the configuration already exists
      }
      else{
         // new configuration that needs to be saved
      }
   }

   return (
      <Dialog open={open} onClose={rejected}>
         <DialogTitle>Plot Options</DialogTitle>
         <DialogContent>

            <Stack spacing={2}>
               <FormControl variant="standard" sx={{ m: 0, minWidth: 500 }}>
                  <InputLabel id="plot-mode-select">Plot Type</InputLabel>
                  <Select labelId="plot-mode-select"
                          value={plotModeIndex}
                          label="Plot Mode"
                          onChange={(event)=>{
                             setPlotModeIndex(event.target.value);
                          }}
                  >
                     <MenuItem key={0} value={0}>
                        Time-history
                     </MenuItem>
                     <MenuItem key={1} value={1}>
                        X-Y plot
                     </MenuItem>
                  </Select>
               </FormControl>


               <Box sx={{display: (plotModeIndex === 1 ? 'none' : 'block')}}>
                  <FormControl variant="standard" sx={{ m: 0, minWidth: 100 }}>
                     <Box>
                        <TsSensorAutoCompleteSelectionInput
                           options={acOptions}
                           disabled={isLoading}
                           selection={tsSelectionLeft}
                           disableSelector={(option) =>{
                              if (tsSelectionLeft.length < 1){
                                 return false;
                              }
                              const selectedUnit = tsSelectionLeft[0].unit;
                              return option.unit !== selectedUnit;
                           }}
                           setSelection={setTsSelectionLeft}
                           label="Sensors and Groups (Left Axis)"
                           placeholder="Sensor/Group"
                        />
                     </Box>
                  </FormControl>
               </Box>

               <Box sx={{display: (plotModeIndex === 1 ? 'none' : 'block')}}>
                  <FormControl variant="standard" sx={{ m: 0, minWidth: 100 }}>
                     <Box>
                        <TsSensorAutoCompleteSelectionInput
                           options={acOptions}
                           disabled={isLoading}
                           selection={tsSelectionRight}
                           disableSelector={(option) =>{
                              if (tsSelectionRight.length < 1){
                                 return false;
                              }
                              const selectedUnit = tsSelectionRight[0].unit;
                              return option.unit !== selectedUnit;
                           }}
                           setSelection={setTsSelectionRight}
                           label="Sensors and Groups (Right Axis)"
                           placeholder="Sensor/Group"
                        />
                     </Box>
                  </FormControl>
               </Box>

               <Box sx={{display: (plotModeIndex === 0 ? 'none' : 'block') }}>
                  <Stack spacing={2}>
                     <Stack direction="row" spacing={2} >
                        <SensorAutoCompleteSelectionInput
                           options={xyAcOptions}
                           disabled={isLoading}
                           selection={xInputSelection}
                           setSelection={setxInputSelection}
                           disableSelector={(option) =>{
                              if (xySelection.length < 1){
                                 return false;
                              }
                              const selectedUnit = xySelection[0].x.unit;
                              return option.unit !== selectedUnit;
                           }}
                           label="X-axis"
                           placeholder="Sensor/Group"/>

                        <SensorAutoCompleteSelectionInput
                           options={xyAcOptions}
                           disabled={isLoading}
                           selection={yInputSelection}
                           disableSelector={(option) =>{
                              if (xySelection.length < 1){
                                 return false;
                              }
                              const selectedUnit = xySelection[0].y.unit;
                              return option.unit !== selectedUnit;
                           }}
                           setSelection={setyInputSelection}
                           label="Y-axis"
                           placeholder="Sensor/Group"/>

                        <Button aria-label="add"
                                size="small"
                                onClick={addXyItemClicked}
                                endIcon={<AddCircleOutlineIcon/>}>
                           Add
                        </Button>
                     </Stack>

                     <Box>
                        {xySelection.map((elem, i)=>{
                           return (
                              <Chip
                                 key={i} value={i}
                                 onClick={()=>{}}
                                 onDelete={(event)=>{
                                    const arr = [...xySelection];
                                    arr.splice(i, 1);
                                    setXySelection(arr);
                                 }}
                                 label={elem.x.key + '/' + elem.y.key}
                              />
                           );
                        })}
                     </Box>
                  </Stack>
               </Box>

               <FormControl variant="standard" sx={{ m: 0, minWidth: 100 }}>
                  <InputLabel id="disp-mode-select">Pressure Transducer Unit</InputLabel>
                  <Select labelId="disp-mode-select"
                          value={displayModeIndex}
                          label="Display"
                          onChange={(event)=>{
                             setDisplayModeIndex(event.target.value);
                          }}
                  >
                     {config.ptDisplayModes
                      .filter(elem=>elem.field !== undefined)
                      .map((elem, i)=>{
                        return (
                           <MenuItem key={i} value={i}>
                              {elem.label}
                           </MenuItem>
                        );
                     })}
                  </Select>
               </FormControl>

            </Stack>
         </DialogContent>

         <DialogActions>
            <Button onClick={rejected}>Cancel</Button>
            <Button onClick={_accepted}>Ok</Button>
         </DialogActions>
     </Dialog>
   );
}
