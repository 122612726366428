/**
 * @module TSPlot
 */


import * as React from 'react';
import {EPlot} from './eplot/EPlot.js';
import './eplot/scss/ep-styles.css';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import RefreshIcon from '@mui/icons-material/Refresh';
import LinearProgress from '@mui/material/LinearProgress';
import { styled, lighten, darken } from '@mui/system';

import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Stack from '@mui/material/Stack';

import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import config from '../config/config';
import * as Datastore from '../Datastore';
import * as utils from '../utils';
import * as plotUtils from './PlotUtils';
import SensorAutoCompleteSelectionInput from '../SensorAutoCompleteSelectionInput';
import TsSensorAutoCompleteSelectionInput from '../TsSensorAutoCompleteSelectionInput';
import DateRangeSelectionWidget from './DateRangeSelectionWidget';
import dataProvider from '../admin/DataProvider';
import dayjs from 'dayjs';





/** Specifies a time-history plot item
 *
 * @typedef {Object} module:TSPlot.TSPlotItem
 * @property {string} type plot item type type ('Jack', 'Rod', 'PT_GROUP')
 * @property {string} key sensor key
 * @property {string} keys if 'PT_GROUP' type, then includes list of sensor keys
 *
 */

/** Specifies a x-y plot item
 *
 * @typedef {Object} module:TSPlot.XYPlotItem
 * @property {module:TSPlot.TSPlotItem} x selected item for x-axis
 * @property {module:TSPlot.TSPlotItem} y selected item for y-axis
 *
 */


/** Contains the current view state of the plan view
 *
 * @typedef {Object} module:TSPlot.TSPlotViewState
 * @property {integer} displayModeIndex specifies active index of the
 *    PTDisplayMode
 * @property {Array<module:TSPlot.TSPlotItem>} tsSelectionLeft selected
 *    items for time-history (Left axis)
 * @property {Array<module:TSPlot.TSPlotItem>} tsSelectionRight selected
 *    items for time-history (Right axis)
 * @property {Array<module:TSPlot.XYPlotItem>} dateRange start and end date of the plot range
 * @property {bool} isTimeSeries specifies whether a time-series is
 *    requested
 *
 */


/**
 *
 * @param {module:TSPlot.TSPlotViewState} props.state active selection
 *
 */
function ViewMenuBar(props){
   const state = props.state;
   const rangeText = props.rangeText;
   const setRangeText = props.setRangeText;

   // callbacks
   const onChange = props.onChange;
   const onRangeChange = props.onRangeChange;
   const zoomOutCb = props.onZoomOut || function(){};
   const onRefresh = props.onRefresh;
   // local state
   const [selectionDialogOpen, setSelectionDialogOpen] = React.useState(false);


   function plotSelectionDialogAccepted(data){
      setSelectionDialogOpen(false);
      onChange({
         ...state,
         ...data
      });
   }

   function plotSelectionDialogRejected(){
      setSelectionDialogOpen(false);
   }

   return (
      <>
         <PlotSelectionDialog
            onAccept={plotSelectionDialogAccepted}
            onReject={plotSelectionDialogRejected}
            selection={state}
            open={selectionDialogOpen}/>

         <Box style={{backgroundColor: '#fff'}}>
            <Box
               sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  border: (theme) => `0px solid ${theme.palette.divider}`,
                  borderRadius: 1,
                  width: '100%',
                  p: 0,
                  bgcolor: 'background.paper',
                  justifyContent: 'space-between',
                  color: 'text.secondary',
               }}
            >
               <Box sx={{display: 'flex' }}>
                  <Tooltip title="Plot selection">
                     <IconButton
                        onClick={()=>{setSelectionDialogOpen(true);}}
                        aria-label="Settings"
                        color="primary">
                        <AutoGraphIcon/>
                     </IconButton>
                  </Tooltip>
               </Box>

               <Box sx={{display: 'flex' }}>
               </Box>

               <Box sx={{display: 'flex' }}>
                  <Tooltip title="Refresh graph">
                     <IconButton aria-label="Refresh" color="primary" onClick={onRefresh}>
                        <RefreshIcon/>
                     </IconButton>
                  </Tooltip>
                  <Divider orientation="vertical" variant="middle" flexItem />
                  <DateRangeSelectionWidget
                     onRangeChange={onRangeChange}
                     rangeText={rangeText}
                     startDate={state.dateRange[0]}
                     endDate={state.dateRange[1]}
                     setRangeText={setRangeText}
                  />
                  <Divider orientation="vertical" variant="middle" flexItem />
                  <Tooltip title="Zoom out">
                     <IconButton aria-label="Zoom out" color="primary" onClick={zoomOutCb}>
                        <ZoomOutIcon/>
                     </IconButton>
                  </Tooltip>
                  <Box sx={{pl: 1}}>
                  </Box>
               </Box>
            </Box>
         </Box>
      </>
   );
}


/** Dialog to change plotted sensors and toggle pressure/force
 *
 * @param {module:TSPlot.TSPlotViewState} props.selection active selection
 *
 */
function PlotSelectionDialog(props){
   const open = props.open;
   const rejected = props.onReject;
   const accepted = props.onAccept;
   const _extSelection = props.selection;

   const [xyAcOptions, setXyAcOptions] = React.useState([]);
   const [acOptions, setAcOptions] = React.useState([]);
   const [tsSelectionLeft, setTsSelectionLeft] = React.useState([]);
   const [tsSelectionRight, setTsSelectionRight] = React.useState([]);
   const [isLoading, setIsLoading] = React.useState(false);

   // Specifies whether the pressure-transducer sensors show psi or kips
   const [displayModeIndex, setDisplayModeIndex] = React.useState(0);

   // 0: time-series, 1: xy plot
   const [plotModeIndex, setPlotModeIndex] = React.useState(0);

   const [xInputSelection, setxInputSelection] = React.useState('');
   const [yInputSelection, setyInputSelection] = React.useState('');
   const [xySelection, setXySelection] = React.useState([]);

   function _accepted(){
      accepted({
         tsSelectionLeft: tsSelectionLeft,
         tsSelectionRight: tsSelectionRight,
         xySelection: xySelection,
         isTimeSeries: (plotModeIndex === 0),
         displayModeIndex: displayModeIndex
      });
   }

   function _updateLocalSelection(data){
      setPlotModeIndex((data.isTimeSeries === true) ? 0 : 1);
      setDisplayModeIndex(data.displayModeIndex);
      setTsSelectionLeft(data.tsSelectionLeft);
      setTsSelectionRight(data.tsSelectionRight);
   }

   function addXyItemClicked(event){
      if ((xInputSelection === '') || (yInputSelection === '')){
         return;
      }

      const newElem = {
         x: JSON.parse(JSON.stringify(xInputSelection)),
         y: JSON.parse(JSON.stringify(yInputSelection))
      };
      setXySelection([...xySelection,  newElem]);
      setxInputSelection('');
      setyInputSelection('');
   }

   React.useEffect(()=>{
      if (open){
         (async ()=>{
            // Update the displayed selection
            _updateLocalSelection(_extSelection);

            // Fetch available plot options for AC
            setIsLoading(true);
            const userCfg = await Datastore.userConfig();
            setAcOptions(plotUtils.tsPlottableOptionList(userCfg.groups));
            setXyAcOptions(plotUtils.xPlottableOptionList(userCfg.groups));

            setIsLoading(false);
         })();
      }

   }, [_extSelection, open]);

   return (
      <Dialog open={open} onClose={rejected}>
         <DialogTitle>Plot Options</DialogTitle>
         <DialogContent>

            <Stack spacing={2}>

               <FormControl variant="standard" sx={{ m: 0, minWidth: 500 }}>
                  <InputLabel id="plot-mode-select">Plot Type</InputLabel>
                  <Select labelId="plot-mode-select"
                          value={plotModeIndex}
                          label="Plot Mode"
                          onChange={(event)=>{
                             setPlotModeIndex(event.target.value);
                          }}
                  >
                     <MenuItem key={0} value={0}>
                        Time-history
                     </MenuItem>
                     <MenuItem key={1} value={1}>
                        X-Y plot
                     </MenuItem>
                  </Select>
               </FormControl>


               <Box sx={{display: (plotModeIndex === 1 ? 'none' : 'block')}}>
                  <FormControl variant="standard" sx={{ m: 0, minWidth: 100 }}>
                     <Box>
                        <TsSensorAutoCompleteSelectionInput
                           options={acOptions}
                           disabled={isLoading}
                           selection={tsSelectionLeft}
                           disableSelector={(option) =>{
                              if (tsSelectionLeft.length < 1){
                                 return false;
                              }
                              const selectedUnit = tsSelectionLeft[0].unit;
                              return option.unit !== selectedUnit;
                           }}
                           setSelection={setTsSelectionLeft}
                           label="Sensors and Groups (Left Axis)"
                           placeholder="Sensor/Group"
                        />
                     </Box>
                  </FormControl>
               </Box>

               <Box sx={{display: (plotModeIndex === 1 ? 'none' : 'block')}}>
                  <FormControl variant="standard" sx={{ m: 0, minWidth: 100 }}>
                     <Box>
                        <TsSensorAutoCompleteSelectionInput
                           options={acOptions}
                           disabled={isLoading}
                           selection={tsSelectionRight}
                           disableSelector={(option) =>{
                              if (tsSelectionRight.length < 1){
                                 return false;
                              }
                              const selectedUnit = tsSelectionRight[0].unit;
                              return option.unit !== selectedUnit;
                           }}
                           setSelection={setTsSelectionRight}
                           label="Sensors and Groups (Right Axis)"
                           placeholder="Sensor/Group"
                        />
                     </Box>
                  </FormControl>
               </Box>

               <Box sx={{display: (plotModeIndex === 0 ? 'none' : 'block') }}>
                  <Stack spacing={2}>
                     <Stack direction="row" spacing={2} >
                        <SensorAutoCompleteSelectionInput
                           options={xyAcOptions}
                           disabled={isLoading}
                           selection={xInputSelection}
                           setSelection={setxInputSelection}
                           disableSelector={(option) =>{
                              if (xySelection.length < 1){
                                 return false;
                              }
                              const selectedUnit = xySelection[0].x.unit;
                              return option.unit !== selectedUnit;
                           }}
                           label="X-axis"
                           placeholder="Sensor/Group"/>

                        <SensorAutoCompleteSelectionInput
                           options={xyAcOptions}
                           disabled={isLoading}
                           selection={yInputSelection}
                           disableSelector={(option) =>{
                              if (xySelection.length < 1){
                                 return false;
                              }
                              const selectedUnit = xySelection[0].y.unit;
                              return option.unit !== selectedUnit;
                           }}
                           setSelection={setyInputSelection}
                           label="Y-axis"
                           placeholder="Sensor/Group"/>

                        <Button aria-label="add"
                                size="small"
                                onClick={addXyItemClicked}
                                endIcon={<AddCircleOutlineIcon/>}>
                           Add
                        </Button>
                     </Stack>

                     <Box>
                        {xySelection.map((elem, i)=>{
                           return (
                              <Chip
                                 key={i} value={i}
                                 onClick={()=>{}}
                                 onDelete={(event)=>{
                                    const arr = [...xySelection];
                                    arr.splice(i, 1);
                                    setXySelection(arr);
                                 }}
                                 label={elem.x.key + '/' + elem.y.key}
                              />
                           );
                        })}
                     </Box>
                  </Stack>
               </Box>

               <FormControl variant="standard" sx={{ m: 0, minWidth: 100 }}>
                  <InputLabel id="disp-mode-select">Pressure Transducer Unit</InputLabel>
                  <Select labelId="disp-mode-select"
                          value={displayModeIndex}
                          label="Display"
                          onChange={(event)=>{
                             setDisplayModeIndex(event.target.value);
                          }}
                  >
                     {config.ptDisplayModes
                      .filter(elem=>elem.field !== undefined)
                      .map((elem, i)=>{
                        return (
                           <MenuItem key={i} value={i}>
                              {elem.label}
                           </MenuItem>
                        );
                     })}
                  </Select>
               </FormControl>

            </Stack>
         </DialogContent>

         <DialogActions>
            <Button onClick={rejected}>Cancel</Button>
            <Button onClick={_accepted}>Ok</Button>
         </DialogActions>
      </Dialog>
   );
}


function axisLabel(unitStr, ptModeIndex){
   if (unitStr === 'pt'){
      return config.ptDisplayModes[ptModeIndex].label;
   }

   return unitStr
}


async function fetchPlotState(){
   const userCfg = await Datastore.userConfig();
   const plotCfg = userCfg.plot;
   if (!plotCfg){
      return plotCfg;
   }

   const dRange = plotCfg.dateRange;
   if ('dateRange' in plotCfg){
      const t0 = new Date(dRange[0]);
      const t1 = new Date(dRange[1]);
      plotCfg.dateRange = [t0, t1];
   }
   return plotCfg;
}


function savePlotState(state){
   return Datastore.saveUserData('plot', state);
}


function TSPlot(){
   const [rangeText, setRangeText] = React.useState('1 hour');
   const [progVisibility, setProgVisibility] = React.useState('hidden');
   const plt = React.useRef(undefined);
   const cfgLoaded = React.useRef(false);

   const state = React.useRef({
      displayModeIndex: 0,
      isTimeSeries: true,
      tsSelectionLeft: [
         {
            key: 'W1J',
            type: 'Jack',
            unit: 'pt'
         }
      ],
      tsSelectionRight: [
      ],
      dateRange: [
         utils.dateAddSeconds(utils.nowMountain(), -3600),
         utils.nowMountain()
      ]
   });

   function plotLegendColumnCount(N){
      return Math.min(
         Math.max(3, Math.ceil(N/3)), 9);
   }

   async function replot(newState){
      // Individual sensor keys
      setProgVisibility('visible');
      const dRange = newState.dateRange;
      const {
         tsSelectionLeft,
         tsSelectionRight,
         isTimeSeries,
         xySelection,
         displayModeIndex} = newState;
      const [t0, t1] = newState.dateRange;

      let pdata = [];
      if (isTimeSeries){
         const elem0 = tsSelectionLeft[0] || {};
         plt.current.leftAxis.label(axisLabel(elem0.unit, displayModeIndex));
         pdata = await plotUtils.fetchTsPlotData(
            tsSelectionLeft,
            displayModeIndex,
            dRange[0], dRange[1],
            'left');

         const rightData = await plotUtils.fetchTsPlotData(
            tsSelectionRight,
            displayModeIndex,
            dRange[0], dRange[1],
            'right'
         );
         const elem0Right = tsSelectionRight[0] || {};
         plt.current.rightAxis.label(axisLabel(elem0Right.unit, displayModeIndex));
         plt.current.legendRight.setMaxColumns(
            plotLegendColumnCount(tsSelectionRight.length));
         plt.current.legendLeft.setMaxColumns(
            plotLegendColumnCount(tsSelectionLeft.length));
         pdata = pdata.concat(rightData);
      }
      else{
         const elem0 = tsSelectionLeft[0] || {};
         plt.current.leftAxis.label(axisLabel(elem0.unit, displayModeIndex));
         pdata = await plotUtils.fetchXyPlotData(xySelection, displayModeIndex, t0, t1);
      }

      // 'Disable' existing plots that may have been hidden
      const existingData = plt.current.pdata || [];
      for (let i=0; i<existingData.length; ++i){
         const k0 = existingData[i].label;
         for (let j=0; j<pdata.length; ++j){
            if (k0 === pdata[j].label){
               pdata[j].enabled = existingData[i].enabled;
            }
         }
      }

      setProgVisibility('hidden');
      plt.current.plot(pdata);

      if (isTimeSeries){
         plt.current.setAxesRange([t0, t1], 'auto', 'auto');
         plt.current.tooltip.formatDelegate = plotUtils.tsFormat;
      }
      else{
         plt.current.setAxesRange('auto', 'auto', 'auto');
         plt.current.tooltip.formatDelegate = plotUtils.xyFormat;
      }
   }

   function rangeChangeRequested(start, end){
      const t0_str = dayjs(start).format('l LT');
      let t1_str = '';
      if (end === undefined){
         end = utils.nowMountain();
         t1_str = 'now';
      }
      else{
         t1_str = dayjs(end).format('l LT');
      }

      const newState = {
         ...state.current,
         dateRange: [start, end]
      };
      replot(newState);
      setRangeText(`${t0_str}|${t1_str}`);
      state.current = newState;
      savePlotState(newState);
   }

   function plotZoomedIn(range){
      if (state.current.isTimeSeries){
         const t0 = range[0];
         const t1 = range[1];
         return rangeChangeRequested(t0, t1);
      }

      plt.current.setAxesRange(range, 'auto', 'auto');
   }

   async function refreshRequested(){
      const dRange = state.current.dateRange;
      rangeChangeRequested(dRange[0], dRange[1] || utils.nowMountain());
   }

   async function zoomOutRequested(){
      const dRange = state.current.dateRange;
      const t0 = dRange[0];
      const t1 = dRange[1] || utils.nowMountain();

      const dt_s = (t1.getTime() - t0.getTime())/1000;
      const offsetS = 0.5 * dt_s;

      const t0new = utils.dateAddSeconds(t0, -offsetS);
      const t1new = utils.dateAddSeconds(t1, offsetS);
      rangeChangeRequested(t0new, t1new);
   }

   function optionsChanged(newState){
      state.current = newState;
      replot(newState);
      savePlotState(newState);
   }

   React.useEffect(()=>{
      if (!plt.current){
         const plot = new EPlot('#pcontainer0', {width: 1000, height: 300});
         plt.current = plot;
         const observer = new ResizeObserver(entries => {
            entries.forEach(entry => {
               const w = Math.max(100, entry.contentRect.width-20);
               const h = Math.max(200, entry.contentRect.height-10);
               plt.current.resize(w, h);
            });
         });
         observer.observe(document.getElementById('pcontainer0'));
      }

      // Must resubscribe for the state to be updated
      plt.current.rubberband.unsubscribe('rangeSelected', plotZoomedIn);
      plt.current.rubberband.subscribe('rangeSelected', plotZoomedIn);
      if (!cfgLoaded.current){
         let dRange = state.current.dateRange;
         cfgLoaded.current = true;
         (async function(){
            let plotState = await fetchPlotState();
            // Some users may initially not have plot state defined
            if (plotState){
               state.current = {
                  ...state.current,
                  ...plotState
               };
            }
            else{
               plotState = state.current;
            }
            dRange = plotState['dateRange']
            rangeChangeRequested(dRange[0], dRange[1]);
         })();
      }
   }, [plt]);

   function startDate(){
      return state.current.dateRange[0];
   }

   function endDate(){
      return state.current.dateRange[1];
   }

   return (
      <>
         <LinearProgress sx={{pt: 0.2, width: '100%', visibility: progVisibility }}/>
         <ViewMenuBar
            rangeText={rangeText}
            state={state.current}
            setRangeText={setRangeText}
            onRangeChange={rangeChangeRequested}
            onChange={optionsChanged}
            startDate={startDate()}
            endDate={endDate()}
            onRefresh={refreshRequested}
            onZoomOut={zoomOutRequested}/>
         <div id="pcontainer0" style={{
                 backgroundColor: '#fff',
                 height: '100%',
                 width: '100%',
                 display: 'block',
                 position: 'relative'
              }}>
         </div>
      </>
   );
}


export default TSPlot;
