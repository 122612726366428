import * as React from 'react';
import {Button} from '@mui/material';
import {
   List,
   Datagrid,
   TextField,
   NumberField,
   DateField,
   BooleanField,
   useRecordContext,
   fetchUtils,
   useNotify,
} from 'react-admin';

import config from '../../config/config';
import {httpClient} from '../DataProvider';



function RunNowButton(){
   const record = useRecordContext();
   const notify = useNotify();
   const [loading, setLoading] = React.useState(false);

   async function runNow(event){
      event.stopPropagation();
      setLoading(true);
      const apiUrl = config.apiEndpoint;
      const url = `${apiUrl}/notifiers/run/${record.id}`;
      try{
         const resp = await httpClient(url, {
            method: 'POST'
         });
         setLoading(false);
         if (resp.status<300 && resp.status>=200){
            return notify('Running', {type: 'success'});
         }
         return notify(resp.json.detail || 'Something went wrong', {type: 'error'});
      }
      catch (exc){
         setLoading(false);
         return notify(`Run failed: ${exc.message}`, {type: 'error'});
      }
   }

   return (
      <Button
         variant="outlined"
         size="small"
         disabled={loading}
         onClick={runNow}>Run Now</Button>
   );
}


export const NotifierList = () => (
    <List exporter={false}>
       <Datagrid rowClick="edit">
            <TextField source="name"/>
            <TextField source="fn" label="Function name"/>
            <TextField source="observers"/>
            <TextField
               format={ val => {
                  if (typeof(val) === 'object'){
                     return JSON.stringify(val);
                  }
                  return '' + val;
               }}
               label="Context"
               source="context"
            />
            <TextField source="trigger"/>
            <NumberField source="interval" label="Interval (minutes)"/>
            <BooleanField source="enabled" />
            <DateField showTime={true} source="createdAt" label="Created"/>
            <DateField showTime={true} source="lastRun"/>
            <RunNowButton/>
       </Datagrid>
    </List>
);
