import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { darken, lighten, styled } from '@mui/material/styles';
import config from './config/config';



const getBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);


const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .super-app-theme--': {
    backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode,
        ),
      },
    },
  },
  '& .super-app-theme--success': {
    backgroundColor: getBackgroundColor(
      theme.palette.success.main,
      theme.palette.mode,
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.success.main,
          theme.palette.mode,
        ),
      },
    },
  },
  '& .super-app-theme--error': {
    backgroundColor: getBackgroundColor(
      theme.palette.error.main,
      theme.palette.mode,
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode,
        ),
      },
    },
  },
}));


export default function AlertsView(){
   const [rowCount, setRowCount] = React.useState(0);
   const [rows, setRows] = React.useState([]);
   const [paginationModel, setPaginationModel] = React.useState({
      page: 0,
      pageSize: 10,
   });
   const [filterModel, setFilterModel] = React.useState({ items: [] });
   const [sortModel, setSortModel] = React.useState([]);

   React.useEffect(() => {
      const fetcher = async () => {
         const sortQuery = `sort=["createdAt", "desc"]`;
         const rangeQuery = `range=[${paginationModel.page}, ${paginationModel.pageSize}]`;
         const url = `${config.apiEndpoint}/alerts?${rangeQuery}&${sortQuery}`;
         const resp = await fetch(url, {
            method: 'GET'
         });
         const data = await resp.json();
         const crange = resp.headers.get('content-range');
         setRowCount(crange.split('/')[1]);
         setRows(data);
      };
      fetcher();
   }, [paginationModel, sortModel, filterModel]);


   const columns = [
      {
         field: 'createdAt',
         valueGetter: (value) =>{
            if (!value){
               return '-';
            }
            const row = value.row;
            return (new Date(row.createdAt)).toLocaleString();
         },
         headerName: 'Alert Time',
         flex: 1
      },
      {
         field: 'data',
         valueGetter: (value) => {
            if (!value) {
               return '-';
            }
            const row = value.row
            if (!row || !row.data){
               return '-';
            }
            return JSON.stringify(row.data);
         },
         headerName: 'Info',
         flex: 1
      }
   ];

   return (
      <Box sx={{ height: '100vh', width: '100%' }}>
         <StyledDataGrid
            columns={columns}
            rows={rows}
            pagination
            rowCountState={rowCount}
            sortingMode="server"
            filterMode="server"
            paginationMode="server"
            onPaginationModelChange={(newPaginationModel) => {
               // fetch data from server
            }}
            onSortModelChange={(newSortModel) => {
               // fetch data from server
            }}
            onFilterModelChange={(newFilterModel) => {
               // fetch data from server
            }}
            getRowClassName={(params) => {
               const status = params.row.type;
               return `super-app-theme--${status}`
            }}
         />
      </Box>
   );
};
