import {NotifierList} from './NotifierList';
import {NotifierCreate} from './NotifierCreate';
import {NotifierEdit} from './NotifierEdit';



export default {
   list: NotifierList,
   create: NotifierCreate,
   edit: NotifierEdit
};
