import * as React from 'react';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import {signup} from './auth/AuthProvider';



function validateEmail(email){
   return String(email)
      .toLowerCase()
      .match(
         /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
};


export default function SignupDialog(props){
   const isOpen = props.open;
   const onClose = props.onClose;
   const [loading, setLoading] = React.useState(false);
   const [msgSplash, setMsgSplash] = React.useState({
      type: 'warning',
      text: ''
   });

   async function submitForm(event){
      event.preventDefault();

      const formData = new FormData(event.currentTarget);
      const email = formData.get('email');
      const password1 = formData.get('password');
      const password2 = formData.get('passwordConfirm');
      const firstName = formData.get('firstname');
      const lastName = formData.get('lastname');

      if (! validateEmail(email)){
         return setMsgSplash({
            type: 'error',
            text: 'Invalid email format'
         });
      }

      if (password1 !== password2){
         return setMsgSplash({
            type: 'error',
            text: 'Passwords do not match'
         });
      }

      setLoading(true);
      const stat = await signup(email, firstName, lastName, password1)
            .catch((err)=>{
               const msg = err.response.data.message || 'Something went wrong.';
               return setMsgSplash({
                  type: 'error',
                  text: msg
               });
            });
      setLoading(false);

      setMsgSplash({
         type: 'success',
         text: stat.data.message
      });

      if (onClose){
         onClose();
      }
   }


   return (
      <>
         <Snackbar
            open={msgSplash.text !== ''}
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            onClose={()=> setMsgSplash({...msgSplash, text: ''})}
            autoHideDuration={5000}
         >
            <Alert severity={msgSplash.type} sx={{ width: '100%' }}>
               {msgSplash.text}
            </Alert>
         </Snackbar>
         <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>Sign up</DialogTitle>

            <DialogContent>

               <Box component="form" onSubmit={submitForm} noValidate sx={{mt: 1}}>
                  <TextField
                     margin="normal"
                     required
                     fullWidth
                     id="email"
                     label="Email Address"
                     name="email"
                     autoComplete="email"
                     autoFocus
                  />

                  <TextField
                     margin="normal"
                     required
                     fullWidth
                     name="firstname"
                     label="First Name"
                     type="text"
                     id="firstname"
                  />

                  <TextField
                     margin="normal"
                     required
                     fullWidth
                     name="lastname"
                     label="Last Name"
                     type="text"
                     id="lastname"
                  />

                  <TextField
                     margin="normal"
                     required
                     fullWidth
                     name="password"
                     label="Password"
                     type="password"
                     id="password"
                  />
                  <TextField
                     margin="normal"
                     required
                     fullWidth
                     name="passwordConfirm"
                     label="Confirm Password"
                     type="password"
                     id="passwordConfirm"
                  />

                  <Button
                     type="submit"
                     fullWidth
                     variant="contained"
                     disabled={loading}
                     sx={{mt: 3, mb: 2}}
                  >
                     Sign up
                  </Button>
               </Box>
            </DialogContent>
         </Dialog>
      </>
   );
}
