/**
 * @module PlanView
 */

import * as d3 from 'd3';
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

import config from './config/config';
import PlanViewSvgPT from './PlanViewSvgPT';
import PlanViewSvgTilt from './PlanViewSvgTilt';




/** Menu bar overlayed on top of sensor plan view
 *
 * @param {object} props
 * @param {PlanViewState} props.selection current active plan view state
 * @param {planViewStateChanged} props.onChange callback called with when
 *    state is changed. Called is responsible for honoring the state change
 */
function PlanViewMenuBar(props){
   const selection = props.selection;
   const onChange = props.onChange;

   function notifyChange(view, mode){
      if (!onChange){
         return;
      }

      onChange({
         viewIndex: view,
         displayModeIndex: mode
      })
   }

   return (
      <Box
         sx={{
            position: 'absolute',
            right: 0,
            marginLeft: 'auto',
            marginRight: '0',
            display: 'flex',
            alignItems: 'right',
            width: 'auto',
            border: (theme) => `0px solid ${theme.palette.divider}`,
            borderRadius: 1,
            p: 1,
            bgcolor: 'background.paper',
            color: 'text.secondary',
         }}
      >
         <Stack direction="row" spacing={1} divider={<Divider orientation="vertical" flexItem />}>
            <Box>
               <Typography variant="caption">
                  <FormControl variant="standard" sx={{ m: 0, minWidth: 100 }}>
                     <InputLabel id="display-mode-select-label">Display</InputLabel>
                     <Select sx={{fontSize:"1em"}}
                             labelId="select-mode-select-label"
                             id="display-mode-select"
                             value={selection.displayModeIndex}
                             label="Display"
                             onChange={(event)=>{
                                notifyChange(selection.viewIndex, event.target.value);
                             }}
                             disableUnderline
                     >
                        {config.views[selection.viewIndex].modes.map((elem, i)=>{
                           return (
                              <MenuItem key={i} value={i}>
                                 <Typography variant="caption">
                                    {elem.label}
                                 </Typography>
                              </MenuItem>
                           );
                        })}
                     </Select>
                  </FormControl>
               </Typography>
            </Box>
            <Box>
               <Typography variant="caption">
                  <FormControl variant="standard" sx={{ m: 0, minWidth: 100 }}>
                     <InputLabel id="view-select">View</InputLabel>
                     <Select sx={{fontSize:"1em"}}
                             labelId="view-select"
                             id="demo-simple-select-standard"
                             value={selection.viewIndex}
                             label="View"
                             onChange={(event)=>{
                                const newIndex = event.target.value;
                                const availOpts = config.views[newIndex].modes.length
                                const dispModeIndex =
                                      selection.displayModeIndex < availOpts ? selection.displayModeIndex
                                      : 0;
                                notifyChange(newIndex, dispModeIndex);
                             }}
                             disableUnderline
                     >
                        {config.views.map((elem, i)=>{
                           return (
                              <MenuItem key={i} value={i}>
                                 <Typography variant="caption">
                                    {elem.label}
                                 </Typography>
                              </MenuItem>
                           );
                        })}
                     </Select>
                  </FormControl>
               </Typography>
            </Box>
         </Stack>
      </Box>
   );
}


function PlanViewSvg(props){
   const sensorData = props.data;
   const viewOpts = props.view;
   const state = props.opts;
   const highlightedKeys = props.highlightedKeys;

   if (state.viewIndex < 3){
      return <PlanViewSvgPT
                data={sensorData}
                view={viewOpts}
                opts={state}
                highlightedKeys={highlightedKeys}
             />
   }
   else if (state.viewIndex > 2){
      return <PlanViewSvgTilt
                data={sensorData}
                view={viewOpts}
                opts={state}
                highlightedKeys={highlightedKeys}
             />
   }

}



/** Contains the current view state of the plan view
 *
 * @typedef {Object} PlanViewState
 * @property {integer} displayModeIndex specifies active index of the
 *    PTDisplayMode
 * @property {integer} viewIndex specifies the active view index in
 *    PTView
 */


/**
 * @param {object} props
 *
 * @param {Array<string>} props.highlightedKeys list of sensors
 *     keys to highlight
 */
function PlanView(props){
   const sensorData = props.sensorData;
   const highlightedKeys = props.highlightedKeys;

   const [state, setState] = React.useState({
      displayModeIndex: 0,
      viewIndex: 2
   });

   const [viewOpts, setViewOpts] = React.useState(undefined);

   /** Handles updating view state
    *
    * @function planViewStateChanged
    *
    * @param {PlanViewState} data new state
    * @return undefined
    */
   async function selectionChanged(data){
      setState({
         ...state,
         displayModeIndex: data.displayModeIndex,
         viewIndex: data.viewIndex,
      });
      setViewOpts(config.views[data.viewIndex]);
   }

   React.useEffect(()=>{
      setViewOpts(config.views[state.viewIndex]);
   }, [state]);

   return (
      <Box sx={{height: '100%'}}>
         <PlanViewMenuBar
            onChange={selectionChanged}
            selection={state}

            viewsList={config.views}
            displayModes={config.displayModes}
         />
         <PlanViewSvg
            data={sensorData}
            view={viewOpts}
            opts={state}
            highlightedKeys={highlightedKeys}
         />
      </Box>
   );
}


export default PlanView;
