import {
   TextInput,
} from 'react-admin';


export default function JsonInput(props){
   return (
      <TextInput
         {...props}
         format={ val => {
            if (typeof(val) === 'object'){
               return JSON.stringify(val);
            }
            return '' + val;
         }}
      />
   );
}
