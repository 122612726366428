/**
 * @module SensorPopover
 */


import * as React from 'react';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';



/** Display sensor information on mouse over
 *
 * @param {bool} props.show if true, displays the popover
 * @param {Dom.Element} props.anchor element to anchor the popover
 *
 * @returns {React.ReactElement} popover menu
 */
function SensorPopover(props){
   const children = props.children;
   const show = props.show;
   const anchor = props.anchor;
   const [popOverOpen, setPopOverOpen] = React.useState(false);

   function popoverEnter(){
      setPopOverOpen(true);
   }

   function popoverLeave(){
      setPopOverOpen(false);
   }

   function handlePopoverClose(){ }

   return (
      <Popover
         id="mouse-over-popover"
         sx={{
            pointerEvents: 'none',
         }}
         open={show || popOverOpen}
         anchorEl={anchor}
         anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
         }}
         transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
         }}
         onClose={handlePopoverClose}
         PaperProps={{
            sx: {
               pointerEvents: 'auto'
            },
            onMouseEnter: popoverEnter,
            onMouseLeave: popoverLeave
         }}
      >
         <Box>
            {children}
         </Box>
      </Popover>);
}


export default SensorPopover;
