import * as React from 'react';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import CircularProgress from "@mui/material/CircularProgress";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import {useNavigate, useLocation} from 'react-router-dom';
import Card from '@mui/material/Card';
import SignupDialog from './SignupDialog';
import {useAuth} from './hooks/useAuth';




export default function LoginPage(){

   const [loading, setLoading] = React.useState(false);
   const [errorSplash, setErrorSplash] = React.useState('');
   const auth = useAuth();
   const navigate = useNavigate();
   const location = useLocation();
   const [signUpOpen, setSignupOpen] = React.useState(false);

   // Run input function with loading spinner set
   async function withLoading(fn){
      setLoading(true);
      const ret = await fn();
      setLoading(false);
      return ret;
   }

   function signUpClicked(){
      setSignupOpen(true);
   }

   async function submitForm(event){
      event.preventDefault();
      const formData = new FormData(event.currentTarget);
      const email = formData.get('email');
      const password = formData.get('password');

      const success = await withLoading(()=>{
         return auth.login(email, password);
      });

      if (success){
         navigate(({} || location.state).next || '/');
      }

      setErrorSplash('Invalid email or password');
   }

   return (
      <Container component="main" maxWidth="xs">
         <Box
            sx={{
               marginTop: '50%',
               display: 'flex',
               flexDirection: 'column',
               alignItems: 'center',
            }}
         >
            <Card sx={{p: 6}}>
            <Typography component="h1" variant="h5">
               Sign in
            </Typography>
            <Box component="form" onSubmit={submitForm} noValidate sx={{mt: 1}}>
               <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
               />
               <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
               />

               <Snackbar
                  open={errorSplash !== ''}
                  anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                  onClose={()=> setErrorSplash('')}
                  autoHideDuration={5000}
               >
                  <Alert severity="error" sx={{ width: '100%' }}>
                     {errorSplash}
                  </Alert>
               </Snackbar>


               <Box sx={{m: 1, position: 'relative'}}>
                  <Button
                     type="submit"
                     fullWidth
                     variant="contained"
                     disabled={loading}
                     sx={{mt: 3, mb: 2}}
                  >
                     Sign In
                  </Button>
                  {loading && (
                     <CircularProgress
                        size={24}
                        sx={{
                           position: 'absolute',
                           top: '50%',
                           left: '50%',
                           marginTop: '-12px',
                           marginLeft: '-12px',
                        }}
                     />
                  ) }
               </Box>

               <Grid container>
                  <Grid item xs>
                  </Grid>
                  <Grid item>
                     <Button variant="text" onClick={signUpClicked}> Sign up </Button>
                  </Grid>
               </Grid>
            </Box>

            </Card>
         </Box>
         <SignupDialog
            open={signUpOpen}
            onClose={()=>{ setSignupOpen(false); }}
         />

      </Container>
   );
}
