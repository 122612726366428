import * as React from 'react';
import {
   Menu,
   Button,
   MenuItem,
   IconButton,
   ListItemIcon,
   SwipeableDrawer,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DownloadIcon from '@mui/icons-material/Download';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import NotesTableView from './NotesTableView';
import UserPlotsDialog from './plot/UserPlotsDialog';
import DataDownloadDialog from './DataDownloadDialog';



export default function DashboardMenuButton(){
   const [anchorEl, setAnchorEl] = React.useState(null);
   const [notesOpen, setNotesOpen] = React.useState(false);
   const [openUserPlotsDialog, setOpenUserPlotsDialog] = React.useState(false);
   const [openDataDownloadDialog, setOpenDataDownloadDialog] = React.useState(false);
   const open = Boolean(anchorEl);
   const ref = React.useRef();


   function closeMenu(){
      setAnchorEl(null);
   }

   function openMenu(event){
      setAnchorEl(event.currentTarget);
   }

   function _callback(fn){
      closeMenu();
      if (fn){
         fn();
      }
   }

   function toggleNotesDrawer(event, state){
      if (event &&
          event.type === 'keydown' &&
          (event.key === 'Tab' || event.key === 'Shift')
         ){
         return;
      }
      setNotesOpen(state);
      if (state === true){
         ref.current.reload();
      }
   }

   function closeDataDownloadDialog(){
      setOpenDataDownloadDialog(false);
   }

   return (
      <>
         <Button
            size="small"
            startIcon={<MenuIcon/>}
            onClick={openMenu}
         > More </Button>
         <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={closeMenu}
            onClick={closeMenu}
         >
            <MenuItem
               onClick={(event)=>{
                  toggleNotesDrawer(event, true);
                  closeMenu();
               }}>
               <ListItemIcon>
                  <SpeakerNotesIcon/>
               </ListItemIcon>
               Notes
            </MenuItem>
            <MenuItem onClick={()=>{setOpenUserPlotsDialog(true);}}>
               <ListItemIcon>
                  <DashboardCustomizeIcon/>
               </ListItemIcon>
               Plot dashboard
            </MenuItem>
            <MenuItem onClick={()=>{setOpenDataDownloadDialog(true);}}>
               <ListItemIcon>
                  <DownloadIcon/>
               </ListItemIcon>
               Download data
            </MenuItem>
         </Menu>


         <SwipeableDrawer
            anchor='right'
            open={notesOpen}
            PaperProps={{
               sx: { width: '40%' },
            }}
            onClose={(event)=>{ toggleNotesDrawer(event, false) }}
            onOpen={(event)=>{ toggleNotesDrawer(event, true) }}
         >
            <NotesTableView api={ref}/>
         </SwipeableDrawer>

         <UserPlotsDialog
            open={openUserPlotsDialog}
            setOpen={setOpenUserPlotsDialog} />
         <DataDownloadDialog
            open={openDataDownloadDialog}
            onClose={closeDataDownloadDialog} />
      </>
   );
}
