import * as React from 'react';
import authProvider from '../auth/AuthProvider';


const AuthContext = React.createContext();


export function AuthContextProvider({children, userData=null}){
   const [user, setUser] = React.useState(userData);

   async function login(username, password){
      const userData = await authProvider.login({username, password}).catch(()=>{});
      setUser(userData);

      if (userData){
         return true;
      }
      return false;
   }

   function logout(){
      authProvider.logout();
      setUser(null);
   }

   async function changePassword(oldPassword, newPassword){
      return await authProvider.changePassword(oldPassword, newPassword);
   }

   async function isValid(){
      try {
         if (await authProvider.checkAuth()){
            return true;
         }
      }
      catch (err){
         return false;
      }
      return false;
   }

   const value = React.useMemo(()=>{
      return ({user, login, logout, isValid, changePassword});
   }, [user]);

   return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}


export function useAuth(){
   return React.useContext(AuthContext);
}
