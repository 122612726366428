import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';

import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';



function DateTimeSelectionDialogButton(props){
   const onAccept = props.onAccept;
   const [isOpen, setIsOpen] = React.useState(false);
   const displayDate = props.displayDate;
   const [dateTime, setDateTime] = React.useState(new Date());

   function accepted(){
      setIsOpen(false);
      if (!onAccept){
         return;
      }
      onAccept(dateTime);
   }

   function rejected(){
      setIsOpen(false);
   }

   function openDialog(){
      setIsOpen(true);
   }

   function displayTimeStr(){
      let x = displayDate;
      if (displayDate.toDate){
         x = displayDate.toDate()
      }

      if (! x || isNaN(x)){
         return '-';
      }

      const dispDate = dayjs(x).toDate();
      return dayjs.tz(dispDate).format('l LT');
   }

   return (
      <>
         <Button
            variant="text"
            size="small"
            onClick={openDialog}
            color="inherit">
            {displayTimeStr()}
         </Button>
         <Dialog open={isOpen} onClose={rejected}>
            <DialogTitle>Query Time</DialogTitle>

            <DialogContent>
               <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                     label="Time"
                     value={dateTime}
                     onChange={(d)=>{
                        setDateTime(d);
                     }}
                     disabled={false}
                     renderInput={(params) => {
                        params.variant="standard";
                        return (
                           <TextField
                              {...params}
                           />
                        );
                     }}
                  />
               </LocalizationProvider>
            </DialogContent>

            <DialogActions>
               <Button onClick={rejected}>Cancel</Button>
               <Button onClick={accepted}>Ok</Button>
            </DialogActions>
         </Dialog>
      </>
   );
}


export default DateTimeSelectionDialogButton;
