const d3 = require('d3');


/** Create positionable dom with an image
 */
function ImageBox(parent){
    (()=>{
        this.dom = d3.select(parent)
            .append('div')
            .attr('class', 'ep-ref-popup')
            .style('left', '0px')
            .style('top', '0px');
        this._imgcontainer = this.dom
            .append('div')
            .attr('class', 'ep-ref-img-container');
        this._img = this._imgcontainer
            .append('img')
            .attr('class', 'ep-ref-img');
    })();
}


/** Set image url
 *
 * @param {text} url path to image
 */
ImageBox.prototype.setUrl = function(url){
    this._img.attr('src', url);
};


/** Return estimated rendered bounding box
 *
 */
ImageBox.prototype.boundingRect = function(){
    return this.dom.node().getBoundingClientRect();
};


/** Display image optionally at a given coordinate
 *
 * @param {integer} x optional x-pos of image relative to parent
 * @param {integer} x optional y-pos of image relative to parent
 */
ImageBox.prototype.show = function(x, y){
    this.dom
        .style('display', 'inline-block');

    if (x === undefined){
        return;
    }

    x = x - this.boundingRect().width/2;
    this.dom
        .transition()
        .style('left', `${x}px`)
        .style('top', `${y}px`);
};


/** Hide image
 *
 */
ImageBox.prototype.hide = function(){
    this.dom.style('display', 'none');
};


module.exports = ImageBox;
