import * as React from 'react';
import {Navigate, useNavigate} from 'react-router-dom';
import {useAuth, AuthContextProvider} from './hooks/useAuth';



export default function RequireLogin(params){
   const loginRoute = params.login || '/login';
   const target = params.target;
   const children = params.children;
   const auth = useAuth();
   const navigate = useNavigate();

   React.useEffect(()=>{
      window.addEventListener('focus', _windowFocused);
   }, []);

   if (! auth.user){
      return <Navigate replace to={loginRoute} state={{next: target}} />;
   }
   else if (target){
      return <Navigate replace to={target} />;
   }


   async function _windowFocused(){
      if (await auth.isValid()){
         return;
      }
      return navigate(loginRoute);
   }

   return (
      <>
         {children}
      </>
   );
}
