import * as React from 'react';
import {
   List,
   EditableDataGrid,
   Datagrid,
   TextField,
   DateField,
   BooleanField
} from 'react-admin';


export const UserList = () => (
    <List exporter={false}>
       <Datagrid rowClick="edit">
            <TextField source="email"/>
            <TextField source="firstname"/>
            <TextField source="lastname"/>
            <BooleanField source="admin" />
            <BooleanField source="enabled" />
            <DateField showTime={true} source="lastLogin" label="Last Login"/>
            <DateField showTime={true} source="createdAt" label="Created"/>
            <DateField showTime={true} source="updatedAt" label="Modified"/>
        </Datagrid>
    </List>
);
