'use strict';

const d3 = require('d3');


/** Draw list of events as lines on plot
 *
 * @param {d3.svg} parent container housing the event markers
 * @param {list} data list of events, arranged as [Date, 'str']
 */
function EventMarkers(parent, container, margins, data){
    this.data = data;
    this.parentMargins = margins;
    this.parent = parent;

    this.tooltip = d3.select(container)
        .append('div')
        .attr('class', 'ep-event-tooltip')
        .style('left', '0px')
        .style('top', '0px')
        .style('visibility', 'hidden')
        .style('white-space', 'pre-line');

   this._clipId = 'clip' + Math.floor(Math.random()*10000);
   this._clipPath = parent.append('clipPath')
        .attr('id', this._clipId)
        .append('rect');

    parent.append('svg:defs').append('svg:marker')
        .attr('id', 'eventTriangle')
        .attr('refX', 3)
        .attr('refY', 3)
        .attr('markerWidth', 30)
        .attr('markerHeight', 30)
        .attr('orient', 'auto')
        .append('path')
        .attr('class', 'ep-event-triangle')
        .attr('d', 'M 0 0  12 3 0 6  3 3')
        .attr('clip-path', ()=>{
           return 'url(#' + this._clipId + ')';
        });
}


EventMarkers.prototype._drawLines = function(bottomAxis, leftAxis){
    let items = this.parent.selectAll('.ep-event-marker')
        .data(this.data);

    // remove
    items.exit()
        .remove();

    // update
    const leftDomain = leftAxis.range();
    items
        .style('fill', 'none')
        .attr('marker-start', 'url(#eventTriangle)')
        // .attr('marker-end', 'url(#eventTriangle)')
        .attr('x1', d =>{
            return bottomAxis.map_to_canvas(d[0]);
        })
        .attr('x2', d =>{
            return bottomAxis.map_to_canvas(d[0]);
        })
        .attr('y1', ()=>{
            return leftDomain[0];
        })
        .attr('y2', ()=>{
            return Math.abs(leftDomain[0] - leftDomain[1]);
        })
        .attr('clip-path', ()=>{
           return 'url(#' + this._clipId + ')';
        });

    // new elements in new data.
    items
        .enter()
        .append('line')
        .style('fill', 'none')
        .attr('class', 'ep-event-marker')
        .attr('marker-start', 'url(#eventTriangle)')
        // .attr('marker-end', 'url(#eventTriangle)')
        .attr('x1', d => {
            return bottomAxis.map_to_canvas(d[0]);
        })
        .attr('x2', d => {
            return bottomAxis.map_to_canvas(d[0]);
        })
        .attr('y1', () => {
            return leftDomain[0];
        })
        .attr('y2', () => {
            return Math.abs(leftDomain[0] - leftDomain[1]);
        })
        .attr('clip-path', ()=>{
           return 'url(#' + this._clipId + ')';
        });

};


EventMarkers.prototype._drawCircles = function(bottomAxis, leftAxis){
    let items = this.parent.selectAll('.ep-event-marker-mouse')
        .data(this.data);

    // remove
    items.exit()
        .remove();

    // update
    items
        .attr('cx', d =>{
            return bottomAxis.map_to_canvas(d[0]);
        })
        .attr('cy', () =>{
           const leftDomain = leftAxis.range();
            return Math.abs(leftDomain[0] - leftDomain[1]);
        })
        .attr('r', '7px')
        .attr('clip-path', ()=>{
           return 'url(#' + this._clipId + ')';
        });

    // new elements in new data.
    items
        .enter()
        .append('circle')
        .attr('class', 'ep-event-marker-mouse')
        .attr('cx', d => {
            return bottomAxis.map_to_canvas(d[0]);
        })
        .attr('cy', () => {
            const leftDomain = leftAxis.range();
            return Math.abs(leftDomain[0] - leftDomain[1]);
        })
        .attr('r', '7px')
        .on('mouseover', (i, d)=>{
            const leftDomain = leftAxis.range();
            let x = bottomAxis.map_to_canvas(d[0]);
            let y = Math.abs(leftDomain[0] - leftDomain[1]);
            this._showTooltip([x, y], d[1]);
        })
        .on('mouseout', this._hideTooltip.bind(this));
};


EventMarkers.prototype._showTooltip = function(pos, content){
    this.tooltip.html(content);

    let bbox = this.tooltip.node().getBoundingClientRect();
    // Center tooltip at x
    let x = pos[0] + this.parentMargins.left - bbox.width/2;
    let y = pos[1];

    this.tooltip
        .style('visibility', 'visible')
        .style('left', `${x}px`)
        .style('top', `${y}px`);
};


EventMarkers.prototype._hideTooltip = function(){
    this.tooltip.style('visibility', 'hidden');
};


EventMarkers.prototype.draw = function(bottomAxis, leftAxis){
    this._drawLines(bottomAxis, leftAxis);
    this._drawCircles(bottomAxis, leftAxis);
};


EventMarkers.prototype.redraw = function(bottomAxis, leftAxis, width, height){
    const leftDomain = leftAxis.range();

    this.parent.selectAll('.ep-event-marker-mouse')
        .attr('cx', d =>{
            return bottomAxis.map_to_canvas(d[0]);
        })
        .attr('cy', () =>{
            return Math.abs(leftDomain[0] - leftDomain[1]);
        });

    this.parent.selectAll('.ep-event-marker')
        .attr('x1', d =>{
            return bottomAxis.map_to_canvas(d[0]);
        })
        .attr('x2', d =>{
            return bottomAxis.map_to_canvas(d[0]);
        })
        .attr('y1', ()=>{
            return leftDomain[0];
        })
        .attr('y2', ()=>{
            return Math.abs(leftDomain[0] - leftDomain[1]);
        });

    this._clipPath
        .attr('width', width - this.parentMargins.left - this.parentMargins.right)
        .attr('height', height+7 - this.parentMargins.top - this.parentMargins.bottom);

};



module.exports = EventMarkers;
