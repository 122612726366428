/**
 * @module TimeWidget
 */


import * as React from 'react';
import Stack from '@mui/material/Stack';

import ToggleButton from '@mui/material/ToggleButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DateTimeSelectionDialogButton from './DateTimeSelectionDialogButton';


/** TimeWidget state
 *
 * @typedef {Object} TimeWidgetState

 * @property {String} displayTimeStr displayed timestring on toolbar
 * @property {boolean} showLatest if true, indicates latest available
 *    is being displayed
 * @property {dayjs} date date+time used in the datetime selection widget
 */


/** Widget to display time and means to modify the query time
 *
 * @param {TimeWidgetState} props.state render state of the TimeWidget
 * @param {timeWidgetStateChanged} onChange callback for when user changes
 *    widget state
 */
function TimeWidget(props){
   const state = props.state;
   const onChange = props.onChange || _onChangeCb;


   /** Callback called when user changes TimeWidget state
    *
    * @function timeWidgetStateChanged
    *
    * @param {TimeWidgetState} data new state of the time widget
    */
   function _onChangeCb(data){ }


   function notifyChange(showLatest, datetime){
      if (!onChange){
         return;
      }
      onChange({
         ...state,
         showLatest: showLatest,
         date: datetime
      });
      return;
   }


   function dateTimeChanged(dateObj){
      notifyChange(false, dateObj);
   }

   return (
      <Stack direction="row" spacing={1}>
         <DateTimeSelectionDialogButton
            displayDate={state.date}
            onAccept={dateTimeChanged}
         />
         <ToggleButton
            edge="end"
            size="small"
            selected={state.showLatest}
            value="latest"
            disabled={false}
            onClick={()=>{
               notifyChange(!(state.showLatest), state.date);
            }}
            sx={{
               border: 'none',
               p: 0
            }}
         >
            <PlayArrowIcon size="inherit" sx={{color: state.showLatest ? 'red' : '#000' }} />
         </ToggleButton>
      </Stack>
   );
}


export default TimeWidget;
