import config from '../config/config';
import * as utils from '../utils';
import * as Datastore from '../Datastore';
import * as d3 from 'd3';



const STROKE_COLORS = ['blue', 'red', 'green', '#ff852b', '#4f9191', '#9d0eff'];


/** A plottable option data
 *
 * Key is the unique sensor identifier
 *
 * @typedef {Object} PlottableOption
 * @property {String} key identifier of the plottable data
 * @property {('Jack'|'Rod'|'PT_GROUP')} type type of plottable data
 */
function _acGroupList(keys, groupLabel, type, unit){
   return keys.map((key)=> {
      return {
         groupLabel: groupLabel,
         key: key,
         type: type,
         unit: unit
      };
   });
}


export function extensometerPlotOptionGroups(){
   return _acGroupList(
      config.sensorGroups['EXTENSOMETERS'], 'Extensometers', 'EXTENSOMETERS', 'in');
}


export function interpDispPlotOptionGroups(){
   return _acGroupList(
      config.sensorGroups['INTERP_DISPS'], 'Interp. Disp', 'Interp. Disp.', 'in');
}


export function tiltPlotOptionGroups(){
   return _acGroupList(
      config.sensorGroups['TILTS'], 'Tilts', 'TILTS', 'deg');
}


/** Generate a list of available plottable options
 *
 * @param {Object} userGroups user-defined groups
 * @return {Array<PlottableOption>} list of available plottable
 *     options
 */
export function tsPlottableOptionList(userGroups){
   const groupList = userGroups;

   let ret = groupList.map((elem)=> {
      return {
         groupLabel: 'PT Groups',
         key: elem.label,
         type: 'PT_GROUP',
         keys: elem.keys,
         unit: 'pt'
      };
   });

   ret = ret.concat(
         _acGroupList(
            config.sensorGroups['PLATE_JACK'], 'Plate Jacks', 'PLATE_JACK', 'pt'))
      .concat(
         _acGroupList(
            config.sensorGroups['COMP_ROD'], 'Compression Rods', 'COMP_ROD', 'pt'))
      .concat(
         _acGroupList(
            config.sensorGroups['THERMO_COUPLE'], 'Thermocouples', 'THERMO_COUPLE', 'degF'))
      .concat(extensometerPlotOptionGroups())
      .concat(interpDispPlotOptionGroups())
      .concat(tiltPlotOptionGroups())
      .concat(
         _acGroupList(
            config.sensorGroups['BATTV'], 'Station Voltage', 'Voltage', 'volt'));

   return ret;
}


/** TODO
 *
 * Available plot options for x-axis
 */
export function xPlottableOptionList(userGroups){
   const groupList = userGroups;

   let ret = groupList.map((elem)=> {
      return {
         key: elem.label,
         groupLabel: 'PT Groups',
         type: 'PT_GROUP',
         keys: elem.keys,
         unit: 'pt'
      };
   });

   ret = ret.concat(
      _acGroupList(
         config.sensorGroups['PLATE_JACK'], 'Plate Jacks', 'PLATE_JACK', 'pt'))
      .concat(
         _acGroupList(
            config.sensorGroups['COMP_ROD'], 'Compression Rods', 'COMP_ROD', 'pt'))
      .concat(
         _acGroupList(
            config.sensorGroups['THERMO_COUPLE'], 'Thermocouples', 'THERMO_COUPLE', 'degF'))
      .concat(extensometerPlotOptionGroups())
      .concat(interpDispPlotOptionGroups())
      .concat(tiltPlotOptionGroups())
      .concat(
         _acGroupList(
            config.sensorGroups['BATTV'], 'Station Voltage', 'Voltage', 'volt'));

   return ret;
}


/* Return unique sensor keys specified in user plot selection
 *
 * @param  {Array<PlottableOption>} selection selected senors
 * @return {Array<string>} unique list of keys
 */
export function sensorKeysFromSelection(selection){
   let ret = [];
   for (let i=0; i<selection.length; ++i){
      const type = selection[i].type;
      const key = selection[i].key;

      if (type === 'PT_GROUP'){
         ret = ret.concat(selection[i].keys);
      }
      else{
         ret.push(key);
      }
   }

   return [...new Set(ret)];
}


/** Sum multiple time-series
 *
 */
function sumTsSeries(dataDict, keys){
   let ret = [];
   if (keys.length < 1){
      return ret;
   }

   const lengths = keys.map(key => dataDict[key].length);
   const N = Math.min(...lengths);

   for (let i=0; i<N; ++i){
      const times = keys.map(key => dataDict[key][i][0]);
      const areEqual = times.every((val, _, arr) => val.getTime() === arr[0].getTime() );
      if (!areEqual){
         continue;
      }

      const vals = keys.map(key => dataDict[key][i][1]);
      ret.push([
         times[0],
         vals.reduce((p, a) => p + a, 0)
      ]);
   }

   return ret;
}


export function toLineSeries(plotConfig, dataDict){
   if (plotConfig.type === 'PT_GROUP'){
      return sumTsSeries(dataDict, plotConfig.keys);
   }
   return dataDict[plotConfig.key];
}



export async function fetchXyPlotData(xySelection, modeIndex, t0, t1){
   const modeKey = config.ptDisplayModes[modeIndex].key;

   // Individual sensor keys
   const sensors = xySelection.map(elem => elem.x)
         .concat(xySelection.map(elem => elem.y));
   const requiredKeys = sensorKeysFromSelection(sensors);
   if (requiredKeys.length < 2){
      return [];
   }

   const sensorHistDict = await Datastore.timeHistory(requiredKeys, modeKey, t0, t1);

   return xySelection.map((elem, i)=>{
      const xdata = toLineSeries(elem.x, sensorHistDict);
      const ydata = toLineSeries(elem.y, sensorHistDict);
      const plotData = utils.tsToXySeries(xdata, ydata);
      return {
         key: i,
         label: elem.x.key  + '/' + elem.y.key,
         axis: 'left',
         linewidth: 1,
         color: STROKE_COLORS[i % STROKE_COLORS.length ],
         data: plotData
      };
   });
}


export async function fetchTsPlotData(tsSelection, modeIndex, t0, t1, leftRight){
   if (tsSelection.length === 0){
      return [];
   }

   const modeKey = config.ptDisplayModes[modeIndex].key;

   // Individual sensor keys
   const requiredKeys = sensorKeysFromSelection(tsSelection);
   const sensorHistDict = await Datastore.timeHistory(requiredKeys, modeKey, t0, t1);

   return tsSelection.map((elem, i)=>{
      return {
         key: leftRight+i,
         label: elem.key,
         axis: leftRight,
         linestyle: leftRight === 'right' ? '--' : '-',
         linewidth: 1,
         color: STROKE_COLORS[i % STROKE_COLORS.length ],
         data: toLineSeries(elem, sensorHistDict)
      };
   });
}


function __tpformatNumber(val, delta){
   if (delta < 0.1){
      return val.toFixed(3);
   }
   else if (delta < 0.01){
      return val.toExponential(2);
   }
   return val.toFixed(2);
}


export function tsFormat(point, data){
   // exntent of the series in the 'y' direction
   let yRange = d3.extent(data.data, (d)=> d[1]);
   let delta = Math.abs(yRange[1] - yRange[0]);

   // Format displayed number based on range in 'y' extent
   let val = __tpformatNumber(point[1], delta);
   let time = point[0].toLocaleString();
   let color = `${data.color}`;
   let label = `<b style="color: ${color}">${data.label}</b>`;
   return `${time}<br>${label}: ${val}`;
}


export function xyFormat(point, data){
   // exntent of the series in the 'y' direction
   const xRange = d3.extent(data.data, (d)=> d[0]);
   const yRange = d3.extent(data.data, (d)=> d[1]);
   const xDelta = Math.abs(yRange[1] - yRange[0]);
   const yDelta = Math.abs(xRange[1] - xRange[0]);

   // Format displayed number based on range in 'y' extent
   const xVal = __tpformatNumber(point[0], xDelta);
   const yVal = __tpformatNumber(point[1], xDelta);
   const time = point[2].toLocaleString();

   let color = `${data.color}`;
   let label = `<b style="color: ${color}">${data.label}</b>`;
   return `${time}<br>${label}: (${xVal}, ${yVal})`;
}
