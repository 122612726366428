const d3 = require('d3');


/** Default Tooltip content delegate for plots
 *
 * @param {list} point [x, y] coordinate of the plot point
 * @param {Object} data Model data for the series,
 *        see `config.plots.series`
 * @returns {string} contents of the tooltip. Accepts HTML
 */
function tooltipFormatDelegate(point, data){
    var val = `${point[0].toFixed(0)}, ${point[1].toFixed(0)}`;
    var color = `${data.color}`;
    var label = `<b style="color: ${color}">${data.label}</b>`;
    return `${label}: ${val}`;
}


function Tooltip(plot, opts){
    opts = opts || {};
    this.formatDelegate = opts.formatDelegate || tooltipFormatDelegate;

    this.dom = d3.select(plot.container)
        .append('div')
        .attr('class', 'ep-tooltip-box')
        .style('left', '0px')
        .style('top', '0px');

    this.dom_dot = d3.select(plot.container)
        .append('div')
        .attr('class', 'ep-tooltip-dot')
        .style('left', '0px')
        .style('top', '0px');


    this.plot = plot;
    plot.subscribe('mousemove', this.__mousemove.bind(this));
    plot.subscribe('mouseleave', this.__mouseleave.bind(this));

    this.hide(true);
}


Tooltip.prototype.__mousemove = function(point){
    let cls = this.plot.closestSeries(point);
    if (cls === undefined){
        this.hide(true);
        return;
    }

    let _x = this.plot.bottomAxis.map_to_canvas(cls.point[0])
        + this.plot.margins.left;
    let _y = this.plot.leftAxis.map_to_canvas(cls.point[1])
        + this.plot.margins.top;
    if (cls.series.axis === 'right'){
        _y = this.plot.rightAxis.map_to_canvas(cls.point[1])
            + this.plot.margins.top;
    }

    var html = this.formatDelegate(cls.point, cls.series);
    this.display([_x, _y], html);
};


Tooltip.prototype.__mouseleave = function(){
    this.hide(true);
};


Tooltip.prototype.hide = function (tf){
    var state = tf ? 'hidden' : 'visible';
    this.dom.style('visibility', state);
    this.dom_dot.style('visibility', state);
};


Tooltip.prototype.display = function(p, content){
    this.hide(false);

    var bbox = this.dom.node().getBoundingClientRect();
    var p_bbox = this.dom_dot.node().getBoundingClientRect();

    this.dom
        .transition()
        .duration(35)
        .style('left', `${Math.max(0, p[0]-bbox.width/2)}px`)
        .style('top', `${p[1]+10}px`);

    this.dom_dot
        .style('left', `${p[0]-p_bbox.width/2}px`)
        .style('top', `${p[1]-p_bbox.height/2}px`);

    this.dom.html(content);
};


module.exports = Tooltip;
