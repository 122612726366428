const d3 = require('d3'),
      Axis = require('./Axis'),
      Legend = require('./Legend'),
      Tracker = require('./Tracker'),
      Tooltip = require('./Tooltip'),
      Rubberband = require('./Rubberband'),
      HCenterContainer = require('./HCenterContainer'),
      ImageBox = require('./ImageBox'),
      EventMarkers = require('./EventMarkers');



/** Tooltip content delegate for time-series plots
 *
 * @param {list} point [x, y] coordinate of the plot point
 * @param {Object} data Model data for the series,
 *        see `config.plots.series`
 * @returns {string} contents of the tooltip. Accepts HTML
 */
function tooltipFormatDelegateT(point, data){
    // exntent of the series in the 'y' direction
    let yRange = d3.extent(data.data, (d)=> d[1]);
    let delta = Math.abs(yRange[1] - yRange[0]);

    // Format displayed number based on range in 'y' extent
    let val = point[1].toFixed(2);
    if (delta < 0.1){
        val = point[1].toFixed(3);
    }
    else if (delta < 0.01){
        val = point[1].toExponential(2);
    }

    let time = point[0].toLocaleString();
    let color = `${data.color}`;
    let label = `<b style="color: ${color}">${data.label}</b>`;
    return `${time}<br>${label}: ${val}`;
}


/** Return x-component of the data
 *
 * Series data is assumed to follow following format
 *   [ [x0, y0],
 *     [x1, y1],
 *     ...
 *     [xn, yn]
 *   ]
 */
function xData(d) { return d[0]; }

/** Return y-component of the data
 *
 * Series data is assumed to follow following format
 *   [ [x0, y0],
 *     [x1, y1],
 *     ...
 *     [xn, yn]
 *   ]
 */
function yData(d) { return d[1]; }


// function parseTime(tstring){
//     return d3.timeParse('%Y-%m-%dT%H:%M:%S.%LZ')(tstring);
// }

export function EPlot(container, opts){
    this.opts = opts || {};
    this.opts.legend = this.opts.legend || {};

    this.container = container;
    this.parent = d3.select(container);

    this.width = this.opts.width || this.parent.node().getBoundingClientRect().width;
    this.height = this.opts.height || 500;

    this.__signals = [
        'mousemove',
        'mouseleave'
    ];

    // Margins defining the bottom-left corner of the plot canvas
    // itself
    this.margins = {
        top: 20,
        right: 50,
        bottom: 100,
        left: 80
    };

    // Bounding box of the plottable area
    this.bbox = [
        [this.margins.left, this.height - this.margins.bottom],
        [this.width - this.margins.right, this.margins.top]
    ];

    this.svg = d3.select(container)
        .append('svg')
        .attr('class', 'ep-plot')
        .attr('width', this.width)
        .attr('height', this.height);

    // group element housing all plottables
    this.plot_group =
        this.svg.append('g')
        .attr(
            'transform',
            `translate(${this.margins.left}, ${this.margins.top})`);

   this._clipId = 'clip' + Math.floor(Math.random()*10000);
   this._clipPath = this.plot_group.append('clipPath')
        .attr('id', this._clipId)
        .append('rect')
        .attr('width', this.width - this.margins.left - this.margins.right)
        .attr('height', this.height - this.margins.top - this.margins.bottom);

    this.plotOverlayGroup =
        this.svg.append('g')
        .attr('class', 'ep-plot-group')
        .attr(
            'transform',
            `translate(${this.margins.left}, ${this.margins.top})`);

    this.plot_background = this.plotOverlayGroup
        .append('rect')
        .attr('width', this.width - this.margins.left - this.margins.right)
        .attr('height', this.height - this.margins.top - this.margins.bottom)
        .attr('class', 'ep-plot-background');

    this.eventMarkers = new EventMarkers(
        this.plotOverlayGroup, container, this.margins, []);

    this.plot_background.on('mousemove', this.__mousemove.bind(this));
    this.plot_background.on('mouseleave', this.__mouseleave.bind(this));

    this.leftAxis = new Axis({
        location: 'left',
        margins: [this.margins.left, this.margins.top],
        box: this.bbox,
        label: 'y axis'
    });

    this.rightAxis = new Axis({
        location: 'right',
        margins: [this.width - this.margins.right, this.margins.top],
        box: this.bbox,
        label: ''
    });

    this.rightAxis
        .domain([this.height - this.margins.top - this.margins.bottom, 0])
        .range([0, this.height - this.margins.bottom - this.margins.top])
        .attach(this.svg);

    this.leftAxis
        .domain([this.height - this.margins.top - this.margins.bottom, 0])
        .range([0, this.height - this.margins.bottom - this.margins.top])
        .attach(this.svg);

    let baOpts = this.opts.bottomAxis || {};
    let timeAxis = baOpts.time === undefined ? true : baOpts.time;
    let baScale = timeAxis ? d3.scaleTime() : undefined;

    this.bottomAxis = new Axis({
        location: 'bottom',
        margins: [this.margins.left, this.height - this.margins.bottom],
        box: this.bbox,
        scale: baScale
    });

    this.bottomAxis
        .domain([0, 1000])
        .range([0, this.width - this.margins.left - this.margins.right])
        .attach(this.svg);


    this.canvas_map = {
        left: function(p){
            return this.leftAxis.map_to_canvas(p[1]);
        },
        right: function(p){
            return this.rightAxis.map_to_canvas(p[1]);
        },
        bottom: function(p){
            return this.bottomAxis.map_to_canvas(p[0]);
        }
    };

    this.observers = {};
    this.tooltip = new Tooltip(this,{
        formatDelegate: tooltipFormatDelegateT
    });

    this.rubberband = new Rubberband(this);
    this.tracker = new Tracker(this);
    this._titleContainer = new HCenterContainer(
        this.svg, this.width, this.margins.top - 20
    );
    this.title('');

    this._imageHover = new ImageBox(this.parent.node());
    if (this.opts.legend.reference !== undefined){
        this.setRefImage(
            this.opts.legend.reference.text, this.opts.legend.reference.url);
    }

   this.__drawLegends();
}


/** Resize the plot to given dimensions
 *
 * If not dimenions are given, will resize to parent width/height
 *
 * @param {int} width plot width in pixels
 * @param {int} height plot height in pixels
 */
EPlot.prototype.resize = function(width, height){
    this.width = width || this.parent.node().getBoundingClientRect().width;
    this.height = height || this.parent.node().getBoundingClientRect().height;

    this.bbox = [
        [this.margins.left, this.height - this.margins.bottom],
        [this.width - this.margins.right, this.margins.top]
    ];

    /* Update plot dimensions
     *
     */
    this.svg.attr('width', this.width);
    this.svg.attr('height', this.height);
    this._clipPath
        .attr('width', this.width - this.margins.left - this.margins.right)
        .attr('height', this.height - this.margins.top - this.margins.bottom);
    this.plot_background
        .attr('width', this.width - this.margins.left - this.margins.right)
        .attr('height', this.height - this.margins.top - this.margins.bottom);

    /* Redraw plot components
     *
     */
    this.leftAxis.resize(this.bbox);
    this.bottomAxis.resize(this.bbox);
    this.rightAxis.resize(this.bbox);
    this._titleContainer.resize(this.width);

    function xmap(p){ return this.bottomAxis.map_to_canvas(p[0]); }
    function ymapLeft(p){ return this.leftAxis.map_to_canvas(p[1]); }
    function ymapRight(p){ return this.rightAxis.map_to_canvas(p[1]); }
    var valuelineLeft = d3.line()
        .x(xmap.bind(this))
        .y(ymapLeft.bind(this));
    var valuelineRight = d3.line()
        .x(xmap.bind(this))
        .y(ymapRight.bind(this));
    this.plot_group
        .selectAll('.ep-line-plot')
        .filter(function(d){
            return d.axis !== 'right';
        })
        .attr('d', function(d){
            return valuelineLeft(d.data);
        });
    this.plot_group
        .selectAll('.ep-line-plot')
        .filter(function(d){
            return d.axis === 'right';
        })
        .attr('d', function(d){
            return valuelineRight(d.data);
        });

    this.eventMarkers.redraw(
       this.bottomAxis, this.leftAxis, width, height);

    this.__drawLegends();
};


/** Set plot title
 *
 * @param {string} str plot title
 * @returns {undefined} None
 */
EPlot.prototype.title = function(str){
    if (this._title === undefined){
        this._title = this._titleContainer
            .append('text')
            .attr('class', 'ep-title');
    }

    this._title.text(str);
    this._titleContainer.center();
};


EPlot.prototype.setRefImage = function(text, url){
    let elem = this._titleContainer
        .append('text')
        .attr('class', 'ep-ref-link')
        .text(text);

    this._imageHover.setUrl(url);

    elem.on('mouseover', (event)=>{
        let x = event.clientX - this._imageHover.boundingRect().width/2;
        let y = this.margins.top;
        this._imageHover.show(x, y);
    });

    elem.on('mouseout', ()=>{
        this._imageHover.hide();
    });

    elem.on('click', ()=>{
        window.open(
            url, '_blank');
    });

    this._refImage = {
        elem: elem,
        url: url
    };
};


EPlot.prototype.subscribe = function(event, cb){
    if (!(event in this.observers)){
        this.observers[event] = [];
    }

    this.observers[event].push(cb);
};


EPlot.prototype.unsubscribe = function(event, cb){
    if (!(event in this.observers)){
        this.observers[event] = [];
    }

    const idx = this.observers[event].indexOf(cb);
    if (!cb){
        this.observers[event] = [];
    }
    else if (idx > -1){
        this.observers[event].splice(idx, 1);
    }
};



EPlot.prototype.notify = function(event, data){
    if (! (event in this.observers)){
        return;
    }

    let obs_list = this.observers[event];
    for (let i=0; i<obs_list.length; ++i){
        obs_list[i](data);
    }
};


EPlot.prototype.__mousemove = function(event){
    let p = d3.pointer(event);
    this.notify('mousemove', [p[0]+this.margins.left, p[1]]);
};


EPlot.prototype.__mouseleave = function(){
    this.notify('mouseleave');
};


EPlot.prototype.__repositionRightLegend = function(){
   let seriesRight = this.__rightSeries();
   const oset = this.legendRight.maxColumns * 80;
   let legend_x = this.width - this.margins.right - oset;
   let legend_y = this.height - this.margins.bottom + 50;
   this.legendRightGroup
      .attr(
         'transform',
         `translate(${legend_x}, ${legend_y})`
      );
};


EPlot.prototype.__repositionLeftLegend = function(){
    var legend_x = this.margins.left;
    var legend_y = this.height - this.margins.bottom + 50;
    this.leftLegendGroup
        .attr(
            'transform',
            `translate(${legend_x}, ${legend_y})`
        );
};


EPlot.prototype.__drawLegends = function(){
    if (this.legendLeft !== undefined){
        this.legendLeft.data = this.__leftSeries();
        this.legendRight.data = this.__rightSeries();

        this.__repositionRightLegend();
        this.__repositionLeftLegend();
        this.legendLeft.draw();

        if (this.legendRight !== undefined){
            this.legendRight.draw();
        }
        return this;
    }

    var legend_x = this.margins.left;
    var legend_y = this.height - this.margins.bottom + 0;
    this.leftLegendGroup = this.svg
        .append('g')
        .attr(
            'transform',
            `translate(${legend_x}, ${legend_y})`
        );

    let seriesLeft = this.__leftSeries();
    this.legendLeft = new Legend(
        this.leftLegendGroup,
        seriesLeft,
        this.opts.legend);

    this.legendLeft.on('legendhover', this.__legendhover.bind(this));
    this.legendLeft.on('legendunhover', this.__legendunhover.bind(this));
    this.legendLeft.on('click', this.__legendclicked.bind(this));

    let seriesRight = this.__rightSeries();

    legend_x = this.width - this.margins.right - seriesRight.length * 60;
    legend_y = this.height - this.margins.bottom + 60;
    this.legendRightGroup = this.svg
        .append('g')
        .attr(
            'transform',
            `translate(${legend_x}, ${legend_y})`
        );
    this.legendRight = new Legend(
        this.legendRightGroup,
        seriesRight,
        this.opts.legend);

    this.legendRight.on('legendhover', this.__legendhover.bind(this));
    this.legendRight.on('legendunhover', this.__legendunhover.bind(this));
    this.legendRight.on('click', this.__legendclicked.bind(this));

    return this;
};


EPlot.prototype.__legendhover = function(key){
    var line_plots =
        this.plot_group
        .selectAll('.ep-line-plot');

    line_plots.each(function(d){
        if (d.key !== key){
            return;
        }
        d3.select(this)
            .attr('stroke-width', d.linewidth*3);
    });
};


EPlot.prototype.__legendunhover = function(key){
    var line_plots =
        this.plot_group
        .selectAll('.ep-line-plot');

    line_plots.each(function(d){
        if (d.key !== key){
            return;
        }
        d3.select(this)
            .attr('stroke-width', d.linewidth);
    });
};


/** Set all series plot state to true or false
 * @private
 *
 * Does not replot series.
 *
 * @param {bool} tf next state of plot series
 */
EPlot.prototype.__setSelectionCollective = function(tf){
   for (let i=0; i<this.pdata.length; ++i){
      this.pdata[i].enabled = tf;
   }
};


/** Return series index by key
 * @private
 *
 * @return {int} Number of active legends
 */
EPlot.prototype.__legendActiveCount = function(){
   let ret = 0;
   for (let i=0; i<this.pdata.length; ++i){
      let state = this.pdata[i].enabled;
      if (state || (state === undefined)){
         ++ret;
      }
   }
   return ret;
};


/** Return series index by key
 * @private
 *
 * @param {string} key unique series key
 * @return {int|undefined} corresponding series key, or undefined if
 *         non-existent
 */
EPlot.prototype.__seriesIndex = function(key){
   for (let i=0; i<this.pdata.length; ++i){
      var pd = this.pdata[i];
      if (pd.key !== key){
         continue;
      }
      return i;
   }
   return undefined;
};


EPlot.prototype.__legendclicked = function(key, event){
    let seriesIndex = this.__seriesIndex(key);
    if (seriesIndex === undefined){
       return;
    }

    let activeCount = this.__legendActiveCount();
    let currentIsActive = (this.pdata[seriesIndex].enabled === true)
        || (this.pdata[seriesIndex].enabled === undefined);
    this.pdata[seriesIndex].enabled = currentIsActive;

    if (event.ctrlKey){
       this.pdata[seriesIndex].enabled = !this.pdata[seriesIndex].enabled;
    }
    else if ((activeCount === 1) && (currentIsActive)){
       this.__setSelectionCollective(true);
    }
    else if (currentIsActive){
       this.__setSelectionCollective(false);
       this.pdata[seriesIndex].enabled = true;
    }
    else{
       this.pdata[seriesIndex].enabled = !this.pdata[seriesIndex].enabled;
    }

    this.plot(this.pdata);
    this.setAxesRange(this.bottomAxis.domain(), 'auto', 'auto');
    this.legendLeft.draw();
};


/** Return list of objects where key is true or undefied
 *
 *
 */
function filter_key(list, key){
    return (list || []).filter(
        pd => ((pd[key] === true) || (pd[key] === undefined))
    );
}


/** Helper function to draw series on parent svg group
 *
 * @param {list} pdata lineseries data
 * @param {func} valueToLine function mapping data to canvas coord
 * @param {object} parent svg group element housing series
 * @param {string} leftRight 'right' if attaching to right axis
 * @param {object} opts additional options
 */
function __insertLinePlots(pdata, valueToLine, parent, leftRight, opts){
    var items = parent
        .selectAll('.ep-line-plot')
        .filter(function(d){
            if (leftRight === 'right'){
                return d.axis === 'right';
            }
            return d.axis !== 'right';
        })
        .data(pdata, function(data){
            // Remove null data
            // This shouldn't really be done here...
            data.data = data.data.filter(function(obj) {
                return (obj[0] != null) && (obj[1] != null);
            });
            return data;
        });

    // remove
    items.exit()
        .remove();

    opts = opts || {};

    // update
    items
        .style('stroke', function(d){
            return d.color;
        })
        .classed('ep-dashed-line', function(d){
            return d.linestyle === '--';
        })
        .attr('stroke-width', function(d){
            return d.linewidth;
        })
        .style('fill', 'none')
        .attr('d', function(d){
            return valueToLine(d.data);
        });

    // new elements in new data.
    items
        .enter()
        .append('path')
        .classed('ep-dashed-line', function(d){
            return d.linestyle === '--';
        })
        .style('fill', 'none')
        .attr('class', 'ep-line-plot')
        .attr('clip-path', function(){
           if (opts.clipId === undefined){
              return '';
           }
           return 'url(#' + opts.clipId + ')';
        })
        .style('stroke', function(d){
            return d.color;
        })
        .attr('stroke-width', function(d){
            return d.linewidth;
        })
        .attr('d', function(d){
            return valueToLine(d.data);
        });
}


/* Guess whether plot data set is timeseries
 *
 * @sa plot
 */
function _isTimeSeriesGuess(plotDataSet){
   if (plotDataSet.length === 0){
      return false;
   }

   for (let i=0; i<plotDataSet.length; ++i){
      const seriesData = plotDataSet[i].data;
      const N = seriesData.length;
      for (let j=0; j<N; ++j){
         if (seriesData[i][j] !== null){
            return typeof(seriesData[i][j].getMonth) === 'function';
         }
      }
   }
   return false;
}


/*
 *
 * Format:
 *   [
 *     {
 *       key: <str>,
 *       label: <str>,
 *       color: <str>,
 *       axis: <str>: {['left'], 'right},
 *       linewidth: <int>,
 *       enabled: <bool>,
 *       data: <array>
 *    }
 *  ]
 *
 */
EPlot.prototype.plot = function(data){
    this.pdata = data;

    // Change between time and linear scale axis
    if (data.length === 0){
        this.__drawLegends();
        return;
    }
    if (! _isTimeSeriesGuess(data)){
        let newScale = d3.scaleLinear();
        newScale.range(this.bottomAxis.range());
        newScale.domain(this.bottomAxis.domain());
        this.bottomAxis.scale(newScale);
        this._isTimeSeries = false;
    }
    else{
       let newScale = d3.scaleTime();
       newScale.range(this.bottomAxis.range());
       newScale.domain(this.bottomAxis.domain());
       this.bottomAxis.scale(newScale);
       this._isTimeSeries = true;
    }

    var valuelineLeft = d3.line()
        .x(this.canvas_map.bottom.bind(this))
        .y(this.canvas_map.left.bind(this));
    var valuelineRight = d3.line()
        .x(this.canvas_map.bottom.bind(this))
        .y(this.canvas_map.right.bind(this));

    let leftSeries = this.__leftSeries(true);
    let rightSeries = this.__rightSeries(true);

    let opts = {
       clipId: this._clipId
    };
    __insertLinePlots(leftSeries, valuelineLeft, this.plot_group, 'left', opts);
    __insertLinePlots(rightSeries, valuelineRight, this.plot_group, 'right', opts);

    this.__drawLegends();
};


EPlot.prototype.plotOverlay = function(data){
    if (data === undefined){
        return;
    }
    this.eventMarkers.data = data || [];
    this.eventMarkers.draw(this.bottomAxis, this.leftAxis);
};


EPlot.prototype._updateMarkers = function(){
    this.eventMarkers.draw(this.bottomAxis, this.leftAxis);
};


/**
 */
function _updateMinMax(oldArray, newArray){
    if (oldArray[0] === undefined){
        return newArray;
    }
    return [
        Math.min(oldArray[0], newArray[0]),
        Math.max(oldArray[1], newArray[1])
    ];
}


/**
 * @typedef {Object} SeriesExtent
 * @property {number} bottom bottom axis extent
 * @property {number} left left axis extent
 * @property {number} right right axis extent
 *
 */


/** Return the min/max of the series data
 *
 * @returns {SeriesExtent} extents in [x, y] directions
 */
EPlot.prototype.seriesExtent = function(){
    var x = [undefined, undefined];
    var yLeft = [undefined, undefined];
    var yRight = [undefined, undefined];

    let series = filter_key(this.pdata || [], 'enabled') || [];

    for (var i=0; i<series.length; ++i){
        var data = series[i].data;
        if (data.length === 0){
           continue;
        }

        var x_r = d3.extent(data, xData);
        var y_r = d3.extent(data, yData);
        let isRightAxis = (series[i].axis === 'right');

        x = _updateMinMax(x, x_r);
        if (isRightAxis){
            yRight = _updateMinMax(yRight, y_r);
        }
        else{
            yLeft = _updateMinMax(yLeft, y_r);
        }
    }

    return {
        bottom: x,
        left: yLeft,
        right: yRight
    };
};


/** Rescale axis to fit to content of the plot
 *
 * @returns {EPlot} self
 */
EPlot.prototype.fitToContent = function(){
    this.setAxesRange('auto', 'auto', 'auto');
    return this;
};


/** Helper func to return series attached to left axis
 *
 * @param {bool} activeOnly if true, returns only active series
 */
EPlot.prototype.__leftSeries = function(activeOnly){
    let ret = [];
    let series = this.pdata || [];
    if (activeOnly){
        series = filter_key(this.pdata, 'enabled');
    }

    for (let i=0; i<series.length; ++i){
        if (series[i].axis === 'right'){
            continue;
        }
        ret.push(series[i]);
    }
    return ret;
};


/** Helper func to return series attached to right axis
 *
 * @param {bool} activeOnly if true, returns only active series
 */
EPlot.prototype.__rightSeries = function(activeOnly){
    let ret = [];
    let series = this.pdata || [];

    if (activeOnly){
        series = filter_key(this.pdata, 'enabled');
    }

    for (let i=0; i<series.length; ++i){
        if (series[i].axis === 'right'){
            ret.push(series[i]);
        }
    }
    return ret;
};


EPlot.prototype.setAxesRange = function(bottom, left, right){
    var extents = this.seriesExtent();
    if (bottom === 'auto'){
        bottom = extents.bottom;
    }

    if (bottom[0] instanceof Date){
        bottom = [bottom[0].getTime(), bottom[1].getTime()];
    }

    if (left === 'auto'){
        left = extents.left;
    }

    if ((right === 'auto') || (right === undefined)){
        right = extents.right;
    }

    var pad_x = 0.0;
    var pad_y = 0.10;
    var marg_x = Math.abs(bottom[1] - bottom[0]) * pad_x;
    var marg_y = Math.abs(left[1] - left[0]) * pad_y;
    var marg_yr = Math.abs(right[1] - right[0]) * pad_y;

    this.leftAxis.domain([left[1] + marg_y, left[0] - marg_y]);
    this.bottomAxis.domain([bottom[0] - marg_x, bottom[1] + marg_x]);

    if (right !== undefined){
        this.rightAxis.domain([right[1] + marg_yr, right[0] - marg_yr]);
    }

    function xmap(p){
        return this.bottomAxis.map_to_canvas(p[0]);
    }

    function ymapLeft(p){
        return this.leftAxis.map_to_canvas(p[1]);
    }

    function ymapRight(p){
        return this.rightAxis.map_to_canvas(p[1]);
    }

    var valuelineLeft = d3.line()
        .x(xmap.bind(this))
        .y(ymapLeft.bind(this));
    var valuelineRight = d3.line()
        .x(xmap.bind(this))
        .y(ymapRight.bind(this));

    var line_plots_left =
        this.plot_group.selectAll('.ep-line-plot')
        .filter(function(d){
            return d.axis !== 'right';
        });
    var line_plots_right =
        this.plot_group.selectAll('.ep-line-plot')
        .filter(function(d){
            return d.axis === 'right';
        });

    // Left axis series
    let leftSeries = this.__leftSeries(true);
    line_plots_left
        .data(leftSeries)
        .enter()
        .append('path')
        .merge(line_plots_left)
        .attr('class', 'ep-line-plot')
        .classed('ep-dashed-line', function(d){
            return d.linestyle === '--';
        })
        .attr('stroke-width', function(d){
            return d.linewidth;
        })
        .transition()
        .duration(200)
        .attr('d', function(d){
            return valuelineLeft(d.data);
        });

    // Right axis series
    let rightSeries = this.__rightSeries(true);
    line_plots_right
        .data(rightSeries)
        .enter()
        .append('path')
        .merge(line_plots_right)
        .attr('class', 'ep-line-plot')
        .classed('ep-dashed-line', function(d){
            return d.linestyle === '--';
        })
        .attr('stroke-width', function(d){
            return d.linewidth;
        })
        .transition()
        .duration(200)
        .attr('d', function(d){
            return valuelineRight(d.data);
        });

    this._updateMarkers();

    return this;
};


function dist(p0, p1){
    return Math.sqrt(
        (p0[0]-p1[0])*(p0[0]-p1[0])
      + (p0[1]-p1[1])*(p0[1]-p1[1])
    );
}


function closestSeriesSorted(p, series, bottomAxis, leftAxis, rightAxis){
    var bisect = d3.bisector(xData).right;
    let xCoord = bottomAxis.dom_to_canvas(p[0]);

    var min_dist = 0;
    var series_ix = -1;
    var ix_min = 0;
    for (let i=0; i<series.length; ++i){
        if (! series[i].data || series[i].data.length === 0) {
            continue;
        }

        let ix = bisect(series[i].data, xCoord);

        if (ix < 0){
            continue;
        }

        if (ix === series[i].data.length){
            ix = ix - 1;
        }
        else if (ix !== 0){
            let d0 = series[i].data[ix-1][0];
            let d1 = series[i].data[ix][0];
            if (Math.abs(d0 - xCoord) < Math.abs(d1 - xCoord)){
                ix = ix-1;
            }
        }

        let canvCoord = [
            bottomAxis.map_to_canvas(series[i].data[ix][0]),
            leftAxis.map_to_canvas(series[i].data[ix][1])
        ];
        if (series[i].axis === 'right'){
            canvCoord[1] = rightAxis.map_to_canvas(series[i].data[ix][1]);
        }
        let pdist = dist(canvCoord, p);

        if (series_ix < 0){
            min_dist = pdist;
            series_ix = i;
            ix_min = ix;
            continue;
        }

        if (pdist < min_dist){
            min_dist = pdist;
            series_ix = i;
            ix_min = ix;
        }
    }

    if (series_ix < 0){
       return undefined;
    }

    return {
        series: series[series_ix],
        point: series[series_ix].data[ix_min]
    };
};


function minDistIndex(p, dataArray){
   let min_d = 1E20;
   let ret = -1;
   for (let i=0; i<dataArray.length; ++i){
      let dx = (p[0] - dataArray[i][0]);
      let dy = (p[1] - dataArray[i][1]);
      let d = Math.sqrt(dx*dx + dy*dy);
      if (d < min_d){
         min_d =d;
         ret = i;
      }
   }
   return {
      index: ret,
      dist: min_d
   };
}


function arrayToCanvasCoord(array2d, xmap, ymap){
   let ret = [];

   for (let i=0; i<array2d.length; ++i){
      ret.push([xmap(array2d[i][0]), ymap(array2d[i][1])]);
   }

   return ret;
}


function closestSeriesXY(p, series, xmap, ylmap, yrmap){
    var min_dist = 1E20;
    var series_ix = -1;
    var ix_min = 0;

    for (let i=0; i<series.length; ++i){
        let ymap = series[i].axis === 'right' ? yrmap : ylmap;

        let array = arrayToCanvasCoord(series[i].data, xmap, ymap);
        let closest = minDistIndex(p, array);

        if (closest.index < 0){
           continue;
        }
        if ((series_ix < 0) || (closest.dist < min_dist)){
           min_dist = closest.dist;
           ix_min = closest.index;
           series_ix = i;
        }
    }

    if (series_ix < 0){
       return undefined;
    }

    // TODO: handle case with zero series
    return {
        series: series[series_ix],
        point: series[series_ix].data[ix_min]
    };
}


/** Find closest series at given canvas coordinates
 *
 * @param {list} p canvas coordinates {x, y}
 * @returns {Object}
 *    series {Object}: data of the closest line series
 *    point {list}: Closest point on the series to input coordinates
 */
EPlot.prototype.closestSeries = function(p){
   let series = filter_key(this.pdata, 'enabled');

   const xMap = (p)=>{
      return this.bottomAxis.map_to_canvas(p);
   };
   const ylMap = (p)=>{
      return this.leftAxis.map_to_canvas(p);
   };
   const yrMap = (p)=>{
      return this.rightAxis.map_to_canvas(p);
   };

   if (this._isTimeSeries){
      return closestSeriesSorted(
         p, series, this.bottomAxis, this.leftAxis, this.rightAxis);
   }

   p[1] -= this.margins.top;
   p[0] -= this.margins.left;

   return closestSeriesXY(p, series, xMap, ylMap, yrMap);
};


// module.exports.EPlot = EPlot;
