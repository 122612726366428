import * as React from 'react';
import {
   Checkbox,
   TextField,
   GroupHeader,
   Autocomplete,
} from '@mui/material';
import { styled } from '@mui/system';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';



export default function SensorAutoCompleteSelectionInput(props){
   const selection = props.selection;
   const setSelection = props.setSelection;
   const options = props.options;
   const label = props.label || '';
   const placeholder = props.placeholder || '';
   const disabled = props.disabled;
   const disableSelector = props.disableSelector;

   const GroupHeader = styled('div')(({ theme }) => ({
      position: 'sticky',
      top: '-8px',
      padding: '4px 10px',
      zIndex: 10,
      backgroundColor: '#ddd'
   }));

   return (
      <Autocomplete
         disabled={disabled}
         size="small"
         onChange={(event, value) => setSelection(value) }
         value={selection}
         style={{ width: 175 }}
         options={options}
         isOptionEqualToValue={(option, value)=> option.key === value.key}
         groupBy={(option)=> option.groupLabel}
         getOptionDisabled={disableSelector}
         getOptionLabel={(option) => option.key || ''}
         renderGroup={(params) => (
            <li key={params.key}>
               <GroupHeader>{params.group}</GroupHeader>
               <ul sx={{padding: 0}}>{params.children}</ul>
            </li>
         )}
         renderInput={(params) => (
            <TextField
               {...params}
               variant="standard"
               label={label}
               placeholder={placeholder} />
         )}
      />
   );
}
