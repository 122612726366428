/**
 * @module Datastore
 *
 */

import axios from 'axios';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import config from './config/config';
import auth from './auth/AuthProvider.js';


dayjs.extend(utc);
dayjs.extend(timezone);



const API_ENDPOINT = config.apiEndpoint + '/sensors';



export async function userConfig(){
   const identity = await auth.getIdentity();
   if (!identity){
      return {};
   }
   const id = identity.id;
   const url = `${config.apiEndpoint}/users/${id}/config`;
   const resp = await axios.get(url, {
      headers: {
         'x-access-token': identity.token
      }
   });
   return resp.data || {};
}


/** Convert date string to date in UTC date object
 *
 * @param {string} str input date string
 * @return {Date} date object in UTC time
 */
function utcStrToUtcDate(str){
   return dayjs.tz(str).$d;
}


/** Pressure-transducer sensor data
 *
 * @typedef PTSensorData
 * @property {string} t datetime string
 * @property {string} label unique sensor key
 * @property {number} pressure sensor pressure in PSI
 * @property {number} force sensor force in kips
 * @property {number} target target force in kips
 * @property {string} color displayed color
 * @property {number} value optional value
 *
 */


/** Pressure-transducer sensor data
 *
 * @typedef {Object} PTSensorDataRet
 * @property {PTSensorSnapshotData} data pressure-trans data
 * @property {String} error User-displayable error message if
 *    an error is encountered
 */


/** Deprecated
 * Interpreted values are computed and stored on the serverside
 */
function __interpretedData(rawData){
   const interpData = [
      {
         label: 'W10TBM',
         tbm: ['W10EXT'],
         tilt: 'W10EW',
         diff: 'W10DIF',
         prefix: 'W10',
         tilt2tbm: 10,
         tilt2diff: 5
      },
      {
         label: 'W19TBM',
         tbm: ['W19EXT'],
         tilt: 'W19EW',
         diff: 'W19DIF',
         prefix: 'W19',
         tilt2tbm: 10,
         tilt2diff: 5
      },
      {
         label: 'W29TBM',
         tbm: ['W29EXT'],
         tilt: 'W29EW',
         diff: 'W29DIF',
         prefix: 'W29',
         tilt2tbm: 10,
         tilt2diff: 5
      },
      {
         label: 'W36TBM',
         tbm: ['W36EXT'],
         tilt: 'W36EW',
         diff: 'W36DIF',
         prefix: 'W36',
         tilt2tbm: 10,
         tilt2diff: 5
      },
      {
         label: 'E19TBM',
         tbm: ['E19EXT'],
         tilt: 'E19EW',
         diff: 'E19DIF',
         prefix: 'E19',
         tilt2tbm: 10,
         tilt2diff: 5
      },
      {
         label: 'E27TBM',
         tbm: ['E27EXT'],
         tilt: 'E27EW',
         diff: 'E27DIF',
         prefix: 'E27',
         tilt2tbm: 10,
         tilt2diff: 5
      },
      {
         label: 'E36TBM',
         tbm: ['E36EXT'],
         tilt: 'E36EW',
         diff: 'E36DIF',
         prefix: 'E36',
         tilt2tbm: 10,
         tilt2diff: 5
      },
      {
         label: 'E10TBM',
         tbm: ['E10EXT'],
         tilt: 'E10EW',
         diff: 'E10DIF',
         prefix: 'E10',
         tilt2tbm: 10,
         tilt2diff: 5
      }
   ];

   return interpData.map((elem)=>{
      const tbmKey = elem.tbm[0];
      const tiltKey = elem.tilt;
      const diffKey = elem.diff;
      const tbmData = rawData.find(x => x.label === tbmKey);
      const tiltData = rawData.find(x => x.label === tiltKey);
      const diffData = rawData.find(x => x.label === diffKey);

      if ((tiltData === undefined) || (tbmData === undefined) || (diffData === undefined)){
         return undefined;
      }
      if ((tiltData.t.getTime() !== tbmData.t.getTime()) ||
          (diffData.t.getTime() != tbmData.t.getTime())){
         return undefined;
      }

      const t = tbmData.t;
      const D1tilde = elem.tilt2tbm * Math.tan(tiltData.value * Math.PI/180);
      const D2tilde = elem.tilt2diff * Math.tan(tiltData.value * Math.PI/180);
      const deltaD = D1tilde - tbmData.value;
      const tbmInterp = D2tilde - deltaD;

      const base = {t: t};
      base[tbmKey] = tbmData.value;
      base[tiltKey] = tiltData.value;

      return [
         {
            ...base,
            label: elem.prefix + 'TBM',
            value: tbmInterp,
         },
         {
            ...base,
            label: elem.prefix + 'FDN',
            value: tbmInterp - diffData.value,
         }
      ];
   }).flat().filter(x => x !== undefined);
}


/** Return pressure-transducers readings
 *
 * Pressure-transducers include plate jacks and compression rods.
 *
 * @param {Array<String>} [list] List of PT sensor keys. If not
 *    specified, returns all PT sensors
 * @param {Date} [datetime] query time. The input date will be interpreted
 *    to be in MST timezone. If not specified, returns latest available
 *    data
 *
 * @return {Array<PTSensorSnapshotData>} returned data, with errors, if any
 */
export async function readings(keys, datetime){
   const identity = await auth.getIdentity();
   if (!identity){
      return [];
   }
   const id = identity.id;
   const req = axios.create({
      headers: {
         'x-access-token': identity.token
      }
   });

   let qlist = [];

   if (keys){
      qlist.push('?keys=' + keys.join(','));
   }
   if (datetime){
      const timestr_mst = dayjs(datetime)
            .format('YYYY-MM-DD HH:mm');
      qlist.push(`t=${timestr_mst}`);
   }

   let qs = '';
   if (qlist.length > 0){
      qs = `?${qlist.join('&')}`;
   }

   const resp = await req.get(`${API_ENDPOINT}/snapshot${qs}`);
   const data = resp.data.data;

   return data.map((elem)=>{
      return {...elem, t: utcStrToUtcDate(elem.t)};
   });
}


function objectKeyValueGet(obj, key, fallbackKey){
   if ((key in obj) && (obj[key] !== null)){
      return obj[key];
   }
   return obj[fallbackKey];
}


/** Return time-histories for selected pt sensors
 *
 * @param {Array<String>} keys list of sensor keys
 * @param {('psi'|'kips')} mode indicates whether to return force or
 *   pressure for pressure-transducers. If sensors do not have the
 *   specified property, returns 'value' property of the data
 * @param {Date} t0 start datetime
 * @param {Date} t1 end datetime
 * @return TODO
 */
export async function timeHistory(keys, mode, t0, t1){
   const identity = await auth.getIdentity();
   if (!identity){
      return {};
   }
   const id = identity.id;
   const req = axios.create({
      headers: {
         'x-access-token': identity.token
      }
   });

   const qs = '?' + [
      'keys=' + keys.join(','),
      't0=' + dayjs(t0).format('YYYY-MM-DD HH:mm'),
      't1=' + dayjs(t1).format('YYYY-MM-DD HH:mm')
   ].join('&');

   const resp =  await req.get(`${API_ENDPOINT}/history${qs}`);
   const data = resp.data.data;
   const ret = {};
   for (const key in data){
      ret[key] = data[key].map(
         (elem)=> [utcStrToUtcDate(elem.t), objectKeyValueGet(elem, mode, 'value')]);
   }
   return ret;
}


/** A sensor named sensor group containing list of sensor keys
 *
 * Key is the unique sensor identifier
 *
 * @typedef {Object} SensorGroup
 * @property {String} name Sensor group name
 * @property {Array<String>} keys unique list of sensor keys
 */


/** Set pressure-sensor groups for the authenticated user
 *
 * Should overwrite existing groups a user may have had
 *
 * @param {Array<SensorGroup>} groups list of sensor groups
 * @return {String} User-displayable error message if unseccessful,
 *                  empty string otherwise
 */
export async function setGroups(groups){
   const data = saveUserData('groups', groups);
   return data['groups'] || {};
}


async function createClient(){
   const identity = await auth.getIdentity();

   if (!identity){
      throw new Error('Unauthorized');
   }

   return axios.create({
      headers: {
         'x-access-token': identity.token
      }
   });
}


export async function create(resource, params){
   const client = await createClient();
   const url = `${config.apiEndpoint}/${resource}`;
   const resp = await client.post(url, params.data);
   return resp.data;
}


export async function update(resource, params){
   const client = await createClient();
   const url = `${config.apiEndpoint}/${resource}/${params.id}`;
   const resp = await client.put(url, params.data);
   return resp.data;
}


export async function deleteOne(resource, params){
   const client = await createClient();
   const url = `${config.apiEndpoint}/${resource}/${params.id}`;
   const resp = await client.delete(url);
   return resp.data;
}


export async function saveUserData(category, data){
   const client = await createClient();

   const identity = await auth.getIdentity();
   if (!identity){
      return {};
   }
   const id = identity.id;
   const url = `${config.apiEndpoint}/users/${id}/config`;

   const resp = await client.put(url, {
      category: category,
      data: data
   });
   return resp.data;
}
