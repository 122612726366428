/** Client side configuration
 *
 */

const PT_DISPLAY_MODES = [
   {
      field: 'kips',
      key: 'psi',
      label: 'Pressure (psi)',
      value: (record)=>{
         return record.psi;
      }
   },
   {
      field: 'kips',
      key: 'kips',
      label: 'Force (kips)',
      value: (record)=>{
         return record.kips;
      }
   },
   {
      field: undefined,
      key: 'kipsToTarget',
      label: 'Force/Target (%)',
      value: (record)=>{
         return record.kips/record.targetKips * 100;
      }
   },
   {
      field: undefined,
      key: 'PsiToTarget',
      label: 'Pressure/Target (%)',
      value: (record)=>{
         return record.psi/record.targetPsi * 100;
      }
   },
   {
      field: undefined,
      key: 'kipsToTargetKips',
      label: 'Force-Target (kips)',
      value: (record)=>{
         return record.kips-record.targetKips;
      }
   },
   {
      field: undefined,
      key: 'PsiToTargetPsi',
      label: 'Pressure-Target (psi)',
      value: (record)=>{
         return record.psi-record.targetPsi;
      }
   }
];


/**
 *
 * @typedef {object} PTView
 *
 * Specifies plan view of Pressure-transducers
 *
 * @property {string} label Displayable label for the view
 * @property {string} background relative or absolute path to SVG
 *     document showing the plan-view
 * @property {Array<Object>} modes list of available view 'modes'
 *
 */


/**
 *
 * @typedef {object} PTDisplayMode
 *
 * Pressure-transducer reading type. A pressure-transducer is either a
 * plate-jack or a compression-rod.
 *
 * @property {string} key API-endpoint retrieval key
 * @property {string} label Displayable label for end-user
 *
 */


const CONFIG_BASE = {
   apiEndpoint: 'http://192.168.0.100:9999/api',

   sensorGroups: {
      PLATE_JACK: genKeys('W', 'J', 36)
         .concat(genKeys('N', 'J', 10))
         .concat(genKeys('C', 'J', 6))
         .concat(genKeys('S', 'J', 10))
         .concat(genKeys('E', 'J', 36))
      ,

      COMP_ROD: genKeys('W', 'C', 34)
         .concat(genKeys('N', 'C', 20))
         .concat(genKeys('S', 'C', 20))
         .concat(genKeys('E', 'C', 34)),

      THERMO_COUPLE: [
         // Large stations
         'STA1_T1', 'STA1_T2', 'STA1_T3',
         'STA2_T1', 'STA2_T2', 'STA2_T3',
         'STA3_T1', 'STA3_T2', 'STA3_T3',
         'STA4_T1', 'STA4_T2', 'STA4_T3',

         // Station 5
         'STA5_TC', 'W10_TC', 'W18_TC', 'W29_TC',

         // Station 6
         'STA6_TC', 'E10_TC', 'E27_TC'
      ],

      EXTENSOMETERS: [
         'W10EXT', 'W10DIF', 'W19EXT', 'W19DIF', 'W29EXT',
         'W29DIF', 'W36EXT', 'W36DIF', 'N3DIF', 'N8EXT', 'N8DIF',
         'E10EXT', 'E10DIF', 'E36EXT', 'E36DIF',
         'E27EXT', 'E27DIF', 'E19EXT', 'E19DIF',
         'S8DIF', 'S3EXT', 'S3DIF',

         // Non-averaged lvdts
         'W10TB80','W10TB75','W19TB80','W19TB75','W29TB73','W36TB80','W36TB75','N8TB65',
         'E10TB80','E10TB75','E19TB80','E19TB75','E27TB64','E36TB80','E36TB75','S3TB69'
      ],

      TILTS: [
         'W1NS', 'W1EW', 'W19NS', 'W19EW', 'W10NS', 'W10EW',
         'W29NS', 'W29EW', 'W18NS', 'W18EW', 'W36NS', 'W36EW',
         'E19NS', 'E19EW', 'E27NS', 'E27EW', 'E36NS', 'E36EW',
         'E1NS', 'E1EW', 'E10NS', 'E10EW', 'E18NS', 'E18EW',
         // "Temperature compensated"
         'W1NS_CMP', 'W1EW_CMP', 'W10NS_CMP', 'W10EW_CMP',
         'W18NS_CMP', 'W18EW_CMP', 'W19NS_CMP', 'W19EW_CMP',
         'W29NS_CMP', 'W29EW_CMP', 'W36NS_CMP', 'W36EW_CMP',
         'E1NS_CMP', 'E1EW_CMP', 'E10NS_CMP', 'E10EW_CMP', 'E18NS_CMP',
         'E18EW_CMP', 'E19NS_CMP', 'E19EW_CMP', 'E27NS_CMP', 'E27EW_CMP',
         'E36NS_CMP', 'E36EW_CMP'
      ],

      INTERP_DISPS: [
         'W10TBM', 'W10FDN',
         'W19TBM', 'W19FDN',
         'W29TBM', 'W29FDN',
         'W36TBM', 'W36FDN',
         'E19TBM', 'E19FDN',
         'E27TBM', 'E27FDN',
         'E36TBM', 'E36FDN',
         'E10TBM', 'E10FDN'
      ],

      BATTV: [
         'STA1_BattV', 'STA2_BattV', 'STA3_BattV', 'STA4_BattV',
         'STA5_BATTV', 'STA6_BATTV'
      ]
   },

   views: [
      {
         label: 'Jacks',
         background: '/plan_view_plate_jack.svg',
         modes: PT_DISPLAY_MODES
      },
      {
         label: 'Rods',
         background: '/plan_view_compr_rod.svg',
         modes: PT_DISPLAY_MODES
      },
      {
         label: 'Jacks+Rods',
         background: '/plan_view_comb.svg',
         modes: PT_DISPLAY_MODES
      },
      {
         label: 'Tilts',
         background: '/plan_view_tilt.svg',
         modes: [
            {
               key: 'value',
               label: 'Tilt (deg)',
            }
         ]
      },
      {
         label: 'Displacements',
         background: '/plan_view_disp.svg',
         modes: [
            {
               key: 'value',
               label: 'Disp (in)',
            }
         ]
      },
      {
         label: 'Interp. Disps',
         background: '/plan_view_inter_disp.svg',
         modes: [
            {
               key: 'value',
               label: 'Disp (in)',
            }
         ]
      }
   ],
   ptDisplayModes: PT_DISPLAY_MODES
};


/**
 * @param {string} apiEndpoint API endpoint including protocol
 * @param {Array<PTDisplayMode>} displayModes available list of display
 *    modes for pressure-transducers
 * @param {Array<PTView>} view available list of views of
 *    pressure-transducers
 */
const config = {
    development: {
       ...CONFIG_BASE,
       // apiEndpoint: 'http://192.168.0.100:9998/api',
       apiEndpoint: 'http://localhost:8080/api',
    },

    test: {
       ...CONFIG_BASE,
       apiEndpoint: 'http://localhost:9999/api',
    },

    stage: {
       ...CONFIG_BASE,
       apiEndpoint: process.env.REACT_APP_API_ENDPOINT || '/api',
    },

    production: {
       ...CONFIG_BASE,
       apiEndpoint: process.env.REACT_APP_API_ENDPOINT || '/api',
    }
};


function genKeys(prefix, suffix, count){
   return Array.from({length: count}, (_,i) => i+1).map((id) => {
      return `${prefix}${id}${suffix}`;
   });
}



export default config[process.env.NODE_ENV];
