
/** Draw a vertical line on plot at given point
 *
 *
 */
function Tracker(plot){
    this._plot = plot;

    this._tracker =
        plot
        .plot_group
        .append('line')
        .attr('class', 'ep-tracker');
}


/** Display vertical tracker at xPos in plot coordinates
 *
 * @param {float/Date} xPos x-position on plot coordinates
 * @returns {undefined} None
 */
Tracker.prototype.show = function(xPos){
    var leftDomain = this._plot.leftAxis.range();
    this._tracker
        .attr('x1', this._plot.bottomAxis.map_to_canvas(xPos))
        .attr('y1', leftDomain[0])
        .attr('x2', this._plot.bottomAxis.map_to_canvas(xPos))
        .attr('y2', Math.abs(leftDomain[0] - leftDomain[1]));
};


Tracker.prototype.hide = function(tf){
    this._visible = tf;
    this._tracker.classed('disabled', tf);
};


module.exports = Tracker;
